import OpisBox from '../components/zamowienie/OpisBox'
import Modal from '../components/zamowienie/Modal'
import NaTerazKafelek from '../components/zamowienie/NaTerazKafelek'
import NaTerazKafelekWydawka from '../components/zamowienie-wydawka/NaTerazKafelekWydawka'
import PotrawyKafelek from '../components/zamowienie/PotrawyKafelek'
import ContextMenu from '../components/zamowienie/ContextMenu'
import KafelekSurowki from '../components/zamowienie/KafelekSurowki'
import Navbar from '../components/wszystkie-zamowienia/Navbar'
import {Helmet} from "react-helmet";
import DodatekKafelek from '../components/zamowienie/DodatekKafelek'
import PrzyciskiDrukujNapoje from '../components/zamowienie/PrzyciskiDrukujNapoje'
import KafelekInformacyjny from '../components/zamowienie/KafelekInformacyjny'
import PrzyciskWydawka from '../components/zamowienie/PrzyciskWydawka'
import PrzyciskWszystkieRachunki from '../components/zamowienie/PrzyciskWszystkieRachunki'
import JedenRachunek from '../components/zamowienie/JedenRachunek'
import PrzyciskZamianaZamowienia from '../components/zamowienie/PrzyciskZamianaZamowienia'
import PrzyciskZmianaZamowieniaWiecej from '../components/zamowienie/PrzyciskZmianaZamowieniaWiecej'
import NavbarP from '../components/zamowienie/NavbarP'
import KafelekNaTerazP from '../components/zamowienie/KafelekNaTerazP'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom';
import  { useContext, useEffect, useState, createContext, useMemo } from 'react'
import { ProduktyContext } from '../ProduktyContext'
import {Obszar3Context} from  '../Obszar3Context'
import PotrawyKafelekWydawka from '../components/zamowienie-wydawka/PotrawyKafelekWydawka'
import {useLocation} from 'react-router'
import BelkaLewa from '../components/all/BelkaLewa'
import apiRequests from '../apiRequests'
import wszystkieDsry from '../funkcje/wszystkieDsry'
import fetchApi from '../api/fetchApi'
import sortowanieDsrPrzyciski from '../funkcje/sortowanieDsrPrzyciski'
import policzTalerze from '../funkcje/policzTalerze'

function ZamowienieWydawka({match}){
  const [ostatnioOdk, setostatnioOdk] = useState([])

  let history = useHistory();
  const {stolik} = useContext(ProduktyContext)
  const location = useLocation()
  let czasWydruku = new URLSearchParams(location.search).get("czas")


  
  let czas = czasWydruku

  var hms = czas    // your input string
  var a = hms.split(':'); // split it at the colons

  // Hours are worth 60 minutes.
  var minutes = (+a[0]) * 60 + (+a[1]);

 czas = minutes
 let modalInterval;
 
 async function ostatnioOdklikane  (){
  if(JSON.parse(sessionStorage.getItem('OPR_WYDAWKA')) != null){
    const res = await fetch(`https://kds.sereczyn.pl/api/odklikaneostatniowydawka?host=${JSON.parse(localStorage.getItem('HOST')).STA_ID}&opr=${JSON.parse(sessionStorage.getItem('OPR_WYDAWKA')) != null && JSON.parse(sessionStorage.getItem('OPR_WYDAWKA')).id}`,{method:"POST"})
    const data = await res.json()
    return data
  }
}

  const[obszar1, setObszar1] = useState([])
  const[obszar2, setObszar2] = useState([])
  const[obszar3, setObszar3] = useState([])
  const[wszystkie, setWszystkie] = useState([])
  const [polki, setpolki] = useState([])

  const[dsrOpisy, setDsrOpisy] = useState([])
  let queryDslZZamowien = []

 let wszystkieArr = []

 const getTasks = async () =>{

    const rachunkiFromServer = await fetchZamowienia()
    setpolki(await fetchApi(`/zamowieniaPolki?dsr_opis=${match.params.stolik}`))
    
    // console.log(rachunkiFromServer);
    setObszar1(rachunkiFromServer[0] != undefined || null ? rachunkiFromServer[0] : []  )
    setObszar2(rachunkiFromServer[1] != undefined || null ? rachunkiFromServer[1] : [] )
    setObszar3(rachunkiFromServer[2] != undefined || null ? rachunkiFromServer[2] : [] )
    setWszystkie(rachunkiFromServer[3] != undefined || null ? rachunkiFromServer[3] : [] )


    //OpisDSR 
         let opisyZDsrow = []
 
         rachunkiFromServer[3].map(x => {
           if(!opisyZDsrow.includes(x.DSR_OpisDostawy)){
             opisyZDsrow.push(x.DSR_OpisDostawy)
           }
         })
         setDsrOpisy(opisyZDsrow)
  
    console.log(rachunkiFromServer[3]);
    wszystkieArr = rachunkiFromServer[3]


    const odk = await  ostatnioOdklikane()
    console.log(odk);
    setostatnioOdk(odk)
 
}
  useEffect(()=>{
    if(localStorage.getItem('HOST') == null){
      localStorage.setItem('HOST', JSON.stringify({STA_ID:1}))
    }
  
    

     getTasks()
     
    let  modal = setTimeout(() => {
      
      let czyWszystkieBezPrzejecia = wszystkieArr.every(x => x.KDL_PrzejetaWydawka === null || x.KDL_PrzejetaWydawka === 'null')
      let czyKtoresNieodklikane = wszystkieArr.every(x => x.KDL_DataWydawka === 'null' || x.KDL_DataWydawka === null)

     console.log(wszystkieArr);
      console.log(czyWszystkieBezPrzejecia, 'log');
      if(czyWszystkieBezPrzejecia && czyKtoresNieodklikane){
        if(document.getElementById('wlaczModalPrzejmijZam') != null){
         clearTimeout(modal)
         document.getElementById('wlaczModalPrzejmijZam').click() 
         
        }
  }
    },2000)

     let zapytajBaze = setInterval(()=>getTasks(),2000)
     let buttonGrupyId = setTimeout(  () => document.getElementById('buttonGrupy').click(),2000)
 

     return () =>{
       clearInterval(zapytajBaze)
       clearTimeout(modal)
       clearInterval(buttonGrupyId)

     }
 },[])

wszystkie.map(x => {
  queryDslZZamowien.push(x.DSL_ID)
})
  // console.log(queryDslZZamowien.join(','))
 


 // Fetch Data (Różne Zamóienia)
 const fetchZamowienia = async()=>{
  let res = await fetch(`https://kds.sereczyn.pl/api/rachunki`,{method:"POST"})
 res = await fetch(`https://kds.sereczyn.pl/api/rachunkiwydawka/${match.params.stolik}`,{method:"POST"})
   const data = await res.json()
   return data
}
 
 // Przejmij zamowienie
 const przejmijZam = async()=>{
  const res = await fetch(`https://kds.sereczyn.pl/api/przejmijzam?query=${queryDslZZamowien.join(',')}&idwydawki=${(JSON.parse(sessionStorage.getItem('OPR_WYDAWKA'))).id}`,{method:"POST"})
   const data = await res.json()

   return data
 
}

 //Segregowanie przycisków
 var dsrPrzyciski = wszystkie.reduce(function(dsrPrzyciski, org) {
  (dsrPrzyciski[org.DSR_ID] = dsrPrzyciski[org.DSR_ID] || []).push(org);
  return dsrPrzyciski;
}, {})

dsrPrzyciski = Object.entries(dsrPrzyciski);
dsrPrzyciski = sortowanieDsrPrzyciski(dsrPrzyciski)


//Segregowanie Nateraz/surowek/deserow
var obszar1Zam = obszar1.reduce(function(ob, org) {
  (ob[org.ART_Nazwa] = ob[org.ART_Nazwa] || []).push(org);
  return ob;
}, {})

obszar1Zam = Object.entries(obszar1Zam)

obszar1Zam.map(grupa =>{
  let ilosc =0;
  let strDslId = []
  grupa[1].map(pozycja =>{
    ilosc += Number(pozycja.DSL_Ilosc)
    strDslId.push(',',pozycja.DSL_ID)
  
  })
  grupa.push({nazwa:grupa[0], ilosc: ilosc, kat: grupa[1][0].ART_KatWydruku, query: strDslId.join('').substring(1), polka: grupa[1][0].KDL_Polka, operator: grupa[1][0].wydawkaOpr, czasPoz : grupa[1][0].czas_pozycja})
})



var obszar2Zam = obszar2.reduce(function(ob, org) {
  (ob[org.ART_Nazwa] = ob[org.ART_Nazwa] || []).push(org);
  return ob;
}, {})

obszar2Zam = Object.entries(obszar2Zam)

obszar2Zam.map(grupa =>{
  let ilosc =0;
  let strDslId = []
  grupa[1].map(pozycja =>{
    ilosc += Number(pozycja.DSL_Ilosc)
    strDslId.push(',',pozycja.DSL_ID)
  
  })
  grupa.push({nazwa:grupa[0], ilosc: ilosc, kat: grupa[1][0].ART_KatWydruku, query: strDslId.join('').substring(1), polka: grupa[1][0].KDL_Polka, operator: grupa[1][0].wydawkaOpr, czasPoz : grupa[1][0].czas_pozycja})
})



// console.log(obszar1Zam, "OBSZAR1");
// console.log(obszar2Zam, "OBSZAR2");
// console.log(obszar4Zam, "OBSZAR4");

//Odklikaj wszystkie pozycje w kafelku
function odklikajPozycje(query, status){

    const fetchZamowienia = (q,s)=>{
    
      fetch(`https://kds.sereczyn.pl/api/odklikajPozycje?query=${q}&status=${s}&ekran=Wydawka&operator=${(JSON.parse(sessionStorage.getItem('OPR_WYDAWKA'))).id}&host=${JSON.parse(localStorage.getItem('HOST')).STA_ID}`,{method:"POST"})
  }
    fetchZamowienia(query, status)
    // console.log('something is change?');
}

function wydruk() {
  let katWydruku = [1,3,4,5,6] 
  
  let zamowienia = wszystkie.filter(x => katWydruku.includes(x.ART_KatWydruku))

  const script = document.createElement('script');
 let wydajacy = JSON.parse(sessionStorage.getItem('OPR_WYDAWKA'))

  let pozycje = []

  zamowienia.map(x => {
  if(x.DSL_DSLIDPowiazana == null){
    pozycje.push({nazwa: x.ART_Nazwa,ilosc: x.DSL_Ilosc, powiazane: [], dslId: x.DSL_ID })
  }
 })
 zamowienia.map(x => {
if(x.DSL_DSLIDPowiazana != null){
  pozycje.map(c => {
    if(x.DSL_DSLIDPowiazana == c.dslId){
      c.powiazane.push(`${x.DSL_Ilosc}x ${x.ART_Nazwa}`)
    }
  })
}
})

let pozycjeQuery = ``

pozycje.map(x => {
  if(x.powiazane.length != 0){
    let powiazaneStr = ``
    x.powiazane.map(x => {
      powiazaneStr = `${powiazaneStr}  builder.addTextSize(1, 1); builder.addText('--${x}');  builder.addFeedLine(1);`
    })
    pozycjeQuery= `${pozycjeQuery} builder.addTextSize(2, 1); builder.addText('${x.ilosc}x ${x.nazwa}'); builder.addFeedLine(1); ${powiazaneStr} `
 
  }else if(x.powiazane == 0){
    pozycjeQuery= `${pozycjeQuery} builder.addTextSize(2, 1); builder.addText('${x.ilosc}x ${x.nazwa}'); builder.addFeedLine(1); `
  }
  
})

 console.log(pozycjeQuery);


  script.async = true;
   script.innerHTML = `var builder = new epson.ePOSBuilder();
   builder.addTextAlign(builder.ALIGN_CENTER);
       builder.addTextFont(builder.FONT_A);
       builder.addTextSize(6, 6);
       builder.addTextSmooth(true);
       builder.addText('${match.params.stolik}');
       builder.addFeedLine(1)
       builder.addTextSize(1, 1);
       builder.addText('${match.params.stolik}');
       builder.addFeedLine(1)
       builder.addTextSize(3, 3);
       builder.addText('${'GROBLA'}');
       builder.addFeedLine(1)
       builder.addTextSize(1, 1);
       builder.addText('${'Grobla'}');
       builder.addFeedLine(1)
       builder.addTextSize(1, 2);
       builder.addText('Ilosc zamowien: ${dsrPrzyciski.length}');
       builder.addFeedLine(1)
       builder.addText('Bonujacy: ${wydajacy.imie} ${wydajacy.nazwisko} ${!wszystkie.some(x => x.KDL_DataWydawka != null) ? 'Bez Godziny' : wszystkie.find(x => x.KDL_DataWydawka != null).KDL_DataWydawka.substring(11).slice(0, -8) }');
       builder.addFeedLine(1)
       builder.addTextSize(2, 1);
       builder.addText('-------------------');
       builder.addFeedLine(1)
       builder.addTextAlign(builder.ALIGN_LEFT);
       ${pozycjeQuery}
       builder.addText('--------------------');
       builder.addFeedLine(1)
       builder.addTextAlign(builder.ALIGN_CENTER);
       builder.addTextSize(1, 2);
       builder.addText('Wydajacy: ${wydajacy.imie} ${wydajacy.nazwisko} ');
       builder.addFeedLine(1)
       builder.addText('Nr Rachunku: ${wszystkie[0].DSR_NrRachunku}');
       builder.addFeedLine(1)
     builder.addBarcode('${wszystkie[0].DSR_NrRachunku}', builder.BARCODE_CODE39, builder.HRI_NONE, builder.FONT_A, 3, 87);
       builder.addCut(builder.CUT_FEED);
   
   var request = builder.toString();
   var address = 'http://192.168.1.52/cgi-bin/epos/service.cgi?devid=local_printer&timeout=60000';
   //Create an ePOS-Print object
   var epos = new epson.ePOSPrint(address);
   //Set a response receipt callback function
   epos.onreceive = function (res) {
   //When the printing is not successful, display a message
   if (!res.success) {
   alert('A print error occurred');
   }
   }
   //Send the print document
   epos.send(request);`

  document.body.appendChild(script);
   setTimeout(() => document.body.removeChild(script) ,3000)
}

    return (<>


{/* //Dodawanie grupy */}
<button id="buttonGrupy" class={`btn btn-primary display-hidden`} onClick={() => {
              !wszystkie.some(x => x.DSR_IDGrupy != null) ?  fetchApi(`/dodajgrupe?query=${wszystkieDsry(wszystkie)}`) :  fetchApi(`/updategrupy?dsry=${wszystkieDsry(wszystkie)}&idGrupy=${wszystkie.find(x => x.DSR_IDGrupy != null).DSR_IDGrupy}`)
          } }></button>
<div className="computer-version">

{/* pop up */}
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
  <div class="modal-dialog" role="document">
    <div class="modal-content">

      <div class="modal-body box--black" style={{textAlign:'center'}}>
        <h3>Czy chcesz przejac to zamowienie?</h3>
      </div>
      <div class="modal-footer box--black" style={{textAlign:'center'}}>
      <div type="button" style={{display:'inline-block'}} class="btn btn-primary" onClick={() => {
        przejmijZam() 
        }} data-dismiss="modal" >Tak</div>
        <div type="button"  style={{display:'inline-block'}} class="btn btn-secondary" data-dismiss="modal">Nie</div>
      
      </div>
    </div>
  </div>
</div>

      <button type="button" class="btn btn-primary display-hidden" id="wlaczModalPrzejmijZam" style={{position:'absolute', top:'30%'}}  data-toggle="modal" data-target="#exampleModal">
</button>

    {/* {NAVBAR} */}
    <div className="fixed-top">
      <div id="computer-nav" className={`navbar px-4 ${ czas > 40 ? 'box--red' : ''}  ${ czas >=0 && czas <= 15 ? 'box--gray' : '' }${czas > 15 && czas <=25 ? 'box--green' : '' }${czas > 25 && czas <=40 ? 'box--yellow' : '' }`} style={{fontSize:"1.5rem !important"}}>
        <div className="navbar__lokalizacja-i-status ">
          <span className="navbar__lokalizacja-i-status__ikona icon-sm">🍕</span>
          <span className="navbar__lokalizacja-i-status__stolik">{match.params.stolik}</span>

    
         
          <span className="navbar__lokalizacja-i-status__ilosc-zamowien" style={{marginRight:'5rem'}}>{`     (${dsrPrzyciski.length})`}</span>
          <span className="navbar__lokalizacja-i-status__stolik">{wszystkie.find(x => x.DSR_Telefon != '') != undefined ? wszystkie.find(x => x.DSR_Telefon != '').DSR_Telefon : null}</span>
          <span className="navbar__lokalizacja-i-status__stolik">{wszystkie.find(x => x.sektor != null) != undefined ? wszystkie.find(x => x.sektor != null).sektor : null}</span>
          <span className="navbar__lokalizacja-i-status__stolik" style={{fontSize:'2rem'}}>talerz:{policzTalerze(wszystkie, 2)}</span>
          <span className="navbar__lokalizacja-i-status__stolik" style={{fontSize:'2rem'}}>miska:{policzTalerze(wszystkie.filter(x => (x.DSL_DSLIDPowiazana != null && x.ART_ZastawaZestaw == true )|| x.DSL_DSLIDPowiazana == null && x.ART_KatWydruku != 3  ), 6)}</span>
          {!wszystkie.some(x => x.KDL_PrzejetaWydawka === null) ? `Przejęte przez ${wszystkie.some(x => x.KDL_PrzejetaWydawka != null) && wszystkie.find(x => x.KDL_PrzejetaWydawka != null).KDL_PrzejetaWydawka} ` : null}
          {/* <span className="navbar__lokalizacja-i-status__status">VIP</span> */}
          {/* <span className="navbar__lokalizacja-i-status__lokalizacja">Rybakówka</span> */}
        </div>
      
        <div className="navbar__czas-zamowienia">
          <span className="navbar__czas-zamowienia__timer">{czasWydruku}</span>
        </div>
      </div>
    </div>
            {/* {NAVBAR} */}
        {/* Lewa strona */}
        <div className="middle-content row px-3 margin-top-middle-container">
        <div className="middle-content__left  col-xl-2 col-lg-2 col-md-2">
             {/* Opis DSR */}
             {dsrOpisy.map(x => { 
                if(x != ''){
                  return   <OpisBox opis={x} />
                }
           })}
        
         <div className="middle-content__left__sidebar mt-3 row" >

       
<div className="middle-content__left__sidebar__container">
         {obszar2Zam.map(x =>{
              return <NaTerazKafelekWydawka operator={x[2].operator} czasPoz={x[2].czasPoz} ilosc={x[2].ilosc} wydawka={1} polka={x[2].polka} kolor={x[1][0]} query={x[2].query} nazwa={x[0]} onClick={odklikajPozycje}/>
            })}
       </div>
       </div>
      </div>
  
        {/* Środek Góra */}
        
      <ContextMenu/>
      <div className="middle-content-middle col-xl-8 col-lg-8 col-md-8">
      <div className="middle-content-middle__gora py-3">
          <div className="middle-content-middle__gora__na-teraz">
            {obszar1Zam.map(x =>{
              return <NaTerazKafelekWydawka operator={x[2].operator} czasPoz={x[2].czasPoz} ilosc={x[2].ilosc} wydawka={1} polka={x[2].polka} kolor={x[1][0]} query={x[2].query} nazwa={x[0]} onClick={odklikajPozycje}/>
            })}
            
            </div>
            </div>
            <div className="middle-content-middle__środek py-1">
            <div className="middle-content-middle__srodek__potrawy">

            {obszar3.map((x,idx )=>{
              if(x.DSL_DSLIDPowiazana == null){
                return  <PotrawyKafelekWydawka czasPoz={x.czas_pozycja} nazwa={x.ART_Nazwa} operator={x.wydawkaOpr}  wydawka={1} polka={x.KDL_Polka} onClick={odklikajPozycje} ilosc={x.DSL_Ilosc} DSL_ID={x.DSL_ID} obszar={obszar3} zamowienie={x}  />
              }
            
            })}
      
           
            </div>
              </div>
              <div className="middle-content-middle__dol py-3">

          <div className="middle-content-middle__dol__dodatki">

    
            
            </div>
            </div>
      </div>

      <div className="middle-content-right col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 mt-4">
        <div className="sidebar-right">
     {/* <PrzyciskiDrukujNapoje/> */}
    
          <div className="sidebar-right__kafelki-informacyjne">

      
    
            

          </div>
       
          <div className="sidebar-right__przyciski-wydawka">
         
       {/* <PrzyciskWydawka/> */}

           
        </div>

        {polki.map(x => {
              return <button type="button" class="btn btn-outline-light w-100 fs-2 d-flex justify-content-between mb-2"><span class="fs-5"> {`${x.KDL_Polka}`.charAt(0) == 1 ? `Piec` : `Patelnia`}: {`${x.KDL_Polka}`.charAt(1)}</span><span class="fs-5">Półka: {`${x.KDL_Polka}`.charAt(2)}</span>  </button>
            })}

  
       <div class="mt-5"></div>
          {dsrPrzyciski.map(x =>{
           return <Link to={`/zamwydawka/${match.params.stolik}?czas=${czasWydruku}`}> <JedenRachunek rachunekNumerek={x[1][0].DSR_NrRachunku} onClick={()=> 
            setTimeout(()=> history.push(`/zamwydawka/${match.params.stolik}/${x[0]}?czas=${czasWydruku}`),10)
          }/></Link>
          })}
          

         <Link to={`/zamwydawka/${match.params.stolik}?czas=${czasWydruku}`}> <button type="button" class="btn btn-secondary w-100">Wszystkie Rachunki</button></Link>
            
     
        </div>
        <div className="sidebar-right__zmiana-zamowienia">
        <div >
        {ostatnioOdk.map(x => {
                     return  <a className="btn btn-primary btn-lg btn--yellow" style={{fontSize:"10px"}} onClick={

                       () =>
                       { history.push(`/wydawka`)
                        setTimeout(() => history.push(`/zamwydawka/${x.DSR_Opis}?czas=${x.czas}`),10)}

                     }>{x.DSR_Opis}</a>
                   })}
          
        </div>
         <PrzyciskZmianaZamowieniaWiecej/>
        </div>

      </div>
  {/* BELKA */}
  <div className="fixed-bottom">
          <link rel="stylesheet" href="./sass/main.css"></link>
        <div className="belka">
  
   
          <div className="belka__srodek">
          {/* <Link to="/napoje"> <span>Napoje</span> </Link> */}
           <span style={{marginLeft:'2rem'}} onClick={() => {przejmijZam(); getTasks();  wydruk()}}>Przejmij Zam.</span> 
          </div>
  
          <div className="belka__prawa">
          {/* <Link to="/raport"><span>Raport</span></Link>
          <Link to="/imprezy"><span>Imprezy</span></Link> */}
          <Link to="/wydawka"><div className="btn btn btn-outline-light btn-lg" style={{padding:'1rem 1.5rem 1rem' }}>Gotowe</div></Link>
     
          </div>
        </div>
      </div>
      {/* BELKA */}
      </div>
      {/* <Helmet>
      <script src="./scripts/e-zam.js" type="text/javascript" />
      <script defer src="./libki/keyboard/keyboard.js" type="text/javascript" ></script>
      <script defer src="scripts/long-press.js"></script>
            </Helmet> */}

            </div>

            <div className="phone-version">
             <NavbarP/>
            
             <div id="phone-content">
      <div id="zamowienia-tel" className="zamowienia">
      <div id="naTeraz">
      <h2 style={{textAlign:"center"}}>NA TERAZ</h2>
      <div className="row">
      <KafelekNaTerazP/>
      <KafelekNaTerazP/>
      <KafelekNaTerazP/>
      <KafelekNaTerazP/>
   
</div>
      </div>
      <div id="potrawy">
      <h2 style={{textAlign:"center"}}>POTRAWY</h2>
          <div className="row">
       
         <PotrawyKafelek phone={true} />
         <PotrawyKafelek phone={true} />
         <PotrawyKafelek phone={true} />
         <PotrawyKafelek phone={true} />
             
             
              </div>
          
          </div>

          <hr/>
          <div id="surowki">
            <h2 style={{textAlign:"center"}}>SURÓWKI</h2>
          <div className="row">
          <KafelekNaTerazP/>
      <KafelekNaTerazP/>
      <KafelekNaTerazP/>
</div>

          </div>
          <hr/>
          <div id="dodatki">
            <h2 style={{textAlign:"center"}}>DODATKI</h2>
          <div className="row">
          <KafelekNaTerazP/>
      <KafelekNaTerazP/>
      <KafelekNaTerazP/>
</div>

          </div>
          <div id="sidebar-tel" className="sidebar " >


   <PrzyciskiDrukujNapoje/>

<div className="sidebar-right__kafelki-informacyjne">


</div>

<OpisBox phone={true}/>

<div className="sidebar-right__przyciski-wydawka">
  <PrzyciskWydawka/>
  <PrzyciskWydawka/>
  <PrzyciskWydawka/>
  <PrzyciskWydawka/>
</div>

<div className="sidebar-right__rachunki">
<div style={{maxHeight: "7.9rem", overflow:"scroll",marginBottom:"-1.5rem"}}>
 <JedenRachunek/>
 <JedenRachunek/>
 <JedenRachunek/>
 </div>


  <div className="sidebar-right__rachunki__wszystkie-rachunki box--gray">
    
 <PrzyciskWszystkieRachunki/>
  </div>
</div>

<div className="sidebar-right__zmiana-zamowienia">
  <div className="przyciski" style={{maxHeight: "12rem", overflow:"scroll", marginBottom:"-1.5rem"}}>
<PrzyciskZamianaZamowienia/>
<PrzyciskZamianaZamowienia/>
<PrzyciskZamianaZamowienia/>
<PrzyciskZamianaZamowienia/>
<PrzyciskZamianaZamowienia/>
<PrzyciskZamianaZamowienia/>
<PrzyciskZamianaZamowienia/>
</div>
<PrzyciskZmianaZamowieniaWiecej/>
</div>


</div>

        </div>
        
        <hr/>
      
  
        </div>


          


            </div>

            
        </>

        
        )
}

export default ZamowienieWydawka