import React, { useState, useEffect} from 'react'
import { useHistory } from 'react-router-dom';
import fetchApi from '../api/fetchApi';
import akcjeArykuły from '../funkcje/akcjeArykuły';

export default function ListaArtykulow() {
   let history = useHistory()
   const [listaArtykulow, setlistaArtykulow] = useState([])
   const [grupy, setgrupy] = useState([])
const [status, setstatus] = useState('')
  let delayCount = 20
    async function listaArtykulowFunction() {
    setlistaArtykulow(await fetchApi(`/listaartykulow`))
    setgrupy(await fetchApi(`/grupyartykulow`))
  }

  async function update(status, artid, controller) {
   console.log(status, artid, controller);
    status ? status = 1 : status = 0
    switch (controller) {
      case 'pos':
        await fetchApi(`/zablokujwposie?artid=${artid}&status=${status}`)
        return true
        break;
    case 'internet':
      await fetchApi(`/zablokujwinternecie?artid=${artid}&status=${status}`)
      return true
    case 'wylaczinternet':
      await fetchApi(`/wylaczwinternecie?artid=${artid}&status=${status}`)
      return true

      default:
        break;
    }
  }

  

  useEffect(() => {
    listaArtykulowFunction()
 
    let intervallistaArtykulow = setInterval(() => {
        listaArtykulowFunction()

   },7000)
   
   return () => {
     clearInterval(intervallistaArtykulow)
   }
  }, [])

useEffect(() => {
    
       console.log(listaArtykulow);
       console.log(grupy);
}, [listaArtykulow])


   return (
        <div style={{background:'white'}}>
 
   

      <div class="container">
    <div class="row"  style={{background:'white'}}>
  
    {grupy.map(x => {
          return <>
              <div class="col-12 text-dark fs-3 fw-bold border border-secondary p-2 ">{x.KAT_Nazwa}</div>

              {listaArtykulow.map( c => {if(x.KAT_ID == c.AXK_KATID) return <div class="col-12 text-dark p-2 fs-3 fw-normal border">{c.ART_Nazwa} 
              
               
                 <span class="float-right ">
                     <button type="button" class={`btn ${c.ART_CzyZastrzezonyWSprzedazy == 0 ? `btn-success` : `btn-danger`} m-1`} onClick={


                       async () => {
                          let status = await fetchApi(`/statusPos?artid=${c.AXK_ARTID}`)
                          update(!status,c.AXK_ARTID, 'pos')
                          listaArtykulowFunction()
                       }
                     }>Zablokuj w posie</button>
                     <button type="button" class={`btn ${c.ART_CzyZablokowanyWInternecie == 0 ? `btn-success` : `btn-danger`} m-1`}
                     onClick={

                
                      async () => {
                        console.log(x.ART_CzyZablokowanyWInternecie);
                         let status = await fetchApi(`/statusInternet?artid=${c.AXK_ARTID}`)
                         update(!status,c.AXK_ARTID, 'internet')
                         akcjeArykuły.odblokujzablokujProdukt( c.AXK_ARTID,!status ? 'zablokuj' : 'odblokuj')
                         listaArtykulowFunction()

                      }
                    }
                     >Zablokuj w internecie</button>

                  <button type="button" class={`btn ${c.ART_CzyProduktDotowany == 0 ? `btn-success` : `btn-danger`} m-1`}
                     onClick={
                      async () => {
                         update(!c.ART_CzyProduktDotowany,c.AXK_ARTID, 'wylaczinternet')
                         akcjeArykuły.odblokujzablokujProdukt( c.AXK_ARTID,!c.ART_CzyProduktDotowany ? 'wylacz' : 'wlacz')
                         listaArtykulowFunction()
                      }
                    }
                     >Wyłącz z internetu</button>
                 </span>
                
                
                </div>})}

          </>
       })}


<div class="col-12 text-dark fs-3 fw-bold" style={{height:'5rem'}}></div>
        
    </div>
    
        </div>
        <div class="my-5"></div>

      <div class="fixed-bottom d-flex align-items-center justify-content-end border-top"  style={{width:'100%', height:'4rem', background:'white'}}>  
            {/* <button type="b}
          }utton " class="mx-2 btn btn-primary btn-lg float-right" onClick={() => {history.push('/scanner')}}>Skanuj</button> */}
      </div>
    
        </div>
    )
}
