import React from 'react'

export default function PrzyciskWydawka({kolor, nazwa, onClick}) {
    return (
        <div data-backdrop="static" data-keyboard="false"  data-bs-toggle="modal" data-bs-target="#exampleModal" className={`sidebar-right__przyciski-wydawka__przycisk ${kolor} mb-3 `} onClick={onClick}>
              <span style={{fontSize:'2rem'}}>{nazwa}</span>
              <i className="icon-sm">🌮</i>
            </div>
    )
}

PrzyciskWydawka.defaultProps = {
    kolor: "box--red"
}