import React, { useState, useEffect} from 'react'
import { useHistory } from 'react-router-dom';
import fetchApi from '../api/fetchApi';
import {BrowserRouter as Router, Route, Switch, Link} from 'react-router-dom'


export default function ListaRachunkowQr() {
   let history = useHistory()
   const [listaZamowien, setlistaZamowien] = useState([])
   const [sektory, setsektory] = useState([])


    async function listaZamowienQr() {
    setlistaZamowien(await fetchApi(`/listazamowienqr`))
    setsektory(await fetchApi(`/sektory`))
  }

  

  useEffect(() => {
    listaZamowienQr()
 
    let intervalListaZamowien = setInterval(() => {
     listaZamowienQr()

   },5000)
   
   return () => {
     clearInterval(intervalListaZamowien)
   }
  }, [])

useEffect(() => {
    
       console.log(listaZamowien);
       console.log(sektory);
}, [listaZamowien])


   return (
        <div style={{background:'white'}}>
 
   

      <div class="container">
    <div class="row"  style={{background:'white'}}>
  
    <div class="col-12 text-dark fs-3 fw-bold  ">Bez sektora</div>
    {sektory.map(x => {
          return <>
              <div class="col-12 text-dark fs-3 fw-bold border border-secondary ">{x.sektory}</div>
             
              {listaZamowien.map(c => {if(x.sektory == c.sektor) return <div class="col-4 text-dark fs-3 fw-normal border"><span class="fs-2 fw-bolder text-dark">{c.DSR_Opis} </span> <span class="fs-3  text-dark float-right"> {c.MinuteDiff} min.</span> </div>})}

          </>
       })}


<div class="col-12 text-dark fs-3 fw-bold" style={{height:'5rem'}}></div>
        
    </div>
    
        </div>
        <div class="my-5"></div>

      <div class="fixed-bottom d-flex align-items-center justify-content-end border-top"  style={{width:'100%', height:'4rem', background:'white'}}>  
      <Link to="/" class="mx-2 btn btn-primary btn-lg float-left" >Strona główna</Link>
            <button type="button " class="mx-2 btn btn-primary btn-lg float-right" onClick={() => {history.push('/scannerqr')}}>Skanuj</button>

      </div>
    
        </div>
    )
}
