import React, { useEffect , useState} from 'react'
import fetchApi from '../api/fetchApi'
import TimerComponent from './TimerComponent'
import { Link, useHistory } from 'react-router-dom'
import { last } from 'lodash'

export default function AkcjeZamowieniaPiece({match}) {

    let history = useHistory()
    const [modalContent, setmodalContent] = useState([])
    const [lastStateArr, setLastStateArr] = useState([])
    
    let arrAll = []
    let array = [];

    useEffect(async () => {
        setmodalContent(await fetchApi(`/zamowieniapiecemodal?polka=${match.params.polka}`)) 
        console.log(modalContent);
        return () => {
            array = []
        }
    }, [])
  


    return (
       
            <div class="container ">
            <div class=" row">
      
                 {
                     modalContent.length != 0 && modalContent.listazamowien.map((x,index) => {

                      return  <div className="col-md-3 col-lg-3 col-sm-3 fs-2 text-light">{x.zamowieniaNaPolce}
                              <div class="row p-3">
       
                              {modalContent.zamowienia.map((c,idx) => {
                                
                                if( c.DSR_Opis == x.zamowieniaNaPolce){
                                    if(array[index] == undefined) { 
                                    array[index] = [];
                                    arrAll[index] = []
                                    }
                                    
                                    if(array[index] != undefined){
                                        
                                        array[index].push(c.KDL_DSLID)
                                        arrAll[index].push(c.KDL_DSLID)
                                    }
                                   
                                 } 
                                 
                                
                                 return c.DSR_Opis == x.zamowieniaNaPolce && <button class={`btn btn-warning btn-lg col-md-12 col-lg-12 col-sm-12 mt-2 ${match.params.polka == c.KDL_Polka && `fw-bold text-light`}`}
                                
                                  onClick={(x) => {

                                         if(array[index] != undefined){
                                         
                                            console.log( x.target.classList.contains('bg-success'), c.KDL_DSLID, `undefined yes`);
                                            x.target.classList.contains('bg-success') ? setLastStateArr(array[index] = lastStateArr.filter(y => y != c.KDL_DSLID))   : setLastStateArr(array[index] = [...lastStateArr, c.KDL_DSLID])
                                        }

                                        x.target.classList.toggle('bg-success')
                                    console.log(array);
                                  }}
                                  
                                  >{c.ART_Nazwa} {c.KDL_Polka}</button>
                              })}
      
                                   </div>
                                   <button class="btn btn-primary btn-lg col-12 col-md-12 col-lg-12" onClick={() =>{console.log(modalContent);}}>Logi</button>

                                  <TimerComponent polka={x.KDL_Polka} timerValue={x.KDL_Timer} dsl={array[index]} oldtimer={x.timer}/>
                                   <button class="btn btn-primary btn-lg col-12 col-md-12 col-lg-12" onClick={() => history.push(`/piece/${match.params.id}?dsl=${lastStateArr.join(',')}`)}>Zmien/Dodaj półkę</button>
                                   
                      </div>
                     })
                 }
            </div>

            <button class="btn btn-light fs-2 w-100 fixed-bottom" >
            <Link class="btn btn-primary btn-lg float-right" to={`/piece/${match.params.id}`}>GOTOWE</Link >
      </button>
            
            </div>

          
    )
}
