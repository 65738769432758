
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router-dom';


function KafelekZamowienia({sektor,onClick, stolik, nazwaLokalizacji, iloscZamowien,czasZamowienia,timerKucharza,opis,sztucce,zamowienia, kolor, typZamowienia, obszar,DSR_ID,dostawa,wynos}){
   
    let history = useHistory();
    let katZamowienia = {
        naTeraz: [3,8,11],
        wydawka: [2,9],
        Kucharz: [1,4,7]
    }   

   

    const wejdzWZamowienia = ()=>{
   
        history.push(`/kuchzam/${opis == null || opis == 'null' || opis == '' ? DSR_ID : opis}?czas=${czasZamowienia}`)
    }

    let czas = czasZamowienia

    var hms = czas ;   // your input string
    var a = hms.split(':'); // split it at the colons

    // Hours are worth 60 minutes.
    var minutes = (+a[0]) * 60 + (+a[1]);

   czas = minutes

   //Sortowanie Potraw 
   let potrawy = {}
 
   if(zamowienia != null ||zamowienia != undefined ||zamowienia != ''){
       zamowienia.map((zamowienie) => {
           if(zamowienie.ART_Nazwa in potrawy && zamowienie.KDL_DataKucharz == null){
              let nazwa =  zamowienie.ART_Nazwa
              potrawy[nazwa].DSL_Ilosc =  Number(potrawy[nazwa].DSL_Ilosc) + Number(zamowienie.DSL_Ilosc)
       
           }else if(` ${zamowienie.ART_Nazwa}` in potrawy && zamowienie.KDL_DataKucharz != null){
            let nazwa =  ` ${zamowienie.ART_Nazwa}`
            potrawy[nazwa].DSL_Ilosc =  Number(potrawy[nazwa].DSL_Ilosc) + Number(zamowienie.DSL_Ilosc)
     
         }
           
           
           else if(zamowienie.KDL_DataKucharz != null){
            let nazwa =  ` ${zamowienie.ART_Nazwa}`
            Object.assign(potrawy, {[nazwa]:{ART_Nazwa: nazwa, DSL_Ilosc:Number(zamowienie.DSL_Ilosc), ART_KatWydruku: zamowienie.ART_KatWydruku, polka: zamowienie.KDL_Polka, odklikane: true } });
           }
           
           else{
               let nazwa =  zamowienie.ART_Nazwa
               Object.assign(potrawy, {[nazwa]:{ART_Nazwa: nazwa, DSL_Ilosc:Number(zamowienie.DSL_Ilosc), ART_KatWydruku: zamowienie.ART_KatWydruku, polka: zamowienie.KDL_Polka,odklikane: false } });
           }
       })
   }

   potrawy = Object.entries(potrawy);

    return(
       
        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6 container__blokZamowieniBg" key={uuidv4()}>
        <span >
        <div className={`blok-dlugi-z-tekstem `} onClick={() => {console.log(czas)}} >
            <div className="blok-zamowienie">
            
               <div className={`blok-head ${typZamowienia}  ${ czas > 5 ? 'box--red' : 'box--gray' }   ` } style={obszar ===3 ? {borderRadius:"5px"} : {}} onClick={wejdzWZamowienia}  >
    
                    {/* Ikonki w zależnośći od TOR (TYP ZAMOWIENIA) */}
                    {dostawa ? <i className="icon-bg" style={{fontSize:"3.5rem", margin:"1rem"}}><img class="filter-white" src="./icons/dostawa.svg"/></i> :null}
                    {wynos ? <i className="icon-bg" style={{fontSize:"3.5rem", margin:"1rem"}}><img class="filter-white" src="./icons/wynos.svg"/></i> :null}
                    {!dostawa && !wynos && stolik != null?  <div className="stolik">{stolik}</div> : <div className="stolik" style={{fontSize:"3rem", marginTop:"4.7rem", marginLeft:"1.5rem"}}>{opis} | {sektor}</div>}

                   
                    {stolik===null ?  <div className="nazwa-lokalizacji" style={{fontSize:"", margin:"0.5rem 0 0"}}>{nazwaLokalizacji}</div> : null} 
               
               {/* ZMIEN TO U GÓRY TO DO TESTÓW */}
               {!dostawa && !wynos && stolik != null? <div className="ilosc-zamowien"style={{fontSize:"", margin:"0rem 0 0 1.5rem"}} >{iloscZamowien}</div> : <div className="ilosc-zamowien"style={{fontSize:"2rem", margin:"0rem 0 4rem 1.5rem",left:"0rem"}} >{iloscZamowien}</div>}  
                    
                   {typZamowienia === 'internet' && <div className="ikona-internetu filter-white"><img src="img/world-wide-web (1).svg"/></div>} 
                    
                    
                   {!dostawa && !wynos && stolik != null?  <div className="czas-zamowienia ">{czasZamowienia}</div>:  <div className="czas-zamowienia " style={{fontSize:"2.5rem", top:".5rem"}}>{czasZamowienia}</div>}
                   
                   {timerKucharza != null ? <div className="timer-kucharza" style={{marginTop:'3rem'}}>{timerKucharza < 0 ? '-0:' + Math.abs(timerKucharza) : '0:' + Math.abs(timerKucharza)}</div> : null }
                    {!dostawa && !wynos && stolik != null? <hr/> : null} 
                    <div className="opis">
                    {!dostawa && !wynos && stolik != null?  <div className="opis-kontent">{opis}</div> : null}

                        
                    </div>
                </div>
               {obszar === 3  ? <></> : <div className="blok-body">
          
  

          <div className="zamowienie-text">

              {potrawy.map(x=>{
                   let podkreslone = x[1].odklikane == true ? ' line-through' : null;
                        if(katZamowienia.Kucharz.includes(x[1].ART_KatWydruku)){
                            return <p class="fs-3"> {x[1].DSL_Ilosc}x <span style={{textDecoration:x[1].odklikane == true ? ' line-through' : null}} >{x[1].ART_Nazwa}</span> {x[1].polka} </p>
                        }
                   
                 
              })}

          </div>
      </div>} 
            </div>
        </div>
        </span>
    </div>
    )
}

KafelekZamowienia.defaultProps = {
    onClick : () =>{console.log('default click');},
    stolik: "-1",
    nazwaLokalizacji: 'Nie podano',
    iloscZamowien: 0,
    czasZamowienia: '',
    timerKucharza: '',
    opis: "Default Description",
    sztucce: [''],
    zamowienia: [''],
    // kolor: 'box--green',
    typZamowienia: '',
    obszar: 1
}

export default KafelekZamowienia 