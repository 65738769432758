import React from 'react'
import SzczegolyZam from './SzczegolyZam'
import SzczegolyZamDSR from './SzczegolyZamDSR'
import { useLocation } from 'react-router'

export default function KelnerSzczegolyAll({match}) {
     const location = useLocation()
      let czasWydruku = new URLSearchParams(location.search).get("czas")
      let dsr = new URLSearchParams(location.search).get("dsr")
      let czyDsr = new URLSearchParams(location.search).get("czyDsr")
   
    return (
 
        <div>
          {czyDsr == 'true' ?  <SzczegolyZamDSR apiEndPoint={'rachunkiwiataall'} dsr={dsr} dsrPage={'test'} gotoweUrl={`/kelner`} match={match} czasWydruku={czasWydruku} powrot={'zam'}></SzczegolyZamDSR> 
          :  <SzczegolyZam apiEndPoint={'rachunkiwiataall'} dsrPage={'test'} gotoweUrl={`/kelner`} match={match} czasWydruku={czasWydruku} powrot={'zam'}></SzczegolyZam>
  }
        </div>
    )
}
