import przytrzymajModal from "../../funkcje/przytrzymajModal"
import {useEffect} from 'react'

function NaTerazKafelek(props) {  
    
    useEffect(() => {
        przytrzymajModal(props.query, `przytrzymajPrzycisk${props.query}`, props.artId)
    }, [])
    
    
    return (
        <div id={`przytrzymajPrzycisk${props.query}`} className={` middle-content-middle__gora__na-teraz__box ${props.kolor.KDL_DataKelnerska === null || props.kolor.KDL_DataKelnerska === 'null' ? 'box--red' : 'box--gray'} herbata`} onClick={(e) => {
           
       
            if(e.target.classList.contains('box--gray')){
                props.onClick(props.query, 'checked')
                e.target.classList.remove('box--gray')
                e.target.classList.add('box--red')
            }else{
                props.onClick(props.query, 'unchecked')
                e.target.classList.add('box--gray')
            }

        }}>{`${props.ilosc}x  ${props.nazwa} ${props.operator} ${props.czasPoz} `}
        
        </div>
    )
}

export default NaTerazKafelek
