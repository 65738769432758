
function NaTerazKafelekWydawka(props) {  

    return (
        <div className={`middle-content-middle__gora__na-teraz__box ${props.kolor.KDL_DataWydawka === null || props.kolor.KDL_DataWydawka === 'null' ? 'box--red' : 'box--gray'} herbata`} onClick={(e) => {
           
       
            if(e.target.classList.contains('box--gray')){
                props.onClick(props.query, 'checked')
                e.target.classList.remove('box--gray')
                e.target.classList.add('box--red')
            }else{
                props.onClick(props.query, 'unchecked')
                e.target.classList.add('box--gray')
            }

        }}>{`${props.ilosc}x  ${props.nazwa} ${props.polka} ${props.operator} ${props.czasPoz}`}</div>
    )
}

export default NaTerazKafelekWydawka
