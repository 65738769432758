import fetchApi from "../api/fetchApi";




    export  default async function logowanieZEkranu(ekran, pin) {
        let data = await fetchApi(`/logowanie?pin=${pin}`)
       
        if(data[0].length > 0){
        if (ekran == 'kelner'){
            console.log(data[0][0]);
            sessionStorage.setItem('OPR_KELNER', JSON. stringify({
            
                id: data[0][0].OPR_ID,
            imie: data[0][0].OPR_Imie,
            nazwisko: data[0][0].OPR_Nazwisko

            }));
        }

        
      }}
          