import OpisBox from '../components/zamowienie/OpisBox'
import Modal from '../components/zamowienie/Modal'
import NaTerazKafelek from '../components/zamowienie/NaTerazKafelek'
import NaTerazKafelekWydawka from '../components/zamowienie-wydawka/NaTerazKafelekWydawka'
import PotrawyKafelek from '../components/zamowienie/PotrawyKafelek'
import PotrawyKafelekWydawka from '../components/zamowienie-wydawka/PotrawyKafelekWydawka'
import ContextMenu from '../components/zamowienie/ContextMenu'
import KafelekSurowki from '../components/zamowienie/KafelekSurowki'
import Navbar from '../components/wszystkie-zamowienia/Navbar'
import {Helmet} from "react-helmet";
import DodatekKafelek from '../components/zamowienie/DodatekKafelek'
import PrzyciskiDrukujNapoje from '../components/zamowienie/PrzyciskiDrukujNapoje'
import KafelekInformacyjny from '../components/zamowienie/KafelekInformacyjny'
import PrzyciskWydawka from '../components/zamowienie/PrzyciskWydawka'
import PrzyciskWszystkieRachunki from '../components/zamowienie/PrzyciskWszystkieRachunki'
import JedenRachunek from '../components/zamowienie/JedenRachunek'
import PrzyciskZamianaZamowienia from '../components/zamowienie/PrzyciskZamianaZamowienia'
import PrzyciskZmianaZamowieniaWiecej from '../components/zamowienie/PrzyciskZmianaZamowieniaWiecej'
import Footer from '../components/wszystkie-zamowienia/Footer'
import FooterP from '../components/zamowienie/FooterP'
import NavbarP from '../components/zamowienie/NavbarP'
import KafelekNaTerazP from '../components/zamowienie/KafelekNaTerazP'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom';
import  { useContext, useEffect, useState, createContext, useMemo } from 'react'
import { ProduktyContext } from '../ProduktyContext'
import {Obszar3Context} from  '../Obszar3Context'
import {useLocation} from 'react-router'
import BelkaLewa from '../components/all/BelkaLewa'
import apiRequests from '../apiRequests'
import { set } from 'lodash'
import sortowanieDsrPrzyciski from '../funkcje/sortowanieDsrPrzyciski'

function Zamowienie({match}){
let modalInterval
  const location = useLocation()
  let czasWydruku = new URLSearchParams(location.search).get("czas")

  const {stolik} = useContext(ProduktyContext)

   //Logowanie () PIN
   let history = useHistory();
   //Logowanie
  const zaloguj = ()=>{
    let daneLogowania = JSON.parse(sessionStorage.getItem('OPR_WYDAWKA'))
  
    if(daneLogowania != null){
      return true;
    }else{
     history.push(`/logowanie?ekran=wydawka&stolik=${match.params.stolik}`)
     window.location.reload()
    }
    
  
  }

  let czas = czasWydruku

  var hms = czas    // your input string
  var a = hms.split(':'); // split it at the colons

  // Hours are worth 60 minutes.
  var minutes = (+a[0]) * 60 + (+a[1]);

 czas = minutes
  
  const[obszar1, setObszar1] = useState([])
  const[obszar2, setObszar2] = useState([])
  const[obszar3, setObszar3] = useState([])
  const[wszystkie, setWszystkie] = useState([])
  const [ostatnioOdk, setostatnioOdk] = useState([])
  const [czasZam, setczasZam] = useState([])
  const [czasZamMin, setczasZamMin] = useState([])

  
        //OpisDSR State
 const[dsrOpisy, setDsrOpisy] = useState([])
  let queryDslZZamowien = []
 
let wszystkieArr = []
  useEffect(()=>{
    if(localStorage.getItem('HOST') == null){
      localStorage.setItem('HOST', JSON.stringify({STA_ID:1}))
    }
  
    const getTasks = async () =>{
        const rachunkiFromServer = await fetchZamowienia()
        const odk = await ostatnioOdklikane()

        setostatnioOdk(odk)
        // console.log(rachunkiFromServer);
        setObszar1(rachunkiFromServer[0] != undefined || null ? rachunkiFromServer[0] : []  )
        setObszar2(rachunkiFromServer[1] != undefined || null ? rachunkiFromServer[1] : [] )
        if(rachunkiFromServer[2] === undefined ||rachunkiFromServer[2] === [] || rachunkiFromServer[2]=== null){

        }else{
          setObszar3(rachunkiFromServer[2] != undefined || null ? rachunkiFromServer[2] : [] )
        }
 
        setWszystkie(rachunkiFromServer[3] != undefined || null ? rachunkiFromServer[3] : [] )

 //OpisDSR 
        let opisyZDsrow = []
        let rachunki = [...rachunkiFromServer[1], ...rachunkiFromServer[2], ...rachunkiFromServer[0]]
        rachunki.map(x => {
          if(!opisyZDsrow.includes(x.DSR_OpisDostawy)){
            opisyZDsrow.push(x.DSR_OpisDostawy)
          }
        })

        setDsrOpisy(opisyZDsrow)
      
              setczasZam([...rachunkiFromServer[0],...rachunkiFromServer[1],...rachunkiFromServer[2]].find(x => x.czas != null).czas)
              setczasZamMin([...rachunkiFromServer[0],...rachunkiFromServer[1],...rachunkiFromServer[2]].find(x => x.czas != null).czas_w_minutach)
        wszystkieArr = rachunkiFromServer[3]
        console.log(rachunkiFromServer);

       
    }

     getTasks()

    let modal = setTimeout(() => {
      
      let czyWszystkieBezPrzejecia = wszystkieArr.every(x => x.KDL_PrzejetaWydawka === null || x.KDL_PrzejetaWydawka === 'null')
      let czyKtoresNieodklikane = wszystkieArr.every(x => x.KDL_DataWydawka === 'null' || x.KDL_DataWydawka === null)
     
      console.log(czyWszystkieBezPrzejecia, 'log');
      if(czyWszystkieBezPrzejecia && czyKtoresNieodklikane){
        if(document.getElementById('wlaczModalPrzejmijZam') != null){
         clearTimeout(modal)
         document.getElementById('wlaczModalPrzejmijZam').click() 
         
        }
  }
    },10000)

     let zapytajBaze = setInterval(()=>getTasks(),5000)

    
     

     return () =>{
       clearInterval(zapytajBaze)
      clearTimeout(modal)
     }
 },[])

 
wszystkie.map(x => {

  queryDslZZamowien.push(x.DSL_ID)
})
  console.log(queryDslZZamowien.join(','))
 
 
  async function ostatnioOdklikane  (){
    if(JSON.parse(sessionStorage.getItem('OPR_WYDAWKA')) != null){
      const res = await fetch(`https://kds.sereczyn.pl/api/odklikaneostatniowydawka?host=${JSON.parse(localStorage.getItem('HOST')).STA_ID}&opr=${JSON.parse(sessionStorage.getItem('OPR_WYDAWKA')) != null && JSON.parse(sessionStorage.getItem('OPR_WYDAWKA')).id}`,{method:"POST"})
      const data = await res.json()
      return data
    }
  }
 
 // Fetch Data (Różne Zamóienia)
 const fetchZamowienia = async()=>{
  let res = await fetch(`https://kds.sereczyn.pl/api/rachunki`,{method:"POST"})
   res = await fetch(`https://kds.sereczyn.pl/api/rachunkiwydawka/${match.params.stolik}/${match.params.dsr}`,{method:"POST"})
     const data = await res.json()
     return data
 }

  // Przejmij zamowienie
  const przejmijZam = async()=>{
    const res = await fetch(`https://kds.sereczyn.pl/api/przejmijzam?query=${queryDslZZamowien.join(',')} &idwydawki=1`,{method:"POST"})
     const data = await res.json()
     return data
  }
 
 //Segregowanie przycisków
 var dsrPrzyciski = wszystkie.reduce(function(dsrPrzyciski, org) {
  (dsrPrzyciski[org.DSR_ID] = dsrPrzyciski[org.DSR_ID] || []).push(org);
  return dsrPrzyciski;
}, {})

dsrPrzyciski = Object.entries(dsrPrzyciski);
dsrPrzyciski = sortowanieDsrPrzyciski(dsrPrzyciski)   

//Segregowanie Nateraz/surowek/deserow
var obszar1Zam = obszar1.reduce(function(ob, org) {
  (ob[org.ART_Nazwa] = ob[org.ART_Nazwa] || []).push(org);
  return ob;
}, {})

obszar1Zam = Object.entries(obszar1Zam)

obszar1Zam.map(grupa =>{
  let ilosc =0;
  let strDslId = []
  grupa[1].map(pozycja =>{
    ilosc += Number(pozycja.DSL_Ilosc)
    strDslId.push(',',pozycja.DSL_ID)
  
  })
  grupa.push({nazwa:grupa[0], ilosc: ilosc, kat: grupa[1][0].ART_KatWydruku, query: strDslId.join('').substring(1),polka: grupa[1][0].KDL_Polka, operator: grupa[1][0].wydawkaOpr, czasPoz : grupa[1][0].czas_pozycja})
})



var obszar2Zam = obszar2.reduce(function(ob, org) {
  (ob[org.ART_Nazwa] = ob[org.ART_Nazwa] || []).push(org);
  return ob;
}, {})

obszar2Zam = Object.entries(obszar2Zam)

obszar2Zam.map(grupa =>{
  let ilosc =0;
  let strDslId = []
  grupa[1].map(pozycja =>{
    ilosc += Number(pozycja.DSL_Ilosc)
    strDslId.push(',',pozycja.DSL_ID)
  
  })
  grupa.push({nazwa:grupa[0], ilosc: ilosc, kat: grupa[1][0].ART_KatWydruku, query: strDslId.join('').substring(1), polka: grupa[1][0].KDL_Polka, operator: grupa[1][0].wydawkaOpr, czasPoz : grupa[1][0].czas_pozycja})
})




// console.log(obszar1Zam, "OBSZAR1");
// console.log(obszar2Zam, "OBSZAR2");
// console.log(obszar4Zam, "OBSZAR4");

//Odklikaj wszystkie pozycje w kafelku
function odklikajPozycje(query, status){

    const fetchZamowienia = (q,s)=>{
      
      fetch(`https://kds.sereczyn.pl/api/odklikajPozycje?query=${q}&status=${s}&ekran=Wydawka&operator=${(JSON.parse(sessionStorage.getItem('OPR_WYDAWKA'))).id}&host=${JSON.parse(localStorage.getItem('HOST')).STA_ID}`,{method:"POST"})
  }
    fetchZamowienia(query, status)
    // console.log('something is change?');
  
  
 
}


    return (<>
{/* pop up */}

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">

      <div class="modal-body box--black" style={{textAlign:'center'}}>
        <h3>Czy chcesz przejac to zamowienie?</h3>
      </div>
      <div class="modal-footer box--black" style={{textAlign:'center'}}>
      <div type="button" style={{display:'inline-block'}} class="btn btn-primary" onClick={() => {
        przejmijZam() 
       
         }} data-dismiss="modal" >Tak</div>
        <div type="button"  style={{display:'inline-block'}} class="btn btn-secondary" data-dismiss="modal">Nie</div>
      
      </div>
    </div>
  </div>
</div>

      <button type="button" class="btn btn-primary display-hidden" id="wlaczModalPrzejmijZam" style={{position:'absolute', top:'30%'}} data-toggle="modal" data-target="#exampleModal">
</button>


<div className="computer-version">
    {/* {NAVBAR} */}
    <div className="fixed-top">
      <div id="computer-nav" className={`navbar px-4 ${ czasZamMin > 40 ? 'box--red' : ''}  ${ czasZamMin >=0 && czasZamMin <= 15 ? 'box--gray' : '' }${czasZamMin > 15 && czasZamMin <=25 ? 'box--green' : '' }${czasZamMin > 25 && czasZamMin <=40 ? 'box--yellow' : '' }`} style={{fontSize:"1.5rem !important"}}>
        <div className="navbar__lokalizacja-i-status ">
          <span className="navbar__lokalizacja-i-status__ikona icon-sm">🍕</span>
          <span className="navbar__lokalizacja-i-status__stolik">{match.params.stolik}</span>
        
          <span className="navbar__lokalizacja-i-status__ilosc-zamowien" style={{marginRight:'5rem'}}>{`     (${dsrPrzyciski.length})`}</span>
          <span className="navbar__lokalizacja-i-status__stolik">{wszystkie.find(x => x.DSR_Telefon != '') != undefined ? wszystkie.find(x => x.DSR_Telefon != '').DSR_Telefon : null}</span>
          <span className="navbar__lokalizacja-i-status__stolik">{wszystkie.find(x => x.sektor != null) != undefined ? wszystkie.find(x => x.sektor != null).sektor : null}</span>

          {!wszystkie.some(x => x.KDL_PrzejetaWydawka === null) ? `Przejęte przez ${JSON.parse(sessionStorage.getItem('OPR_WYDAWKA')).imie} ` : null}
          {/* <span className="navbar__lokalizacja-i-status__status">VIP</span> */}
          {/* <span className="navbar__lokalizacja-i-status__lokalizacja">Rybakówka</span> */}
        </div>
      
        <div className="navbar__czas-zamowienia">
          <span className="navbar__czas-zamowienia__timer">{czasZam}</span>
        </div>
      </div>
    </div>
            {/* {NAVBAR} */}
        {/* Lewa strona */}
        <div className="middle-content row px-3 margin-top-middle-container">
        <div className="middle-content__left  col-xl-2 col-lg-2 col-md-2">
         
        {dsrOpisy.map(x => { 
            if(x != ''){
              return   <OpisBox opis={x} />
            }
          
           })}
        
         <div className="middle-content__left__sidebar mt-3 row" >

       
<div className="middle-content__left__sidebar__container">
         {obszar2Zam.map(x =>{
              return <NaTerazKafelekWydawka ilosc={x[2].ilosc} 
              czasPoz={x[2].czasPoz} operator={x[2].operator} polka={x[2].polka} wydawka={1} query={x[2].query} kolor={x[1][0]} nazwa={x[0]} onClick={odklikajPozycje}/>
            })}
       </div>
       </div>
      </div>
  
        {/* Środek Góra */}
      
      <ContextMenu/>
      <div className="middle-content-middle col-xl-8 col-lg-8 col-md-8">
      <div className="middle-content-middle__gora py-3">
          <div className="middle-content-middle__gora__na-teraz">
            {obszar1Zam.map(x =>{
              return <NaTerazKafelekWydawka ilosc={x[2].ilosc} 
              czasPoz={x[2].czasPoz} operator={x[2].operator} kolor={x[1][0]} wydawka={1} polka={x[2].polka} query={x[2].query} nazwa={x[0]} onClick={odklikajPozycje}/>
            })}
            
            </div>
            </div>
            <div className="middle-content-middle__środek py-1">
            <div className="middle-content-middle__srodek__potrawy">
           {/* <Obszar3Context.Provider value={{obszar3, setObszar3}}> */}
           {obszar3.map((x,idx )=>{
              if(x.DSL_DSLIDPowiazana == null){
                return  <PotrawyKafelekWydawka czasPoz={x.czas_pozycja}  nazwa={x.ART_Nazwa}  operator={x.wydawkaOpr} polka={x.KDL_Polka} wydawka={1} ilosc={x.DSL_Ilosc}  onClick={odklikajPozycje} DSL_ID={x.DSL_ID} obszar={obszar3} zamowienie={x} />
              }
            
            })}
             {/* </Obszar3Context.Provider> */}
           
            </div>
              </div>
              <div className="middle-content-middle__dol py-3">

          <div className="middle-content-middle__dol__dodatki">

          
            
            </div>
            </div>
      </div>

      <div className="middle-content-right col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 mt-4">
        <div className="sidebar-right">
     <PrzyciskiDrukujNapoje/>
    
          <div className="sidebar-right__kafelki-informacyjne">

  
    
            

          </div>
       
          <div className="sidebar-right__przyciski-wydawka">
         
       <PrzyciskWydawka/>
            
        </div>


  
        <div className="sidebar-right__rachunki" style={{marginBottom:"-4.5rem"}}>
          {dsrPrzyciski.map(x =>{
           return <Link to={`/zamwydawka/${match.params.stolik}?czas=${czasWydruku}`}> <JedenRachunek rachunekNumerek={x[1][0].DSR_NrRachunku} onClick={()=> 
            setTimeout(()=> history.push(`/zamwydawka/${match.params.stolik}/${x[0]}?czas=${czasWydruku}`),40)
          }/></Link>
          })}
          

         <Link to={`/zamwydawka/${match.params.stolik}?czas=${czasWydruku}`}> <PrzyciskWszystkieRachunki/></Link>
            
          </div>
        </div>
        <div className="sidebar-right__zmiana-zamowienia">
        <div >
        {ostatnioOdk.map(x => {
                     return  <a className="btn btn-primary btn-lg btn--yellow" style={{fontSize:"10px"}} onClick={

                       () =>
                       { history.push(`/wydawka`)
                        setTimeout(() => history.push(`/zamwydawka/${x.DSR_Opis}?czas=${x.czas}`),10)}

                     }>{x.DSR_Opis}</a>
                   })}
          
        </div>
         <PrzyciskZmianaZamowieniaWiecej/>
        </div>

      </div>
     {/* BELKA */}
  <div className="fixed-bottom">
          <link rel="stylesheet" href="./sass/main.css"></link>
        <div className="belka">
      
  
          <div className="belka__srodek">
          {/* <Link to="/napoje"> <span>Napoje</span> </Link> */}
          <span style={{marginLeft:'2rem'}} onClick={() => {przejmijZam()
          }}>Przejmij Zam.</span> 
          </div>
  
          <div className="belka__prawa">
          {/* <Link to="/raport"><span>Raport</span></Link>
          <Link to="/imprezy"><span>Imprezy</span></Link> */}
          <Link to="/wydawka"><div className="btn btn btn-outline-light btn-lg" style={{padding:'1rem 1.5rem 1rem' }}>Gotowe</div></Link>
     
          </div>
        </div>
      </div>
      {/* BELKA */}
      </div>
      {/* <Helmet>
      <script src="./scripts/e-zam.js" type="text/javascript" />
      <script defer src="./libki/keyboard/keyboard.js" type="text/javascript" ></script>
      <script defer src="scripts/long-press.js"></script>
            </Helmet> */}

            </div>

            <div className="phone-version">
             <NavbarP/>
            
             <div id="phone-content">
      <div id="zamowienia-tel" className="zamowienia">
      <div id="naTeraz">
      <h2 style={{textAlign:"center"}}>NA TERAZ</h2>
      <div className="row">
      <KafelekNaTerazP/>
      <KafelekNaTerazP/>
      <KafelekNaTerazP/>
      <KafelekNaTerazP/>
   
</div>
      </div>
      <div id="potrawy">
      <h2 style={{textAlign:"center"}}>POTRAWY</h2>
          <div className="row">
       
         <PotrawyKafelek phone={true} />
         <PotrawyKafelek phone={true} />
         <PotrawyKafelek phone={true} />
         <PotrawyKafelek phone={true} />
             
             
              </div>
          
          </div>

          <hr/>
          <div id="surowki">
            <h2 style={{textAlign:"center"}}>SURÓWKI</h2>
          <div className="row">
          <KafelekNaTerazP/>
      <KafelekNaTerazP/>
      <KafelekNaTerazP/>
</div>

          </div>
          <hr/>
          <div id="dodatki">
            <h2 style={{textAlign:"center"}}>DODATKI</h2>
          <div className="row">
          <KafelekNaTerazP/>
      <KafelekNaTerazP/>
      <KafelekNaTerazP/>
</div>

          </div>
          <div id="sidebar-tel" className="sidebar " >


   <PrzyciskiDrukujNapoje/>

<div className="sidebar-right__kafelki-informacyjne">


</div>

<OpisBox phone={true}/>

<div className="sidebar-right__przyciski-wydawka">
  <PrzyciskWydawka/>
  <PrzyciskWydawka/>
  <PrzyciskWydawka/>
  <PrzyciskWydawka/>
</div>

<div className="sidebar-right__rachunki">
<div style={{maxHeight: "7.9rem", overflow:"scroll",marginBottom:"-1.5rem"}}>
 <JedenRachunek/>
 <JedenRachunek/>
 <JedenRachunek/>
 </div>


  <div className="sidebar-right__rachunki__wszystkie-rachunki box--gray">
    
 <PrzyciskWszystkieRachunki/>
  </div>
</div>

<div className="sidebar-right__zmiana-zamowienia">
  <div className="przyciski" style={{maxHeight: "12rem", overflow:"scroll", marginBottom:"-1.5rem"}}>
<PrzyciskZamianaZamowienia/>
<PrzyciskZamianaZamowienia/>
<PrzyciskZamianaZamowienia/>
<PrzyciskZamianaZamowienia/>
<PrzyciskZamianaZamowienia/>
<PrzyciskZamianaZamowienia/>
<PrzyciskZamianaZamowienia/>
</div>
<PrzyciskZmianaZamowieniaWiecej/>
</div>


</div>

        </div>
        
        <hr/>
      
  
        </div>


             <FooterP/>

            </div>

            
        </>

        
        )
}

export default Zamowienie