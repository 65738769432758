import React from 'react'
import { useHistory } from 'react-router-dom';

export default function PieceBox(params) {
  let history = useHistory()
 
  function dodajPolke(){
    history.push(`/piece/${params.stolik}`)
   }
  
  return (
        <div class="box--yellow piece-btn" style={{width:'100%'}} onClick={() => dodajPolke()}>
          <p>Piece</p>
        </div>
    )
}
