import React from 'react'

export default function PrzyciskZamianaZamowienia({kolor}) {
    return (
        <div className={`sidebar-right__zmiana-zamowienia__przycisk box--yellow ${kolor} ml-3`}>222</div>
    )
}

PrzyciskZamianaZamowienia.defaultProps = {
    kolor: "box--yellow"
}