import KafelekSidebar from '../components/wszystkie-zamowienia/KafelekSidebar'
import KafelekZamBg from '../components/wszystkie-zamowienia/KafelekZamBg'
import { useHistory } from 'react-router-dom';
import {useState, useEffect} from 'react'
import Rachunki from '../components/wszystkie-zamowienia/Rachunki'
import KafelekReklamacjiP from '../components/wszystkie-zamowienia/KafelekReklamacjiP';
import KafelekSprzatnacP from '../components/wszystkie-zamowienia/KafelekSprzatnacP';
import KafelekNaWynosP from '../components/wszystkie-zamowienia/KafelekNaWynosP';
import KafelekKonwersacjaP from '../components/wszystkie-zamowienia/KafelekKonwersacjaP';
import Footer from '../components/wszystkie-zamowienia/Footer';
import { ProduktyContext } from '../ProduktyContext';
import apiRequests from '../apiRequests'
import {useContext} from 'react'
import { Link } from 'react-router-dom'
import BelkaLewa from '../components/all/BelkaLewa';
import Zamowienie from '../components/wiata/Zamowienie';
import scrollButton from '../funkcje/scrollButton'


function WiataMain(){

//===Imports / Requiers
const history = useHistory()

//=== Variables/States || s - posortowane/grupowane
const [wszystkieZamowienia, setWszystkieZamowienia] = useState([])
const [stanowiska, setstanowiska] = useState([])
const [sNaTeraz, setsNaTeraz] = useState([])
const [odklikaneZamowienia, setodklikaneZamowienia] = useState([])
const [ostatnioOdklikane, setostatnioOdklikane] = useState([])


//===Functions 
const ustawHosta = async() => {
    if(localStorage.getItem('HOST') == null){
      document.getElementById('wlaczModalUstawieniaStanowiska').click()
    }
  }

//Logowanie
const zaloguj = (stolik)=>{
  let daneLogowania = JSON.parse(sessionStorage.getItem('OPR_KELNER'))
  if(daneLogowania != null){
  }else{
   history.push(`/logowanie?ekran=kelner&stolik=${stolik}`)
  }
}

//Odklikane Zam Api
async function odklikane  (){
  const res = await fetch("https://kds.sereczyn.pl/api/odklikanewiata",{method:"POST"})
  const data = await res.json()
  return data
}

async function wiataFetch(){
  //Dsle pogrupowane skryptem
  let wszystkieZam = (await apiRequests.wszystkieZamowienia())
  setWszystkieZamowienia([...wszystkieZam])

  //Odklikane Zamówienia
  let odklikaneZam = await odklikane()
  setodklikaneZamowienia(odklikaneZam)

  let ostatnio = await apiRequests.ostatnioOdklikane('kelner')
  setostatnioOdklikane(ostatnio)

  //naTeraz
  let sNaTeraz = (await apiRequests.sortWiataNaTeraz())
  setsNaTeraz(sNaTeraz)
      console.log("S NaTeraz)", sNaTeraz);
}

//===UseEffects
useEffect(async()=>{
  //ekran logowania
  if(localStorage.getItem('Zalogowany') != 'true'){
    history.push('/ekranlogowania')
  }  
 
    
  //Ustawienie stata z listą stanowisk
    let stanowiska = (await apiRequests.getStanowiska())
    setstanowiska(stanowiska[0])

    //Dsle pogrupowane skryptem
    let wszystkieZam = (await apiRequests.wszystkieZamowienia())
    setWszystkieZamowienia([...wszystkieZam])

    //Odklikane Zamówienia
    let odklikaneZam = await odklikane()
    setodklikaneZamowienia(odklikaneZam)

    let ostatnio = await apiRequests.ostatnioOdklikane('kelner')
    setostatnioOdklikane(ostatnio)

    //naTeraz
    let sNaTeraz = (await apiRequests.sortWiataNaTeraz())
    setsNaTeraz(sNaTeraz)
        console.log("S NaTeraz)", sNaTeraz);

    ustawHosta()

    //Zabezpieczenie jeżeli ktoś nie wybierze hosta
    if(localStorage.getItem('HOST') == null){
      localStorage.setItem('HOST', JSON.stringify({STA_ID:1}))
    }



       },[])

      useEffect(() => {
        let scrollPositionW = sessionStorage.getItem('scrollWiata')
        setTimeout(() =>  window.scrollBy(0, Number(scrollPositionW) ),1000)
        let scrollW  = setInterval(() => sessionStorage.setItem('scrollWiata', window.pageYOffset)   ,400)
        scrollButton()    

        let wiataIntervalFetch = setInterval(() => {
          wiataFetch()
        }, 5000);
        return () => {
                clearInterval(scrollW)
                clearInterval(wiataIntervalFetch)
              }
      }, [])  
     
    return(
<>
<link rel="stylesheet" href="./sass/pages/_ekran-wszystkie-zamowienia.css"/>

<div className="container1024 row">
{/* Trick aby wydobyć margin top na telefonie */}
<div className="sidebar--telefon mt-5">
          <div className="mt-5">
 </div>
          {/*  */}
          </div>
        <div className="col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 container__srodek">
            <div className="na-Teraz row">
                
            {sNaTeraz.map(c => {
                 return wszystkieZamowienia.map(x=>{
                   //Zamiana tablicy na varible łatwe do zrozumienia || c - z sortu 
                    let dsl = x[0][0]
                    let dslgrupa = x[0]
                 if(`${c.DSR_Opis}`.replace(/\s/g, "") == `${dsl.DSR_Opis}`.replace(/\s/g, "") && c.DSR_Opis != ''){
                  console.log(c.numerek);
                        
                  return  <KafelekZamBg 
                       
                  wynos={dsl.TOR_CzyNaWynos}
                  dostawa={dsl.TOR_CzyZDostawa}
                   wydawka={2}
                   stolik={dsl.DSR_STOID} 
                  nazwaLokalizacji={''}
                  iloscZamowien = {c.IloscRachunkow}
                  czasZamowienia = {c.czas}
                  timerKucharza = {c.timer}
                  opis={dsl.DSR_Opis}
                  sztucce={dslgrupa}
                  kolor={'box--yellow'}
                  typZamowienia={dsl.typZamowienia}
                  zamowienia={dslgrupa}
                  obszar={4}
                  DSR_ID = {dsl.DSR_ID}
                  sektor = {c.sektor}
                  numerek= {c.numerek}
                   />
                 }
                      
                    } )
                 })
                }
                

                 </div>  
                 <hr/>
                 <div className="do-Wydania row">
                 {//Do wydania Zamówienia
                 }  
                </div>
                <hr/>
                 <div className="wszystkie-Zamowienia row">
                 {//Do wydania Zamówienia
                 }  
                </div>
            </div>

            <div className="fixed-bottom" style={{marginBottom:'5.5rem'}}>
            <div id="rachunki" style={{maxWidth:"80vh"}}>
         {odklikaneZamowienia.map(x => {
             
             return   <div class="rachunki-box box--green"  onClick={() => {history.push(`/zamwiata/${x.DSR_Opis}?czas=${x.czas}`)}}><span style={{fontSize:"2.5rem"}}>{x.DSR_Opis}</span> </div>
         })}
      
        </div>
</div>
            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 sidebar-container" style={{textAlign:'center'}}>
            <div className="sidebar--komputer">
                <div className="sidebar ">
                <button type="button" class="btn btn-outline-light" style={{width:'95%', pointerEvents:'none', fontSize:'2rem', height:'4rem', marginLeft:'.5rem'}}>Wiata</button>
 

                </div>

              
                <div className="przelaczZamowienie">
               
                <div className="row">
                    
                    {ostatnioOdklikane.map(x => {
                      return  <a className="btn btn--przelaczZamowienie btn--yellow" style={{fontSize:"10px"}} onClick={
                        () => history.push(`/zamwiata/${x.DSR_Opis}?czas=${x.czas}`)
                      }>{x.DSR_Opis}</a>
                    })}
                </div>
              
              
               <div className="row">
                   <div className="col-xl-12 col-lg-12 col-md-12 com-sm-12 przelaczZamowienie__wszystkie">
                       <a href="#" className="btn btn--grey btn--wszystkie">Wszystkie</a>
                   </div>
               </div>

           </div>

            </div>
             
              
            
            
        </div>     
        </div>
        <div className="belka--komputer ">

            {/* BELKA */}
  <div className="fixed-bottom">
          <link rel="stylesheet" href="./sass/main.css"></link>
        <div className="belka">
  
         <BelkaLewa/>
         <button id="scrollButtonDown" class="btn btn-lg"> ⏬ </button>
                   <button id="scrollButtonUp" class="btn btn-lg"> ⏫ </button>
          
          <div className="belka__prawa">
          {/* <Link to="/raport"><span>Raport</span></Link>
          <Link to="/imprezy"><span>Imprezy</span></Link> */}
     
     
          </div>
        </div>
      </div>
      {/* BELKA */}
        </div>
       


{/* MODAL */}
    <div class="modal " id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">

      <div class="modal-body box--black" style={{textAlign:'center'}}>
        <h3>Skonfiguruj Stanowisko</h3>
      
        <label for="cars">Wybierz swoje stanowisko</label>
           
        
        <select name="cars" id="cars"  style={{width:'100%', color:'black'}} onChange={e => stanowiska.map(c => {
           if(c.STA_ID == e.target.value.substr(0,e.target.value.indexOf(' '))) {localStorage.setItem('HOST', JSON.stringify(c))} 
        })}>
                <option style={{color:'black'}}></option>
        {stanowiska.map(x => {
              return <option  style={{color:'black'}}>{x.STA_ID} { x.STA_Nazwa}</option>
           })}
       
        </select>

      </div>
      <div class="modal-footer box--black" style={{textAlign:'center'}}>
      <div type="button" style={{display:'inline-block'}} class="btn btn-primary" data-dismiss="modal" >OK</div>
      </div>
    </div>
  </div>
</div>

      <button data-backdrop="static" data-keyboard="false" type="button" class="btn btn-primary display-hidden" id="wlaczModalUstawieniaStanowiska" style={{position:'absolute', top:'30%'}} data-toggle="modal" data-target="#exampleModal">
</button>

                </>
    )

}

export default WiataMain

