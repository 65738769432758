import React from 'react'
import {Link} from 'react-router-dom'

export default function WTrakcieBudowy() {
    return (
        <div>
            <h1>Ta strona jest w trakcie budowy</h1>
            <Link to="/kelner"><button type="button" class="btn btn-primary btn-lg">Powrót</button></Link>
       
        </div>
    )
}
