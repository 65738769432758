import React from 'react'
import BelkaLewa from '../components/all/BelkaLewa'
import Footer from '../components/wszystkie-zamowienia/Footer'
import {Link} from 'react-router-dom'
import QrScanner from '../libki/QrScanner'


function Test(props) {
    return (
        <>
        <button class={`btn-${props.kolor}`} style={{width:'100%', height:'100%'}}></button>
        <button class={`btn-${props.kolor}`} style={{width:'100%', height:'100%'}}></button>
        <button class={`btn-${props.kolor}`} style={{width:'100%', height:'100%'}}></button>
        <button class={`btn-${props.kolor}`} style={{width:'100%', height:'100%'}}></button>
        <button class={`btn-${props.kolor}`} style={{width:'100%', height:'100%'}}></button>
        <button class={`btn-${props.kolor}`} style={{width:'100%', height:'100%'}}></button>
        <button class={`btn-${props.kolor}`} style={{width:'100%', height:'100%'}}></button>
        </>
    )
}

export default function Taca() {
//    Style
    const mainContainerGrid = {
        display:'grid', 
        gridTemplateColumns:'3fr 1fr',
        gridGap: "1rem"
    }

    const secondaryContainerGrid = {display:'grid',
     gridTemplateColumns:'repeat(4,24.33333%)',
     gridAutoRows: "minmax(20rem, auto)",
     gridGap: "1rem",
    }

    const notificationContainer = {
        display:'grid',
        gridTemplateColumns:'1fr',
        gridTemplateRows:'1fr 7fr 1fr 1fr',
        gridRowGap:'1rem',
        height:'92vh',
        width:'24.6vw'
    }
    
    
    return (
        // Main container
        <div style={mainContainerGrid}>
       
            {/* Zamówienia */}
            <div>
                {/* 1. Miejsce */}
                {/* Sopiować to aby utworzyć nową sekcję */}
                <div style={secondaryContainerGrid}>
                {/* Tutaj dodawać zamówienia */}
                <Test kolor='secondary'/>
                </div>
                {/* Skopiować to aby utworzyć nową sekcję */}

                 {/* 2. Miejsce */}
                <div style={{...secondaryContainerGrid, marginTop:'.5rem'}}>
                <Test kolor='primary'/>
                </div>

                 {/* 3. Miejsce */}
                <div style={{...secondaryContainerGrid, marginTop:'.5rem', marginBottom:'5.3rem'}}>
                <Test kolor='warning'/>
                </div>
           
            </div>

            {/* Notyfikacje */}

            <div>
              <div class="position-fixed">
                    <div style={notificationContainer}>
                    <button class={`btn-danger`} style={{width:'100%', height:'100%'}}></button>
                    <button class={`btn-danger`} style={{width:'100%', height:'100%'}}></button>
                    <button class={`btn-danger`} style={{width:'100%', height:'100%'}}></button>
                    <button class={`btn-danger`} style={{width:'100%', height:'100%'}}></button>
                    </div>
                 </div>
            </div>

            {/* Belka */}
            <Belka/>

        </div>
    )
}




// Komponenty 
function Belka(){
    return(
       
       <div className="belka--komputer ">
       <div className="fixed-bottom">
    <link rel="stylesheet" href="./sass/main.css"></link>
    <div className="belka">
     <BelkaLewa/>
      <div className="belka__srodek">
      <Link to="/napoje"> <span>Napoje</span> </Link>
      </div>
      <div className="belka__prawa">
      {/* <Link to="/raport"><span>Raport</span></Link>
      <Link to="/imprezy"><span>Imprezy</span></Link> */}
      </div>
    </div>
  </div>
    </div>
    ) 
}