import KafelekSidebar from '../components/wszystkie-zamowienia/KafelekSidebar'
import KafelekZamBg from '../components/wszystkie-zamowienia/KafelekZamBg'
import { useHistory } from 'react-router-dom';
import {useState, useEffect} from 'react'
import Rachunki from '../components/wszystkie-zamowienia/Rachunki'
import KafelekReklamacjiP from '../components/wszystkie-zamowienia/KafelekReklamacjiP';
import KafelekSprzatnacP from '../components/wszystkie-zamowienia/KafelekSprzatnacP';
import KafelekNaWynosP from '../components/wszystkie-zamowienia/KafelekNaWynosP';
import KafelekKonwersacjaP from '../components/wszystkie-zamowienia/KafelekKonwersacjaP';
import Footer from '../components/wszystkie-zamowienia/Footer';
import { ProduktyContext } from '../ProduktyContext';

import {useContext} from 'react'
import { Link } from 'react-router-dom'
import BelkaLewa from '../components/all/BelkaLewa';
import KafelekZamLite from '../components/wszystkie-zamowienia/KafelekZamowienieLite';
import scrollButton from '../funkcje/scrollButton'

function AllZamMid(){

  document.addEventListener('keydown', function(event) {
    if(event.keyCode == 115) {
        document.getElementById('wlaczModalUstawieniaStanowiska').click()
    }
   
});

  //Przenoszenie do innej strony (strony pojedynczego zamowienia)
    let history = useHistory();
    const {stolik, setStolik} = useContext(ProduktyContext)

    const[naTerazZamowienia, setZamowienia] = useState([])
    const[doWydaniaZamowienia, setDoWydania] = useState([])
    const[resztaZamowien, setResztaZamowien] = useState([])
    const[wszystkieZamowienia, setWszystkieZamowienia] = useState([])
    const [odklikane, setodklikane] = useState([])
    const [ostatnioOdklikane, setOstatnioOdklikane] = useState([])
    const [stanowiska, setstanowiska] = useState([])
    const [wybraneStanowisko, setWybraneStanowisko] = useState('')


    const[naTerazZamowieniaSort, setZamowieniaSort] = useState([])
    const[doWydaniaZamowieniaSort, setDoWydaniaSort] = useState([])
    const[resztaZamowienSort, setResztaZamowienSort] = useState([])
        
//Logowanie
const zaloguj = (stolik)=>{
  let daneLogowania = JSON.parse(sessionStorage.getItem('OPR_KELNER'))

  if(daneLogowania != null){
   
  }else{
   history.push(`/logowanie?ekran=kelner&stolik=${stolik}`)
   window.location.reload()
  }
  

}



useEffect(()=>{

  if(localStorage.getItem('Zalogowany') != 'true'){
    history.push('/ekranlogowania')
  }

  const ustawHosta = async() => {
    if(localStorage.getItem('HOST') == null){
      document.getElementById('wlaczModalUstawieniaStanowiska').click()
    }
  }
  ustawHosta()

  async function getStanowiska  (){
    const res = await fetch("https://kds.sereczyn.pl/api/getstanowiska",{method:"POST"})
    const data = await res.json()
    return data
  }

  if(localStorage.getItem('HOST') == null){
    localStorage.setItem('HOST', JSON.stringify({STA_ID:1}))
  }


      async function resztaGroup  (){
        const res = await fetch("https://kds.sereczyn.pl/api/kelnerreszta",{method:"POST"})
        const data = await res.json()
        return data
      }
      async function wydawkaGroup  (){
        const res = await fetch("https://kds.sereczyn.pl/api/kelnerwydawka",{method:"POST"})
        const data = await res.json()
        return data
      }
      async function naTerazGroup  (){
        const res = await fetch("https://kds.sereczyn.pl/api/kelnernateraz",{method:"POST"})
        const data = await res.json()
        return data
      }
      async function odklikane  (){
        const res = await fetch("https://kds.sereczyn.pl/api/odklikanekelner",{method:"POST"})
        const data = await res.json()
        return data
      }
      async function ostatnioOdklikane  (){
        
        const res = await fetch(`https://kds.sereczyn.pl/api/odklikaneostatniokelner?host=${JSON.parse(localStorage.getItem('HOST')).STA_ID}`,{method:"POST"})
        const data = await res.json()
        return data
      }


        const getTasks = async () =>{
            const zamowieniaFromServer = await fetchZamowienia()
            const resztaG = await resztaGroup()
            const doWydaniaG = await wydawkaGroup()
            const naTerazG = await naTerazGroup()
            const odk = await odklikane()
            const ostatnioOdk = await ostatnioOdklikane()
            const stanowiska = await getStanowiska()

            console.log(resztaG, 'SEKTORY');
          console.log(zamowieniaFromServer);
            setstanowiska(stanowiska[0])
            setodklikane(odk)
            setOstatnioOdklikane(ostatnioOdk)

            let katObszar1 = [3,8]
            let katObszar2 = [1,2,4,5,6,7,9]
            let katObszar3 = [1,2,4,5,6,7,9]
            let kategorieKelAll = [1,2,3,4,5,6,8,7,9]
            let all = [];
         
           zamowieniaFromServer[3].map((x,idx) => {
              all[idx] = x[0].filter(c => kategorieKelAll.includes(c.ART_KatWydruku))
            })

            all.filter((x,idx) => x.length == 0 ? delete all[idx] : null)
                
            // console.log(all);
               let  naTeraz = zamowieniaFromServer[0].map(c => c.filter(x => katObszar1.includes(x.ART_KatWydruku)))
                let wydawka = zamowieniaFromServer[1].map(c => c.filter(x => katObszar3.includes(x.ART_KatWydruku)))
                let reszta = zamowieniaFromServer[2].map(c => c.filter(x => katObszar3.includes(x.ART_KatWydruku)))


                setResztaZamowienSort(resztaG)
                setDoWydaniaSort(doWydaniaG)
                setZamowieniaSort(naTerazG)
                // console.log(wydawka);
                setZamowienia(naTeraz)
                setDoWydania(wydawka)
                setResztaZamowien(reszta)
                setWszystkieZamowienia(all)

             console.log(wybraneStanowisko);
              
                
        }
        
        // sessionStorage.removeItem('OPR_KUCHARZ')
        sessionStorage.removeItem('OPR_KELNER')
         getTasks()
        //  ustawHosta()

        
        let scrollPosition = sessionStorage.getItem('scrollKelner')
        // console.log(scrollPosition);
       setTimeout(() =>  window.scrollBy(0, Number(scrollPosition) ),1200)
        let scroll  = setInterval(() => sessionStorage.setItem('scrollKelner', window.pageYOffset)   ,400)
      
         
         let zapytajBaze = setInterval(()=>getTasks(),5000)
  
        scrollButton()
        return () => {
          clearInterval(scroll)
          clearInterval(zapytajBaze)
 
        }
     },[])
     
    
     
 
     // Fetch Data (Różne Zamóienia)
     const fetchZamowienia = async()=>{
         const res = await fetch("https://kds.sereczyn.pl/api/rachunki?ekran=kelner",{method:"POST"})
         const data = await res.json()
         return data
     }
     
   
   
     
     const iloscZamowien = (x) => {
        var dsrPrzyciski = x.reduce(function(dsrPrzyciski, org) {
            (dsrPrzyciski[org.DSR_ID] = dsrPrzyciski[org.DSR_ID] || []).push(org);
            return dsrPrzyciski;
          }, {})

          dsrPrzyciski = Object.entries(dsrPrzyciski)
          return dsrPrzyciski.length
     }

     let arrZDSR = []

    return(
<>
<link rel="stylesheet" href="./sass/pages/_ekran-wszystkie-zamowienia.css"/>

<div className="container1024 row">
{/* Trick aby wydobyć margin top na telefonie */}
<div className="sidebar--telefon mt-5">
          <div className="mt-5">
 </div>
          {/*  */}
          </div>
        <div className="col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 container__srodek">
            <div className="na-Teraz row">
                 {
                 
                 naTerazZamowieniaSort.map(c => {
                 
                 return naTerazZamowienia.map(x=>{
                 if(`${c.DSR_Opis}`.replace(/\s/g, "") == `${x[0].DSR_Opis}`.replace(/\s/g, "")){

              
                    //CZy choć jeden reachunek n  zie ma Nulla, jeśli tak no to pokaż kafelek
                    let czyDataWydawki = true;
                     x.map(rachunek =>{
                       if(rachunek.KDL_DataKelnerska === null){
                         czyDataWydawki = false
                       }
                      })
                      let czas = x.sort((a,b) => (a.czas_w_minutach < b.czas_w_minutach) ? 1 : ((b.czas_w_minutach < a.czas_w_minutach) ? -1 : 0))
                      if(!czyDataWydawki){
                        let timer = x.find(v => v.Timer !== null)
                        if(timer == undefined){
                          timer = x[0]
                        }


                        return  <KafelekZamBg 
                       
                       wynos={x[0].TOR_CzyNaWynos}
                       dostawa={x[0].TOR_CzyZDostawa}
                        wydawka={0}
                        stolik={x[0].DSR_STOID} 
                       nazwaLokalizacji={''}
                       iloscZamowien = {iloscZamowien(x)}
                       czasZamowienia = {c.czas}
                       timerKucharza = {timer.Timer}
                       opis={x[0].DSR_Opis}
                       sztucce={x}
                       kolor={'box--yellow'}
                       typZamowienia={x.typZamowienia}
                       zamowienia={x}
                       obszar={1}
                       DSR_ID = {x[0].DSR_ID}
                       sektor = {c.sektor}
                       numerek= {c.numerek}
                        />
                      }
                      
                    }   })
                 })
                }


                 </div>  
                 <hr/>
                 <div className="do-Wydania row">
                 {doWydaniaZamowieniaSort.map(x=>{
              
              return doWydaniaZamowienia.map(c => {
                if(c[0].DSR_Opis.replace(/\s/g, "") == x.DSR_Opis.replace(/\s/g, "")){
        
                  let czyDataWydawki = true;
          c.map(rachunek =>{
            if(rachunek.KDL_DataKelnerska === null){
              czyDataWydawki = false
            }
          })
          
          if(!czyDataWydawki){
          
            let timer = c.find(v => v.Timer !== null)
    
            if(timer == undefined){
              timer = c[0]
            }
            let czas = c.sort((a,b) => (a.czas_w_minutach < b.czas_w_minutach) ? 1 : ((b.czas_w_minutach < a.czas_w_minutach) ? -1 : 0))
            let imie = c.find(v => v.OPR_Imie != null)
            if(imie == undefined){
              imie = c[0]
            }
                return <KafelekZamBg 
            dostawa={c[0].TOR_CzyZDostawa}
            wynos={c[0].TOR_CzyNaWynos}
            stolik={c[0].DSR_STOID}
            wydawka={0}
            nazwaLokalizacji={''}
            iloscZamowien = {iloscZamowien(c)}
            czasZamowienia = {x.czas}
            timerKucharza = {timer.Timer}
            opis={c[0].DSR_Opis}
            sztucce={c}
            kolor={'box--blue'}
            typZamowienia={c.typZamowienia}
            zamowienia = {c}
            obszar={2}
            DSR_ID = {c[0].DSR_ID}
            wydawkaNazwa={imie.OPR_Imie}
            sektor = {x.sektor}
            numerek= {x.numerek}
            />}

                }
              })
          })}   
                </div>
                <hr/>
                <div className="wszystkie-Zamowienia row">
                {resztaZamowienSort.map(x=>{
              
                  return resztaZamowien.map(c => {
                    if(c[0].DSR_Opis.replace(/\s/g, "") == x.DSR_Opis.replace(/\s/g, "")){
            
                      let czyDataWydawki = true;
              c.map(rachunek =>{
                if(rachunek.KDL_DataKelnerska === null){
                  czyDataWydawki = false
                }
              })
              let czas = c.sort((a,b) => (a.czas_w_minutach < b.czas_w_minutach) ? 1 : ((b.czas_w_minutach < a.czas_w_minutach) ? -1 : 0))
              if(!czyDataWydawki){
                let timer = c.find(v => v.Timer !== null)
                   if(timer == undefined){
                     timer = c[0]
                   }
                
                return <KafelekZamBg 
                dostawa={c[0].TOR_CzyZDostawa}
                wynos={c[0].TOR_CzyNaWynos}
                stolik={c[0].DSR_STOID}
                wydawka={0}
                nazwaLokalizacji={''}
                iloscZamowien = {iloscZamowien(c)}
                czasZamowienia = {x.czas}
                timerKucharza = {timer.Timer}
                opis={c[0].DSR_Opis}
                sztucce={c}
                kolor={'box--blue'}
                typZamowienia={c.typZamowienia}
                zamowienia = {c}
                obszar={3}
                DSR_ID = {c[0].DSR_ID}
                sektor = {x.sektor}
                numerek= {x.numerek}
                />}

                    }
                  })
              })}  
                </div>
            </div>

            <div className="fixed-bottom" style={{marginBottom:'5.5rem'}}>
            <div id="rachunki" style={{maxWidth:"80vh"}}>
         {odklikane.map(x => {
             
             return   <div class="rachunki-box box--green"  onClick={() => {history.push(`/zam/${x.DSR_Opis}?czas=${x.czas}`)}}><span style={{fontSize:"2.5rem"}}>{x.DSR_Opis}</span> </div>
         })}
      
        </div>
</div>
            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 sidebar-container" style={{textAlign:'center'}}>
            <div className="sidebar--komputer">
                <div className="sidebar ">
                <button type="button" class="btn btn-outline-light" style={{width:'95%', pointerEvents:'none', fontSize:'2rem', height:'4rem', marginLeft:'.5rem'}}>Kelner</button>
           

                </div>

              
                <div className="przelaczZamowienie">
               
               <div className="row">
                    
                   {ostatnioOdklikane.map(x => {
                     return  <a className="btn btn--przelaczZamowienie btn--yellow" style={{fontSize:"10px"}} onClick={
                       () => history.push(`/zam/${x.DSR_Opis}?czas=${x.czas}`)
                     }>{x.DSR_Opis}</a>
                   })}
               </div>
              
              
               <div className="row">
                   <div className="col-xl-12 col-lg-12 col-md-12 com-sm-12 przelaczZamowienie__wszystkie">
                       <a href="#" className="btn btn--grey btn--wszystkie">Wszystkie</a>
                   </div>
               </div>

           </div>

            </div>
             
              
            
            
        </div>     
        </div>
        <div className="sidebar--telefon">
          <div className="mt-5"></div>
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top ">
    

                <button className="navbar-toggler my-3 btn-lg mx-auto btn--red" type="button" data-toggle="collapse"
                    data-target="#sidebarTel--reklamacja" aria-controls="navbarNav" aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span className="sidebar-telefon-button-ilosc" >111</span>
                    {/* <span className="ikona-belka filter-white"><img src="img/warning-sign.svg" alt=""/></span> */}
                </button>

               
                <button className="navbar-toggler my-3 btn-lg mx-auto btn--yellow" type="button" data-toggle="collapse"
                    data-target="#sidebarTel--sprzatnij" aria-controls="navbarNav" aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span className="sidebar-telefon-button-ilosc" >1</span>
                    {/* <span className=" ikona-belka filter-white"><img src="img/spray.svg" alt=""/></span> */}
                </button>

                <button className="navbar-toggler my-3 btn-lg mx-auto btn--green" type="button" data-toggle="collapse"
                    data-target="#sidebarTel--naWynos" aria-controls="navbarNav" aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span className="sidebar-telefon-button-ilosc" >1</span>
                    {/* <span className=" ikona-belka filter-white blok"><img src="img/delivery.svg" alt=""/></span> */}
                </button>

                
                <button className="navbar-toggler my-3 btn-lg mx-auto btn--blue" type="button" data-toggle="collapse"
                    data-target="#sidebarTel--konwersacja" aria-controls="navbarNav" aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span className="sidebar-telefon-button-ilosc" >1</span>
                    {/* <span className=" ikona-belka filter-white"><img src="img/messenger.svg" alt=""/></span> */}
                </button>

                <div className="collapse navbar-collapse " id="sidebarTel--reklamacja">
                  <div className="row mt-3">

                    <KafelekReklamacjiP/>
                    <KafelekReklamacjiP/>
                    <KafelekReklamacjiP/>
                  </div>
                </div>

                <div className="collapse navbar-collapse " id="sidebarTel--sprzatnij">
                    <div className="row mt-3">
                      <KafelekSprzatnacP/>
                      <KafelekSprzatnacP/>
                      <KafelekSprzatnacP/>
                      <KafelekSprzatnacP/>
                      </div>
                      </div>

                      <div className="collapse navbar-collapse " id="sidebarTel--naWynos">
                    <div className="row mt-3">
               <KafelekNaWynosP/>
               <KafelekNaWynosP/>
               <KafelekNaWynosP/>
                    </div>
                      </div>

                      <div className="collapse navbar-collapse " id="sidebarTel--konwersacja">
                      <div className="row mt-3">
               <KafelekKonwersacjaP/>
               <KafelekKonwersacjaP/>
               <KafelekKonwersacjaP/>
                    </div>
                      </div>

        </nav>
        </div>
        <div className="belka--komputer ">
            {/* BELKA */}
  <div className="fixed-bottom">
          <link rel="stylesheet" href="./sass/main.css"></link>
        <div className="belka">
  
         <BelkaLewa/>
                   <button id="scrollButtonDown" class="btn btn-lg"> ⏬ </button>
                   <button id="scrollButtonUp" class="btn btn-lg"> ⏫ </button>
          
  
          <div className="belka__prawa">
            
          <Link to="/szukaj"><span>🔎</span></Link>
          {/* <Link to="/raport"><span>Raport</span></Link>
          <Link to="/imprezy"><span>Imprezy</span></Link>
      */}
     
          </div>
        </div>
      </div>
      {/* BELKA */}
        </div>
        <div className="belka--telefon ">
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-bottom ">

            <a className="navbar-brand " href="#"></a>
            <button className="navbar-toggler btn-lg mx-left" type="button" data-toggle="collapse" data-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse " id="navbarNav">
                <ul className="navbar-nav">
                    <li className="nav-item active">
                        
                        <a className="nav-link" href="#">Napoje</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#">Raport</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#">Imprezy</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#"><br/></a>
                    </li>
                </ul>
            </div>


        </nav>

    </div>

    <div className="belka__ikony--telefon">
        <ul>
            {/* <li> <span className="ikona-belka filter-white"><img src="img/chef.svg" alt=""/></span></li>
            <li><span className="ikona-belka filter-white"><img src="img/fish.svg" alt=""/></span></li>
            <li><span className="ikona-belka filter-white"><img src="img/waiter.svg" alt=""/></span></li>
            <li><span className="ikona-belka filter-white"><img src="img/waiter-z-taca.svg" alt=""/></span></li> */}
        </ul>
    </div>
{/* MODAL */}
    <div class="modal " id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content" >

      <div class="modal-body box--black" style={{textAlign:'center'}}>
        <h3>Skonfiguruj Stanowisko</h3>
      
        <label for="cars">Wybierz swoje stanowisko</label>
           
        
        <select name="cars" id="cars"  style={{width:'100%', color:'black'}} onChange={e => stanowiska.map(c => {
           if(c.STA_ID == e.target.value.substr(0,e.target.value.indexOf(' '))) {localStorage.setItem('HOST', JSON.stringify(c))} 
        })}>
                <option style={{color:'black'}}></option>
        {stanowiska.map(x => {
              return <option  style={{color:'black'}}>{x.STA_ID} { x.STA_Nazwa}</option>
           })}
       
        </select>

      </div>
      <div class="modal-footer box--black" style={{textAlign:'center'}}>
      <div type="button" style={{display:'inline-block'}} class="btn btn-primary" data-dismiss="modal" >OK</div>
      </div>
    </div>
  </div>
</div>

      <button data-backdrop="static" data-keyboard="false" type="button" class="btn btn-primary display-hidden" id="wlaczModalUstawieniaStanowiska" style={{position:'absolute', top:'30%'}} data-toggle="modal" data-target="#exampleModal">
</button>

                </>
    )

}

export default AllZamMid

