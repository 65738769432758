import { useState, useEffect } from "react"
import fetchApi from "../api/fetchApi"

export default function TimerComponent({timerValue,dsl,oldtimer}) {

    const [timer, settimer] = useState(Math.abs(Number(timerValue)))
   
    return <div class="mt-5 timer" >
                        <button class="btn btn-primary btn-lg col-2 col-md-2 col-lg-2 " onClick={() => settimer(Math.abs(timer + 1))} >+</button>
                        <button class="btn btn-primary btn-lg col-4 col-md-4 col-lg-4">{timer}</button>
                        <button class="btn btn-primary btn-lg col-4 col-md-4 col-lg-4" onClick={async () => {
                           await fetchApi(`/updatetimera?dsl=${dsl}&timer=${timer}&oldtimer=${oldtimer}`)
                            // alert(`/updatetimera?dsl=${dsl}&timer=${timer}&oldTimer=${oldtimer}`);
                        }}>OK</button>
                        <button class="btn btn-primary btn-lg col-2 col-md-2 col-lg-2" onClick={() => settimer(Math.abs(timer - 1))}>-</button>
            </div>
}