import {BrowserRouter as Router, Route, Switch, Link} from 'react-router-dom'
import Footer from './components/wszystkie-zamowienia/Footer'
import Navbar from './components/wszystkie-zamowienia/Navbar'
import Frytki from './pages/Frytki'
import Kuchnia from './pages/Kuchnia'
import Wydawka from './pages/Wydawka'
import Login from './pages/Login'
import Ryby from './pages/Ryby'
import AllZamMid from './pages/Wszystkiezamowienia'
import Zamowienie from './pages/Zamowienie'
import ZamowienieWydawka from './pages/ZamowienieWydawka'
import ZamowienieWydawkaDSR from './pages/ZamowienieWydawkaDSR'
import { ProduktyContext} from './ProduktyContext' //Zmień nazwę tego
import {useState} from 'react'
import ZamowienieDSR from './pages/ZamowienieDSR'
import KucharzZamowienia from './pages/KucharzZamowienia'
import Piece from './pages/Piece'
import PieceDodaj from './pages/PieceDodaj'
import KuchniaDSR from './pages/KuchniaDSR'
import {DsrPrzyciskiKuchniaContext} from  './DsrPrzyciskiKuchniaContext'
import {ModalContext} from  './ModalContext'
import {KoszykKucharzContext} from  './KoszykKucharzContext'
import {QueryKoszykContext} from  './QueryKoszykContext'
import {ZieloneRachunkiContext} from  './ZieloneRachunkiContext'
import Taca from './pages/Taca'
import WiataMain from './pages/WiataMain'
import SzczegolyWiata from './pages/SzczegolyWiata'
import SzczegolyWiataDSR from './pages/SzczegolyWiataDSR'
import SzczegolyWiataAll from './pages/SzczegolyWiataAll'
import SzczegolyWiataAllDSR from './pages/SzczegolyWiataAllDSR'
import KelnerSzczegolyAll from './pages/KelnerSzczegolyAll'
import Qr from './libki/QrScanner'
import EkranLogowania from './components/logowanie/EkranLogowania'
import Szukaj from './pages/Szukaj'
import DodajStolikDoBazy from './libki/DodajStolikDoBazy'
import ListaRachunkowQr from './pages/ListaRachunkowQr'
import ListaArtykulow from './pages/ListaArtykulow'
import Sprzatanie from './pages/Sprzatanie'
import PieceNowe from './pages/PieceNowe'
import akcjeZamowieniaPiece from './pages/AkcjeZamowieniaPiece'
import AkcjeZamowieniaPiece from './pages/AkcjeZamowieniaPiece'
import WTrakcieBudowy from './pages/WTrakcieBudowy'
import SzukajDSR from './pages/SzukajDSR'
import QrScanner from './libki/QrScanner'

function App() {
 const [stolik, setStolik] = useState("Hello From Context")
  const [dsrBtn, setdsrBtn] = useState('dsr context')
  const [modalContent, setModalContent] = useState([])
  const [koszykKucharz, setKoszykKucharz] = useState([])
  const [queryKoszyk, setQueryKoszyk] = useState([])
  const [zieloneRachunki, setZieloneRachunki] = useState([])

  return (
   
   <Router>
      <>
 
  <ProduktyContext.Provider value={{stolik, setStolik}}>
      <Switch>
      <Route path="/wiata" exact component={WiataMain}/>
      <Route path="/sprzatanie" exact component={Sprzatanie}/>

      <Route path="/scannerqr" exact component={QrScanner}/>

      <Route path="/listarachunkowqr" exact component={ListaRachunkowQr}/>
      
      <Route path="/artykuly" exact component={ListaArtykulow}/>
      <Route path="/dodajstolik" exact component={DodajStolikDoBazy}/>
      <Route path="/ekranlogowania" exact component={EkranLogowania}/>

      <Route path="/zamwiata/:stolik" exact component={SzczegolyWiata}/>
      <Route path="/zamwiata/:stolik/:dsr" exact component={SzczegolyWiataDSR}/>
      <Route path="/zamwiataall/:stolik" exact component={SzczegolyWiataAll}/>
      <Route path="/zamwiataall/:stolik/:dsr" exact component={SzczegolyWiataAllDSR}/>

      <Route path="/" exact render={()=>(
           <>
           <div style={{height:'100vh', position:'relative'}}>
          <Link to="/sprzatanie" className="btn fs-1 d-flex justify-content-center align-items-center btn-primary w-100 h-50" style={{height:'50% !important'}}><span class="fs-1">Sprzątanie</span></Link>
          <Link to="/listarachunkowqr" className="btn  d-flex justify-content-center  align-items-center fs-1 btn-danger w-100 h-50" style={{height:'50% !important'}}><span class="fs-1">Scanner Stolików</span></Link>
          </div>
           </>
      ) }/>


      <Route path="/kelner" exact component={AllZamMid}/>
      <Route path="/wydawka" exact component={Wydawka}/>
    
      <Route path="/zam/:stolik"  exact component={Zamowienie}/>
      
      <Route path="/zamwydawka/:stolik"  exact component={ZamowienieWydawka}/>
      <Route path="/zam/:stolik/:dsr" exact  component={ZamowienieDSR}/>
      <Route path="/zamwydawka/:stolik/:dsr" exact  component={ZamowienieWydawkaDSR}/>

      <Route path="/test/:stolik" exact component={KelnerSzczegolyAll}/>
      <Route path="/test/:stolik/:dsr" exact component={KelnerSzczegolyAll}/>
      
      </Switch>
</ProduktyContext.Provider>

<ZieloneRachunkiContext.Provider value={{zieloneRachunki, setZieloneRachunki}}>
  <KoszykKucharzContext.Provider value={{koszykKucharz, setKoszykKucharz}}>
  <QueryKoszykContext.Provider value={{queryKoszyk, setQueryKoszyk}}>
  <ModalContext.Provider value={{modalContent, setModalContent}}>
  <DsrPrzyciskiKuchniaContext.Provider value={{dsrBtn, setdsrBtn}}>
  <Route path="/kuchzam" exact component={KucharzZamowienia}/>
  <Route path="/kuchzam/:id" exact component={Kuchnia}/>
  <Route path="/kuchzam/:id/:dsr" component={KuchniaDSR}/>
  </DsrPrzyciskiKuchniaContext.Provider>

  <Route path="/piece" exact  component={PieceNowe}/>
  <Route path="/piece/:id" exact  component={PieceNowe}/>
  <Route path="/piece/:id/:polka" exact  component={AkcjeZamowieniaPiece}/>

  </ModalContext.Provider>
  </QueryKoszykContext.Provider>
  </KoszykKucharzContext.Provider >
  </ZieloneRachunkiContext.Provider>

  <Route path="/frytki"  component={WTrakcieBudowy}/>
  <Route path="/ryby"  component={WTrakcieBudowy}/>
  <Route path="/raport"  component={WTrakcieBudowy}/>
  <Route path="/imprezy"  component={WTrakcieBudowy}/>

  <Route path="/logowanie" exact component={Login}/>
  <Route path="/szukaj" exact component={Szukaj}/>
  <Route path="/szukaj/:dsr" exact component={SzukajDSR}/>
          
      <Route path="/patelnie"  render={()=>(
        <WTrakcieBudowy/>
      ) }/>
     
      <Route path="/piece-patelnie"  render={()=>(
         <WTrakcieBudowy/>
      ) }/>
      </>

      <Route path="/taca" exact component={Taca}/>    
     
      {/* <center class="position-absolute top-3 start-50 translate-middle-x" style={{top:'0', opacity:'.7'}}><h5>Ten program jest w fazie testów. Sprawdzamy działanie programu pod kątem funkcjonalności, <br/> nie zwracaj uwagi na wygląd - zostanie on zmieniony gdy
      wszystko będzie działało. Przepraszamy za wszelkie błędy i prosimy o cierpliwość. <br/> Prosimy o informacje o wszelkich błędach, niedociągnięciach, spostrzeżeniach. <br/>
      Dziękujemy.</h5></center> */}
    </Router>
    
  );
}

export default App;
