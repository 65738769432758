function OpisBox({phone,opis}){
    
  if(!phone){
    return(
        
      <div className="middle-content__left__opis-box p-3 mt-3 row ">
      <div className="middle-content__left__opis-box__box">
        <i className="icon-sm">➕</i>
        <i className="icon-sm">🗒</i>

        <span className="middle-content__left__opis-box__box--kontent">{opis}</span>
        <span className="middle-content__left__opis-box__box--status">Klient VIP</span>
      </div>
    </div>
  )
}{
  return(
        
    <div className="middle-content__left__opis-box p-3 mt-3 mb-5 row ">
    <div className="middle-content__left__opis-box__box">
      <i className="icon-sm">➕</i>
      <i className="icon-sm">🗒</i>

      <span className="middle-content__left__opis-box__box--kontent">{opis}</span>
      <span className="middle-content__left__opis-box__box--status">Klient VIP</span>
    </div>
  </div>
)
}

  }

export default OpisBox