import React from 'react'

export default function KafelekKonwersacjaP() {
    return (
        <div className="col-6 mb-3">
                                
                                <div className="konwersacja-z-klientem ">
                                    <div className="blok-maly-zamowienie-konwersacja">
                                        <div className="blok-head">
                                            <div className="stolik"><span className="ikona-zadania">🏳 </span>32</div>
                                            <div className="opis-zadania">Konwersacja z klientem</div>
                                            <div className="ilosc-zadan">(4)</div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>

    )
}
