import React from 'react'

export default function FooterP() {
    return (
       < div className="fixed-bottom">
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark box--black px-4">
        <a className="navbar-brand" href="#"> <i className="icon-sm filter-white"><img src="/icons/chef.svg" alt=""/></i></a>
        <a className="navbar-brand" href="#"> <i className="icon-sm filter-white"><img src="/icons/fish.svg" alt=""/></i></a>
        <a className="navbar-brand" href="#"> <i className="icon-sm filter-white"><img src="/icons/waiter.svg" alt=""/></i></a>
        <a className="navbar-brand" href="#"> <i className="icon-sm filter-white"><img src="/icons/waiter-z-taca.svg" alt=""/></i></a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse font-size-md" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item mt-2">
              <a className="nav-link" href="#">Napoje</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">Raport</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">Imprezy</a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
    )
}
