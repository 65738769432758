import React from 'react'
import {useContext, useMemo} from 'react'
import { Obszar3Context } from '../../Obszar3Context'

function PotrawyKafelekWydawka({kolorKafelek,kolorWSrodku, potrawy,onClick, phone,DSL_ID, nazwa,zamowienie,obszar,polka, wydawka, ilosc, operator, czasPoz}) {

    // const {obszar3} = useContext(Obszar3Context)
    let obszar3 = []

  


    if(obszar == undefined || null){
    obszar3 = []
    }else{
      obszar3 = obszar
    }


    if(!phone){
        return (

            <div className="middle-content-middle__srodek__potrawy__box"  >
              <span  
              onClick={(e) => {
              
                if(e.target.classList.contains('box--gray')){
                  let arr =[]
                  obszar3.map(x => {
                    if(DSL_ID === x.DSL_DSLIDPowiazana){
                      arr.push(x.DSL_ID, ',')
                    }
                  })
      
                  // console.log([...arr,DSL_ID].join(''));
                  onClick([...arr,DSL_ID].join(''),'checked')
                  e.target.classList.remove('box--gray')
                  if(!e){}
                  if(e.target.nextSibling.children !== null){
                    let arrayOfPowiazane =[...e.target.nextSibling.children]
                    // console.log(arrayOfPowiazane);
                    arrayOfPowiazane.forEach(child => {
                      child.classList.remove('box--gray')
                      child.classList.add('box--red')
                      // console.log('cos');
                    })
                    e.target.classList.add('box--red')
                  }
                 
              }else{
                let arr =[]
                obszar3.map(x => {
                  if(DSL_ID === x.DSL_DSLIDPowiazana){
                    arr.push(x.DSL_ID, ',')
                  }
                })
    
                if(e.target.nextSibling.children !== null){
                let arrayOfPowiazane =[...e.target.nextSibling.children]
                  // console.log(arrayOfPowiazane);
                  arrayOfPowiazane.forEach(child => {
                    child.classList.add('box--gray')
                    child.classList.remove('box--red')
                    // console.log('cos');
                  })
    
                // console.log([...arr,DSL_ID].join(''));
                onClick([...arr,DSL_ID].join(''),'unchecked')
                e.target.classList.add('box--gray')
                }
              }
                  
              }}
              className={`middle-content-middle__srodek__potrawy__box--head ${zamowienie.KDL_DataWydawka == "null" || zamowienie.KDL_DataWydawka == null ? 'box--red' : 'box--gray'}`}>{ilosc}x {nazwa} {polka} {operator} {czasPoz}</span>
              <span className="middle-content-middle__srodek__potrawy__box--body">
              {obszar3.map(x => {
                  if(DSL_ID === x.DSL_DSLIDPowiazana){
                    return (
                      <span className={`middle-content-middle__srodek__potrawy__box--body__kafelek ${x.KDL_DataWydawka == "null" || x.KDL_DataWydawka == null ? 'box--red' : 'box--gray'}`} onClick={(e) => {
                        if(e.target.classList.contains('box--gray')){
                          onClick(x.DSL_ID, 'checked' )
                          e.target.classList.remove('box--gray')
                          e.target.classList.add('box--red')
                        }else{
                          onClick(x.DSL_ID, 'unchecked' )
                          e.target.classList.add('box--gray')
                          e.target.classList.remove('box--red')
                        }
                       
                    }} >
                 {x.DSL_Ilosc}x {x.ART_Nazwa} {x.KDL_Polka} {x.wydawkaOpr} {x.czas_pozycja} </span>  )
                  }
          
               })}
                
                </span>
            </div>
      )
    }{
        return (
            <div className="col-sm-6 col-6">
        <div className="potrawy__box" style={{fontSize: "1.8rem"}}>
            <div className="middle-content-middle__srodek__potrawy__box" style={{width:"100%"}}>
              <span className={`middle-content-middle__srodek__potrawy__box--head ${kolorKafelek}`}>Potrawa</span>
              <span className="middle-content-middle__srodek__potrawy__box--body">
              {obszar3.map(x => {
                  
                  if(DSL_ID === x.DSL_DSLIDPowiazana){
                    return (
                      <span className={`middle-content-middle__srodek__potrawy__box--body__kafelek ${kolorWSrodku}`} onClick={onClick} >
                  {x.ART_Nazwa} {x.KDL_Polka}    </span>  )
                  }
          
               })}
                
                
                </span>
            </div>
            </div>
            </div>
      )
    }
  
}

PotrawyKafelekWydawka.defaultProps = {
    kolorKafelek:"box--red",
    kolorWSrodku: "box--red",
    potrawy: ['Kopytka','Pstrąg','Frytki','Jesiotr','Schab'],
    phone:false
}

export default PotrawyKafelekWydawka
