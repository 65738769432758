import React, { useState } from 'react'
import QrReader from 'react-qr-reader'
import fetchApi from '../api/fetchApi'

export default function DodajStolikDoBazy() {
  const [Qr, setQr] = useState(null) 
  const [input, setInput] = useState(null) 
  const [status, setstatus] = useState(null) 
  const [calyhtml, setcalyhtml] = useState(null)
  const [jakiNumerek, setjakiNumerek] = useState([])

  const handleScan = async(data) => {   
    if (data) {
      setQr(data.slice(-6))
      setcalyhtml(data)
      setstatus('')
      document.getElementById('zatwierdzbtn').click()
      setjakiNumerek(await fetchApi(`/kodqr?kodqr=${data.slice(-6)}`))
    }
   
  }

  const handleError = err => {
    console.error(err)
  }
 
 
async function zatwierdz() {

  if(Qr == null || Qr == '' || input == -1 || input == null || input == ''){
    alert('cos poszlo nie tak')
    setstatus('')
    setInput(null)
    setQr(null)
    setjakiNumerek(await fetchApi(`/kodqr?kodqr=${calyhtml.slice(-6)}`))
  }else{
    let numerek =  await fetchApi(`/dodajStolik?kodqr=${Qr}&numer=${input}`)
    numerek.status == true ? setstatus('dodano') : setstatus('istnieje już taki stolik lub kod qr') 
    setInput(null)
    setQr(null)
    setjakiNumerek( await fetchApi(`/kodqr?kodqr=${calyhtml.slice(-6)}`))
  }

  document.getElementById('modalbuttonstoliki').click()

}
   
function skopiuj() {
  /* Get the text field */
  var copyText = document.getElementById("myInput");

  /* Select the text field */
  copyText.select();
  copyText.setSelectionRange(0, 99999); /* For mobile devices */

  /* Copy the text inside the text field */
  document.execCommand("copy");


  /* Alert the copied text */
 setstatus(status + "  ||  skopiowano: " + copyText.value);
 document.getElementById('closebutton').click()
 document.getElementById('inputmoj').value = ''
  
}

let btnstyle= {width:'5rem', height:'4rem'}
  return (

   <div style={{postion:'relative'}}>
     <h3>status: {status}</h3>
        <input type="number" style={{width:'100%', height:'6rem', color:'black', fontSize:'5rem'}} value={input} id="inputmoj" onChange={(e) => setInput(e.target.value)} ></input>
        <QrReader
          delay={1000}
          onError={handleError}
          onScan={handleScan}
          style={{ width: '100%' }}
        />

  
    
 
  <button className="btn btn-primary " id="zatwierdzbtn" style={{position:'absolute', left:'-70rem'}}  onClick={() => zatwierdz()}>Zatwierdz</button>


  
<button type="button" style={{position:'absolute', left:'-70rem'}}  id="modalbuttonstoliki" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
  
  </button>
  
  
  <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body">
        <input type="text" style={{color:'black', width:'70%', height:'4rem'}} value={calyhtml} id="myInput"/>
        <button class="btn btn-warning" style={{width:'5rem', height:'4rem'}} onClick={() => skopiuj()}>Copy text</button>
        </div>
        <button type="button" id="closebutton" class="btn btn-secondary display-hidden position-fixed" data-dismiss="modal">Close</button>

        <h3 class="text-dark fw-bolder">Numerek: {jakiNumerek}</h3>
      </div>

    </div>
  </div>
      </div>

  )
}
 

