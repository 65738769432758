import React from 'react'
import {Link } from 'react-router-dom'

import { ReactComponent as KelnerSvg } from '../../img/waiter.svg';
import { ReactComponent as KucharzSvg } from '../../img/chef.svg';
import { ReactComponent as TacaSvg } from '../../img/waiter-z-taca.svg';
import { ReactComponent as WydSvg } from '../../img/cutlery.svg';

export default function BelkaLewa() {
    return (
       < div className="belka__lewa">
        <Link to="/kuchzam">
        <i className="icon-md filter-white" ><KucharzSvg style={{width:'3rem'}}/></i>
          <span style={{marginRight:'2rem !important'}}>Kucharz</span>    </Link>
    
        <Link to="/wydawka">
        <i className="icon-md filter-white"> <WydSvg style={{width:'3rem', position:'absolute', top:'.6rem'}}/> </i>
          <span style={{marginLeft:'3.5rem'}}>Wydawka</span></Link>
     <Link to="/kelner">
     <i className="icon-md filter-white" ><KelnerSvg style={{width:'3rem'}}/></i>
          <span>Kelner</span> </Link>

          {/* <Link to="/taca">
         <i className="icon-md filter-white" ><TacaSvg style={{width:'3rem'}}/></i>
          <span>Taca</span>
          </Link>  */}

          <Link to="/wiata">
          <i className="icon-md filter-white" ><TacaSvg style={{width:'3rem'}}/></i>
          <span>Wiata</span>
          </Link>

          <a href="http://192.168.1.15/s4h/index21.php">Stary KDS</a>
    
      </div>
    )
}
