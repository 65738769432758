import React from 'react'

export default function PrzyciskWszystkieRachunki({kolor}) {
    return (
        <>
        <div className={`sidebar-right__rachunki__wszystkie-rachunki box--gray ${kolor}`}>  <span>Wszystkie rachunki</span></div>
      
        </>
    )
}

PrzyciskWszystkieRachunki.defaultProps = {
    kolor: "box--gray"
}