import akcjeArykuły from "./akcjeArykuły";
import fetchApi from "../api/fetchApi";

export default async function przytrzymajModal(query, id, artId)  {
    //ART_CZyprodukt dotowany - status 
    let status = await fetchApi(`/statussprzedazy?id=${artId}`)

    console.log('odpalone')
    var mouseTimer;
  function mouseDown() { 
      mouseUp();
      mouseTimer = window.setTimeout(execMouseDown,1000); //set timeout to fire in 2 seconds when the user presses mouse button down
  }

  function mouseUp() { 
      if (mouseTimer) window.clearTimeout(mouseTimer);  //cancel timer when mouse button is released
  }

 const removeElements = (elms) => elms.forEach(el => el.remove());

 let dsl = query.replace(/,/g, '')

  function execMouseDown() { 
    let modalContextMenu = document.createElement(`modalContextMenu${dsl}`);
    modalContextMenu.async = true;
    modalContextMenu.innerHTML = `
    <button type="button" id='modalContextButtonNew${dsl}' class="btn btn-primary display-hidden" data-toggle="modal" data-target="#contextMenuNew${dsl}">
  Launch demo modal
</button>
    <div class="modal " id="contextMenuNew${dsl}" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">add
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel" style="color:black">Status: ${status[0].ART_CzyZastrzezonyWSprzedazy == true ? 'zablokowany' : 'odblokowany'}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" style="color:black">
        ${query} ${artId}
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button id="zablokuj${id}"  type="button" class="btn btn-primary zablokuj">Zablokuj sprzedaż</button>
          <button id="odblokuj${id}" type="button" class="btn btn-primary ">Odblokuj sprzedaż</button>
        </div>
      </div>
    </div>
  </div>
  
  `
 
    document.body.appendChild(modalContextMenu);
    setTimeout(() =>  removeElements( document.querySelectorAll(`modalcontextmenu${dsl}`) ) ,200000)

  ;
    document.getElementById(`modalContextButtonNew${dsl}`).click()
    removeElements( document.querySelectorAll('.modal-backdrop') );

    let odblokuj = document.getElementById(`odblokuj${id}`)
    setTimeout(() => odblokuj.addEventListener("click", () => {
        akcjeArykuły.odblokujzablokujProdukt(artId, 'odblokuj')
    }) ,1000) 

    let zablokuj = document.getElementById(`zablokuj${id}`)
    setTimeout(() => zablokuj.addEventListener("click", () => {
        akcjeArykuły.odblokujzablokujProdukt(artId, 'zablokuj')
    }) ,1000) 
  }

//   console.log(`.${id}`)

   let element = document.getElementById(id)

   if(element != null){
    element.addEventListener("mousedown", mouseDown)
   


   }

   document.body.addEventListener("mouseup", mouseUp);  
    
  };