function Navbar(){
    return(

    <div className="fixed-top">
      <div id="computer-nav" className="navbar px-4 box--green">
        <div className="navbar__lokalizacja-i-status ">
          <span className="navbar__lokalizacja-i-status__ikona icon-sm">🍕</span>
          <span className="navbar__lokalizacja-i-status__stolik">222</span>
          <span className="navbar__lokalizacja-i-status__ilosc-zamowien">(1)</span>
          <span className="navbar__lokalizacja-i-status__status">VIP</span>
          <span className="navbar__lokalizacja-i-status__lokalizacja">Rybakówka</span>
        </div>
        <div className="navbar__sztucce">
          <button className="navbar__sztucce--all box--gray">All</button>
          <button className="navbar__sztucce--ryba box--gray"><i className="icon-sm ">🥩</i> 1</button>
          <button className="navbar__sztucce--mieso box--red"><i className="icon-sm ">🥩</i> 2</button>
          <button className="navbar__sztucce--lyzka box--gray"><i className="icon-sm ">🥩</i> 3</button>
          <button className="navbar__sztucce--widelec box--red"><i className="icon-sm ">🥩</i> 4</button>
        </div>
        <div className="navbar__czas-zamowienia">
          <span className="navbar__czas-zamowienia__timer">0:20</span>
        </div>
      </div>
    </div>
    )
}

export default Navbar