import zamowienia from "./listyZamowien"

function KafelekSidebar({typ, stolik, czasZamowienia, timerKucharza, kolor}){
    return(
        <div className="blok-side-bar">
        <div className={typ}>
            <div className={`blok-maly-${typ}`}>
                <div className={`blok-head ${kolor}`}>
                    <div className="lewa">

                        {typ==='reklamacja' ? <div className="informacja-reklamacja">Jaki typ?</div> :<div className="informacja-reklamacja">Reklamacja</div>}
                        
                        <div className="stolik">{stolik}</div>
                    </div>
                    <div className="prawa">
                        <div className="czas-zamowienia">{czasZamowienia}</div>
                        <div className="timer-kucharza">{timerKucharza}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

KafelekSidebar.defaultProps = {
    typ: "reklamacja",
    kolor: "box--red",
    stolik: 233,
    czasZamowienia: '0:15',
    timerKucharza: "0:30"
}

export default KafelekSidebar