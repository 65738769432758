import KafelekSidebar from '../components/wszystkie-zamowienia/KafelekSidebar'
import KafelekZamowienia from '../components/kucharzZamowienia/KafelekZamowienia'
import { useHistory } from 'react-router-dom';
import {useState, useEffect} from 'react'
import Rachunki from '../components/wszystkie-zamowienia/Rachunki'
import KafelekReklamacjiP from '../components/wszystkie-zamowienia/KafelekReklamacjiP';
import KafelekSprzatnacP from '../components/wszystkie-zamowienia/KafelekSprzatnacP';
import KafelekNaWynosP from '../components/wszystkie-zamowienia/KafelekNaWynosP';
import KafelekKonwersacjaP from '../components/wszystkie-zamowienia/KafelekKonwersacjaP';
import Footer from '../components/wszystkie-zamowienia/Footer';
import {useContext} from 'react'
import KafelekRachunki from '../components/kucharzZamowienia/KafelekRachunki'
import { Link } from 'react-router-dom'
import BelkaLewa from '../components/all/BelkaLewa'
import scrollButton from '../funkcje/scrollButton'
import {ZieloneRachunkiContext} from  '../ZieloneRachunkiContext'
import fetchApi from '../api/fetchApi';




function WiataMain(){

    const {zieloneRachunki,setZieloneRachunki} = useContext(ZieloneRachunkiContext)
    
    const history = useHistory()
    const[obszarGora, setObszarGora] = useState([])
    const[obszarDol, setObszarDol] = useState([])
    const [kucharzSort, setkucharzSort] = useState([])
    const [ostatnioOdklikane, setostatnioOdklikane] = useState([])
    const [zamowieniaWKoszyku, setzamowieniaWKoszyku] = useState([])


//Logowanie
const zaloguj = ()=>{
   let daneLogowania = JSON.parse(sessionStorage.getItem('OPR_KUCHARZ'))

   if(daneLogowania != null){
    
   }else{
    history.push('/logowanie?ekran=kucharz')

   }
   
 
}


const [stanowiska, setstanowiska] = useState([])
const [odklikane, setOdklikane] = useState([])
let scroll 
let zapytajBaze
let scrollButtonik

document.addEventListener('keydown', function(event) {
    if(event.keyCode == 115) {
        document.getElementById('wlaczModalUstawieniaStanowiska').click()
    }
});

let rest
    useEffect(async()=>{

        if(localStorage.getItem('Zalogowany') != 'true'){
          history.push('/ekranlogowania')
        }
    

        const ustawHosta = async() => {
            if(localStorage.getItem('HOST') == null){
              document.getElementById('wlaczModalUstawieniaStanowiska').click()
            }
          }
          ustawHosta()

          if(localStorage.getItem('HOST') == null){
            localStorage.setItem('HOST', JSON.stringify({STA_ID:1}))
          }

          async function ostatnioOdklikane  (){
            
            const res = await fetch(`https://kds.sereczyn.pl/api/odklikaneostatniokucharz?host=${JSON.parse(localStorage.getItem('HOST')).STA_ID}`,{method:"POST"})
            const data = await res.json()
            return data
          }

          async function odklikane  (){
            const res = await fetch(`https://kds.sereczyn.pl/api/odklikanekucharz`,{method:"POST"})
            const data = await res.json()
            return data
          }

        async function kucharzSort  (){
            const res = await fetch("https://kds.sereczyn.pl/api/kucharzsort",{method:"POST"})
            const data = await res.json()
            return data
          }

          async function getStanowiska  (){
            const res = await fetch("https://kds.sereczyn.pl/api/getstanowiska",{method:"POST"})
            const data = await res.json()
            return data
          }
    
      
        const getTasks = async () =>{
           
              const stanowiska = await getStanowiska()
              
                        setstanowiska(stanowiska[0])
              
            const zamowieniaFromServer = await fetchZamowienia()
            const kucharzPosortowane = await kucharzSort()
            const ostatnioOdk = await ostatnioOdklikane()
            const odk = await odklikane()
            setzamowieniaWKoszyku(await fetchApi('/zamowieniawkoszyku'))
           
            setOdklikane(odk)

            setostatnioOdklikane(ostatnioOdk)

            // console.log(zamowieniaFromServer);
                setObszarGora(zamowieniaFromServer[0])
                let dol = []

                zamowieniaFromServer[1].map(x => {
                    x.map(c => {
                        if(c.ART_KatWydruku == 1 || c.ART_KatWydruku == 4 || c.ART_KatWydruku == 7)  {
                            dol.push(c)
                        }
                    })
                })

                var results = dol.reduce(function(results, org) {
                    (results[org.DSR_Opis] = results[org.DSR_Opis] || []).push(org);
                    return results;
                }, {})
               
                 rest = Object.entries(results)
                // console.log(rest)

                setZieloneRachunki(rest)
                setObszarDol(rest)
                setkucharzSort(kucharzPosortowane)
                zaloguj()
        }

          setzamowieniaWKoszyku(await fetchApi('/zamowieniawkoszyku'))
         getTasks() 
          zapytajBaze = setInterval(()=>getTasks(),5000)

         
         let scrollPosition = sessionStorage.getItem('scrollKucharz')
         setTimeout(() =>  window.scrollBy(0, Number(scrollPosition) ),1000)
         scroll  = setInterval(() => sessionStorage.setItem('scrollKucharz', window.pageYOffset)   ,400)
       
         
            scrollButton()
       
       
     },[])

     
     useEffect(() => {
       
      return () =>{
           clearInterval(scroll)
           clearInterval(zapytajBaze)
           clearInterval(scrollButtonik)
    }
}, [])
     

 
 
     // Fetch Data (Różne Zamóienia)
     const fetchZamowienia = async()=>{
         await fetch("https://kds.sereczyn.pl/api/rachunki?ekran=kucharz",{method:"POST"})
         const res = await fetch("https://kds.sereczyn.pl/api/rachunkiKucharz",{method:"POST"})
         const data = await res.json()
         return data
     }

     const iloscZamowien = (x) => {
        var dsrPrzyciski = x.reduce(function(dsrPrzyciski, org) {
            (dsrPrzyciski[org.DSR_ID] = dsrPrzyciski[org.DSR_ID] || []).push(org);
            return dsrPrzyciski;
          }, {})

          dsrPrzyciski = Object.entries(dsrPrzyciski)
          return dsrPrzyciski.length
     }


    return(
<>
<link rel="stylesheet" href="./sass/pages/_ekran-wszystkie-zamowienia.css"/>

<div className="container1024 row">
{/* Trick aby wydobyć margin top na telefonie */}
<div className="sidebar--telefon mt-5">
          <div className="mt-5">
 </div>
          </div>
        <div className="col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12 container__srodek">
           {/* Z choć jednym timerem - nie przejmować się klasą na-Teraz */}
            <div className="na-Teraz row">
            
            {
            kucharzSort.map(c => {
                  
               return obszarGora.map(x=>{
                let timer = x.find(v => v.Timer !== null)
                if(timer == undefined){
                  timer = x[0]
                }

                let czas = x.sort((a,b) => (a.czas_w_minutach < b.czas_w_minutach) ? 1 : ((b.czas_w_minutach < a.czas_w_minutach) ? -1 : 0))
                    if(`${c.DSR_Opis}`.replace(/\s/g, "") == `${x[0].DSR_Opis}`.replace(/\s/g, "")){
                    return  <KafelekZamowienia
                    wynos={x[0].TOR_CzyNaWynos}
                    dostawa={x[0].TOR_CzyZDostawa}
                     stolik={x[0].DSR_STOID} 
                    nazwaLokalizacji={''}
                    czasZamowienia = {c.czas}
                    timerKucharza = {timer.Timer}
                    opis={x[0].DSR_Opis.replace(/\s/g, "")}
                    sztucce={x}
                    kolor={'box--yellow'}
                    typZamowienia={x.typZamowienia}
                    zamowienia = {x}
                    obszar={1}
                    DSR_ID = {x[0].DSR_ID}
                    iloscZamowien={iloscZamowien(x)}
                     />
                    }
                 })
            })
           }
                 </div>  
                 <hr/>


                 {/* Zamień to na skończone zamowienia (Puszczony Timer we wszystkich) */}
                 <div className="fixed-bottom" style={{marginBottom:'5.5rem'}}>
                 <div id="rachunki" style={{maxWidth:"80vh"}}>
                 {odklikane.map(x => {
             
             return   <div class={`rachunki-box ${x.Timer > -1 ? 'box--red' : 'box--green'}`}  onClick={() => {
  
              history.push(`/kuchzam/${x.DSR_Opis}?czas=${x.czas}`)
            
            }}><span style={{fontSize:"2.5rem"}}>{x.DSR_Opis}<br/>{x.Timer > -1 ? '0:' + Math.abs(x.Timer) : '-0:' + Math.abs(x.Timer)}</span> </div>
         })}
      
        </div>
        </div>


            </div>
        
            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 sidebar-container" style={{textAlign:'center'}}>
            <div className="sidebar--komputer">
                <div className="sidebar ">
                <button type="button" class="btn btn-outline-light" style={{width:'95%', pointerEvents:'none', fontSize:'2rem', height:'4rem', marginLeft:'.5rem'}}>Kucharz</button>
             
              <h4>Koszyk</h4>
              {zamowieniaWKoszyku.map(x => {
                return <button class="btn btn-lg btn-primary">{x.DSR_Opis}</button>
              })} 
               <button class={`btn btn-lg btn-warning w-100 ${zamowieniaWKoszyku.length == 0 ? `display-none` : null}`} onClick={() => history.push('/piece?koszyk=true')}>Dodaj koszyk</button>
                </div>

              
                <div className="przelaczZamowienie">
               
               <div className="row">
                    
                   {ostatnioOdklikane.map(x => {
                     return  <a className="btn btn--przelaczZamowienie btn--yellow" style={{fontSize:"10px"}} onClick={
                       () => history.push(`/kuchzam/${x.DSR_Opis}?czas=${x.czas}`)
                     }>{x.DSR_Opis}</a>
                   })}
               </div>
              
              
               <div className="row">
                   <div className="col-xl-12 col-lg-12 col-md-12 com-sm-12 przelaczZamowienie__wszystkie">
                       <a href="#" className="btn btn--grey btn--wszystkie">Wszystkie</a>
                   </div>
               </div>

           </div>

            </div>
             
              
            
            
        </div>     
        </div>
        <div className="sidebar--telefon">
          <div className="mt-5"></div>
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top ">
    

                <button className="navbar-toggler my-3 btn-lg mx-auto btn--red" type="button" data-toggle="collapse"
                    data-target="#sidebarTel--reklamacja" aria-controls="navbarNav" aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span className="sidebar-telefon-button-ilosc" >111</span>
                    {/* <span className="ikona-belka filter-white"><img src="img/warning-sign.svg" alt=""/></span> */}
                </button>

               
                <button className="navbar-toggler my-3 btn-lg mx-auto btn--yellow" type="button" data-toggle="collapse"
                    data-target="#sidebarTel--sprzatnij" aria-controls="navbarNav" aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span className="sidebar-telefon-button-ilosc" >1</span>
                    {/* <span className=" ikona-belka filter-white"><img src="img/spray.svg" alt=""/></span> */}
                </button>

                <button className="navbar-toggler my-3 btn-lg mx-auto btn--green" type="button" data-toggle="collapse"
                    data-target="#sidebarTel--naWynos" aria-controls="navbarNav" aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span className="sidebar-telefon-button-ilosc" >1</span>
                    {/* <span className=" ikona-belka filter-white blok"><img src="img/delivery.svg" alt=""/></span> */}
                </button>

                
                <button className="navbar-toggler my-3 btn-lg mx-auto btn--blue" type="button" data-toggle="collapse"
                    data-target="#sidebarTel--konwersacja" aria-controls="navbarNav" aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span className="sidebar-telefon-button-ilosc" >1</span>
                    {/* <span className=" ikona-belka filter-white"><img src="img/messenger.svg" alt=""/></span> */}
                </button>

                <div className="collapse navbar-collapse " id="sidebarTel--reklamacja">
                  <div className="row mt-3">
                    <KafelekReklamacjiP/>
                    <KafelekReklamacjiP/>
                 
                  </div>
                </div>

                <div className="collapse navbar-collapse " id="sidebarTel--sprzatnij">
                    <div className="row mt-3">
                      <KafelekSprzatnacP/>
                      <KafelekSprzatnacP/>
                      <KafelekSprzatnacP/>
                      <KafelekSprzatnacP/>
                      </div>
                      </div>

                      <div className="collapse navbar-collapse " id="sidebarTel--naWynos">
                    <div className="row mt-3">
               <KafelekNaWynosP/>
               <KafelekNaWynosP/>
               <KafelekNaWynosP/>
                    </div>
                      </div>

                      <div className="collapse navbar-collapse " id="sidebarTel--konwersacja">
                      <div className="row mt-3">
               <KafelekKonwersacjaP/>
               <KafelekKonwersacjaP/>
               <KafelekKonwersacjaP/>
                    </div>

                    
                      </div>

        </nav>
        </div>
        <div className="belka--komputer ">
          {/* BELKA */}
  <div className="fixed-bottom">
          <link rel="stylesheet" href="./sass/main.css"></link>
        <div className="belka">
  
        <BelkaLewa/>
  
        <button id="scrollButtonDown" class="btn btn-lg"> ⏬ </button>
                   <button id="scrollButtonUp" class="btn btn-lg"> ⏫ </button>
  
          <div className="belka__prawa">
          <Link onClick={() => {sessionStorage.removeItem('OPR_KUCHARZ')
            setTimeout(() => {
              zaloguj()
             }, 300); 
        }} to="/kuchzam"><span>Wyloguj</span></Link>
          {/* <Link to="/raport"><span>Raport</span></Link>
          <Link to="/imprezy"><span>Imprezy</span></Link> */}

     
          </div>
        </div>
      </div>
      {/* BELKA */}
        </div>
        <div className="belka--telefon ">
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-bottom ">

            <a className="navbar-brand " href="#"></a>
            <button className="navbar-toggler btn-lg mx-left" type="button" data-toggle="collapse" data-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse " id="navbarNav">
                <ul className="navbar-nav">
                    <li className="nav-item active">
                        
                        <a className="nav-link" href="#">Napoje</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#">Raport</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#">Imprezy</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#"><br/></a>
                    </li>
                </ul>
            </div>


        </nav>

    </div>

    <div className="belka__ikony--telefon">
        <ul>
            {/* <li> <span className="ikona-belka filter-white"><img src="img/chef.svg" alt=""/></span></li>
            <li><span className="ikona-belka filter-white"><img src="img/fish.svg" alt=""/></span></li>
            <li><span className="ikona-belka filter-white"><img src="img/waiter.svg" alt=""/></span></li>
            <li><span className="ikona-belka filter-white"><img src="img/waiter-z-taca.svg" alt=""/></span></li> */}
        </ul>
    </div>
{/* MODAL */}

<div class="modal " id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">

      <div class="modal-body box--black" style={{textAlign:'center'}}>
        <h3>Skonfiguruj Stanowisko</h3>
      
        <label for="cars">Wybierz swoje stanowisko</label>
       
      
        <select name="cars" id="cars"  style={{width:'100%', color:'black'}} onChange={e => stanowiska.map(c => {
           if(c.STA_ID == e.target.value.substr(0,e.target.value.indexOf(' '))) {localStorage.setItem('HOST', JSON.stringify(c))} 
        })}>
                  <option style={{color:'black'}}></option>
        {stanowiska.map(x => {
              return <option style={{color:'black'}}>{x.STA_ID} { x.STA_Nazwa}</option>
            })}
        </select>

      </div>
      <div class="modal-footer box--black" style={{textAlign:'center'}}>
      <div type="button" style={{display:'inline-block'}} class="btn btn-primary" data-dismiss="modal" >OK</div>
      </div>
    </div>
  </div>
</div>

      <button data-backdrop="static" data-keyboard="false" type="button" class="btn btn-primary display-hidden" id="wlaczModalUstawieniaStanowiska" style={{position:'absolute', top:'30%'}} data-toggle="modal" data-target="#exampleModal">
</button>
                </>
    )

}

export default WiataMain

