import React from 'react'

export default function NavbarP() {
    return (
        <div className="fixed-top">
      <div className="navbar px-4 box--yellow " id="phone-nav">
        <div className="navbar__lokalizacja-i-status ">
          <span className="navbar__lokalizacja-i-status__ikona icon-sm">🍕</span>
          <span className="navbar__lokalizacja-i-status__stolik">222</span>
          <span className="navbar__lokalizacja-i-status__ilosc-zamowien">(1)</span>
          <span className="navbar__lokalizacja-i-status__status">VIP</span>
        </div>
        <div className="navbar__czas-zamowienia">
          <span className="navbar__czas-zamowienia__timer">0:20</span>
        </div>
      </div>
    </div>
    )
}
