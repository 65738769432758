import React from 'react'
import { useHistory } from 'react-router-dom';

export default function JedenRachunek({kolor,rachunekNumerek, onClick}) {
    return (
        <div onClick={()=>onClick()} className="sidebar-right__rachunki__jeden-rachunek box--gray" style={{fontSize:"1.2rem"}}>{rachunekNumerek}</div>
    )
}

JedenRachunek.defaultProps = {
    kolor: 'box--gray',
    rachunekNumerek: 100
}