import React from 'react'
import fetchApi from '../api/fetchApi'
import { useState, useEffect } from 'react'
import {BrowserRouter as Router, Route, Switch, Link} from 'react-router-dom'
import axios from 'axios'

export default function Sprzatanie() {
    const [sprzatanieDane, setsprztanieDane] = useState([])
    const [stolikiDoPosprzatania, setstolikiDoPosprzatania] = useState([])
   console.log(sprzatanieDane);
   
  

   const [dateTime, setdateTime] = useState('12-12-2000 12:12')
    let sprzatanieInterval 

    useEffect(async() => {
        var today = new Date();
        var date = today.getDate()+'-' +(today.getMonth()+1)+ '-' + today.getFullYear()
        var time = today.getHours() + ":" + today.getMinutes() 
        
        setdateTime(date+' '+time)
        setsprztanieDane(await fetchApi(`/sprzatanieWyswietl`))
        axios.post(`https://api.dostolika.pl/api/functions/chat`, {action:"get_chat", opr_id: 1}).then(res => {
            setstolikiDoPosprzatania(res.data.chats)
           
        })
       
        sprzatanieInterval = setInterval(async () => {
            setsprztanieDane(await fetchApi(`/sprzatanieWyswietl`))
            axios.post(`https://api.dostolika.pl/api/functions/chat`, {action:"get_chat", opr_id: 1}).then(res => {
                setstolikiDoPosprzatania(res.data.chats)
            })
            setdateTime(date+' '+time) 
        }, 10000);


    }, [])
    useEffect(() => {

        return () => {
            clearInterval(sprzatanieInterval)
        }
    }, [])

    return (
        <div>
              <div class="container">
              <h2>STOLIKI DO POSPRZĄTANIA, ODKLIKAJ GDY POSPRZĄTASZ</h2>
         {stolikiDoPosprzatania.map(x => {
             
             let diff = Math.abs(new Date(dateTime) - new Date(x.time))
        
             var minutes = Math.floor((diff/1000)/60);
       
            if(x.responses.some(c => c.message == "Posprzątane")){

            }else{
                return <button class={`btn fs-1  mx-2 btn-large btn-primary`} onClick={async(e) => {
                    const body = {opr_id: 1, action:"reply",help_id: x.id ,notes:"Posprzątane"}
                    e.target.classList.add('display-none')
                    const response = await fetch('https://api.dostolika.pl/api/functions/chat', {
                      method: 'post',
                      body: JSON.stringify(body),
                      headers: {'Content-Type': 'application/json'}
                    });
                    console.log(response);
                }}><span class="fw-bold fs-1" style={{pointerEvents: 'none'}}>{x.table}</span> || <span style={{pointerEvents: 'none'}} class=" fs-1 float-right text-light"> {minutes} min</span> </button>
            }
        })}
    <div class="row"  style={{background:'white'}}>
        

    {sprzatanieDane.map(c => {
          return <>

           <div class="col-12  d-flex justify-content-center text-dark fs-1 fw-bold border border-secondary ">{c.nazwa}</div> 

         <div class={`col-12 text-dark d-flex justify-content-center p-3 fs-3 fw-normal border`}> <button class={`btn fs-1  w-100 btn-large ${c.timer > 0 ?  'btn-danger' :'btn-success'}`}> 
              <span class="fs-3">{!(c.timer > 0) ?  'posprzątaj strefę za ' :'strefa czeka na sprzątnięcie już '}</span>
              <span class="fw-bold">{Math.abs(c.timer)}</span> min </button> </div>
          </>
       })}
        
        <div class="col-12 text-dark fs-3 fw-bold" style={{height:'5rem'}}></div>

    </div>
        
      
        
            </div>
            <div class="my-5"></div>
    
          <div class="fixed-bottom d-flex align-items-center justify-content-end border-top"  style={{width:'100%', height:'4rem', background:'white'}}>  
          
                <Link to="/" class="mx-2 btn btn-primary btn-lg float-left" >Strona główna</Link>
          </div>
    
        </div>
      
    )
}
