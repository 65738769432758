async function odblokujzablokujProdukt(artid, akcja) {
    const dataTrue = { "czy_zastrzezony_w_sprzedazy": 1 };
    const dataFalse = { "czy_zastrzezony_w_sprzedazy": 0 };

    const dataWlacz = { "czy_dotowany": 0 };
    const dataWylacz = { "czy_dotowany": 1 };

    
    let res 
    switch (akcja) {
        case 'odblokuj':
            res = await fetch(`https://api.dostolika.pl/api/products/${artid}`, {
            method:"PUT", 
            body: JSON.stringify(dataFalse),
            headers: {
              'Content-Type': 'application/json',
            }
        })
        console.log('odblokowany');
            break;
            case 'zablokuj':
              res = await fetch(`https://api.dostolika.pl/api/products/${artid}`, {
            method:"PUT", 
            body:  JSON.stringify(dataTrue),
            headers: {
              'Content-Type': 'application/json',
            }

            })
            console.log('zablokowany');
            break;
            case 'wylacz':
                res = await fetch(`https://api.dostolika.pl/api/products/${artid}`, {
              method:"PUT", 
              body:  JSON.stringify(dataWylacz),
              headers: {
                'Content-Type': 'application/json',
              }

              })
              console.log('wylacz');
           break;
            case 'wlacz':
                res = await fetch(`https://api.dostolika.pl/api/products/${artid}`, {
              method:"PUT", 
              body:  JSON.stringify(dataWlacz),
              headers: {
                'Content-Type': 'application/json',
              }

              })
              console.log('wlacz');
            break;
 
        default:
            break;
    }
    const data = await res.json()
    return data
  }
      
  export default {
    odblokujzablokujProdukt
  }