import React from 'react'
import fetchApi from '../api/fetchApi'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

export default function SzukajDSR({match}) {
 const [wynik, setwynik] = useState([])
  
 useEffect(async() => {
   setwynik(await fetchApi(`/szukajdsr?dsr=${match.params.dsr}`))

   }, [])
  
  return (
        <div>
           <table class="table table-dark table-striped">
  <thead>
    <tr>

      <th scope="col">Ilość</th>
      <th scope="col">Nazwa Artykułu</th>
      <th scope="col">Data Wydruku</th>
      <th scope="col">Data Kucharz</th>
      <th scope="col">Data Wydawka</th>
      <th scope="col">Data Kelner</th>
      <th scope="col">Kucharz Operator</th>
      <th scope="col">Wydawka Operator</th>
      <th scope="col">Kelner Operator</th>

    </tr>
  </thead>
  <tbody>
   {wynik.map(x => {
     return (
      <tr>
      <td>{x.DSL_Ilosc}</td>
      <td>{x.ART_Nazwa}</td>
      <td>{`${x.KDL_DataWydruku}`.replace(`T`, ' ').replace(`Z`, ' ').slice(0, -8)}</td>
      <td>{x.kucharzData}</td>
      <td>{x.wydawkaData}</td>
      <td>{x.kelnerData}</td>
      <td>{x.kuch_imie} {x.kuch_nazwisko}</td>
      <td>{x.wyd_imie} {x.wyd_nazwisko}</td>
      <td>{x.kel_imie} {x.kel_nazwisko}</td>
   
    </tr>
     )
   })}
   

  </tbody>
</table>

<button class="btn btn-light fs-2 w-100 fixed-bottom" >
          <Link to='/szukaj'><button type="button" class="btn btn-primary float-right fs-3">Powrót</button></Link>
      </button>
        </div>
    )
}
