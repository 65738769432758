import OpisBox from '../components/zamowienie/OpisBox'
// import Modal from '../components/zamowienie/Modal'
import NaTerazKafelek from '../components/zamowienie/NaTerazKafelek'
import PotrawyKafelek from '../components/zamowienie/PotrawyKafelek'
import ContextMenu from '../components/zamowienie/ContextMenu'
import KafelekSurowki from '../components/zamowienie/KafelekSurowki'
import Navbar from '../components/wszystkie-zamowienia/Navbar'
import {Helmet} from "react-helmet";
import DodatekKafelek from '../components/zamowienie/DodatekKafelek'
import PrzyciskiDrukujNapoje from '../components/zamowienie/PrzyciskiDrukujNapoje'
import KafelekInformacyjny from '../components/zamowienie/KafelekInformacyjny'
import PrzyciskWydawka from '../components/zamowienie/PrzyciskWydawka'
import PrzyciskWszystkieRachunki from '../components/zamowienie/PrzyciskWszystkieRachunki'
import JedenRachunek from '../components/zamowienie/JedenRachunek'
import PrzyciskZamianaZamowienia from '../components/zamowienie/PrzyciskZamianaZamowienia'
import PrzyciskZmianaZamowieniaWiecej from '../components/zamowienie/PrzyciskZmianaZamowieniaWiecej'
import Footer from '../components/wszystkie-zamowienia/Footer'
import FooterP from '../components/zamowienie/FooterP'
import NavbarP from '../components/zamowienie/NavbarP'
import KafelekNaTerazP from '../components/zamowienie/KafelekNaTerazP'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom';
import  { useContext, useEffect, useState, createContext, useMemo } from 'react'
import { ProduktyContext } from '../ProduktyContext'
import {Obszar3Context} from  '../Obszar3Context'
import { useLocation } from 'react-router'
import apiRequests from '../apiRequests'
import policzSztucce from '../funkcje/policzSztucce'
import policzTace from '../funkcje/policzTace'
import policzTalerze from '../funkcje/policzTalerze'
import wszystkieDsle from '../funkcje/wszystkieDsle'
import wszystkieDsry from '../funkcje/wszystkieDsry'
import fetchApi from '../api/fetchApi'
import logowanieZEkranu from '../funkcje/logowanieZEkranu'

//Imgs
import { ReactComponent as KelnerSvg } from '../img/waiter.svg';
import { ReactComponent as KucharzSvg } from '../img/chef.svg';
import { ReactComponent as TacaSvg } from '../img/waiter-z-taca.svg';
import { ReactComponent as WydSvg } from '../img/fish.svg';
import BelkaLewa from '../components/all/BelkaLewa'
import sortowanieDsrPrzyciski from '../funkcje/sortowanieDsrPrzyciski'
import odklikajTace from '../funkcje/odklikajTace'


function Zamowienie({match}){


  const location = useLocation()
  let czasWydruku = new URLSearchParams(location.search).get("czas")


  let czas = czasWydruku

    var hms = czas    // your input string
    var a = hms.split(':'); // split it at the colons

    // Hours are worth 60 minutes.
    var minutes = (+a[0]) * 60 + (+a[1]);

   czas = minutes


  const {stolik} = useContext(ProduktyContext)

  //Logowanie () PIN
  let history = useHistory();
 //Logowanie
const zaloguj = ()=>{
  let daneLogowania = JSON.parse(sessionStorage.getItem('OPR_KELNER'))

  if(daneLogowania != null){
    return true;
  }else{
   history.push(`/logowanie?ekran=kelner&stolik=${match.params.stolik}?czas=${czasWydruku}`)

  }
  

}

 

  
  const[obszar1, setObszar1] = useState([])
  const[obszar2, setObszar2] = useState([])
  const[obszar3, setObszar3] = useState([])
  const[obszar4, setObszar4] = useState([])
  const[wszystkie, setWszystkie] = useState([])
  const[ostatnioOdklikane, setOstatnioOdklikane] = useState([])
  const [taceglowne, settaceglowne] = useState([])
  const [tacezupy, settacezupy] = useState([])
  const [tacedodatkowe, settacedodatkowe] = useState([])
  const [idgrupy, setidgrupy] = useState(null)

 //OpisDSR State
  const[dsrOpisy, setDsrOpisy] = useState([])

  //Get tasks
  const getTasks = async () =>{
    const rachunkiFromServer = await fetchZamowienia()
    const odk = await apiRequests.ostatnioOdklikane('Kelner')

  setOstatnioOdklikane(odk)
    console.log(rachunkiFromServer);
    // console.log(match);
    
    setObszar1(rachunkiFromServer[0] != undefined || null ? rachunkiFromServer[0] : []  )
    setObszar2(rachunkiFromServer[1] != undefined || null ? rachunkiFromServer[1] : [] )
    setObszar3(rachunkiFromServer[2] != undefined || null ? rachunkiFromServer[2] : [] )
    setObszar4(rachunkiFromServer[3] != undefined || null ? rachunkiFromServer[3] :  [])
    setWszystkie(rachunkiFromServer[4] != undefined || null ? rachunkiFromServer[4] : [] )
   
    if(rachunkiFromServer[4].some(x => x.DSR_IDGrupy != 'null')){
      
      settaceglowne(await fetchApi(`/tacepogrupie?idgrupy=${rachunkiFromServer[4].find(x => x.DSR_IDGrupy != null) != undefined &&  rachunkiFromServer[4].find(x => x.DSR_IDGrupy != null).DSR_IDGrupy}&jakataca=2`))

      settacezupy(await fetchApi(`/tacepogrupie?idgrupy=${rachunkiFromServer[4].find(x => x.DSR_IDGrupy != null) != undefined &&  rachunkiFromServer[4].find(x => x.DSR_IDGrupy != null).DSR_IDGrupy}&jakataca=1`))

      settacedodatkowe(await fetchApi(`/tacepogrupie?idgrupy=${rachunkiFromServer[4].find(x => x.DSR_IDGrupy != null) != undefined &&  rachunkiFromServer[4].find(x => x.DSR_IDGrupy != null).DSR_IDGrupy}&jakataca=4`))
     
      setidgrupy(rachunkiFromServer[4].find(x => x.DSR_IDGrupy != null).DSR_IDGrupy)
    }
  
    
    
    //OpisDSR 

    console.log(rachunkiFromServer[4]);
    let opisyZDsrow = []
  

    rachunkiFromServer[4].map(x => {
      if(!opisyZDsrow.includes(x.DSR_OpisDostawy)){
        opisyZDsrow.push(x.DSR_OpisDostawy)
      }
    })
    setDsrOpisy(opisyZDsrow)


}
//Główny useEffect
  useEffect(()=>{
    if(localStorage.getItem('HOST') == null){
      localStorage.setItem('HOST', JSON.stringify({STA_ID:1}))
    }
  
    let buttonGrupyId = setTimeout(  () => {document.getElementById('buttonGrupy').click()
  },1000)
  
   getTasks()

  let wyliczTaceInterval = setTimeout(() => {
    document.getElementById('wyliczTaceBtn').click()
  }, 100);

     let zapytajBaze = setInterval(()=>getTasks(),2000)
     return () =>{
       clearInterval(zapytajBaze)
      clearInterval(buttonGrupyId)
      clearInterval(wyliczTaceInterval)
      clearInterval(interwalModalEkran)
         clearInterval(interwalModalModal)
     }
 },[])
 


 // Fetch Data (Różne Zamóienia)
 const fetchZamowienia = async()=>{
    let res = await fetch(`https://kds.sereczyn.pl/api/rachunki`,{method:"POST"})
   res = await fetch(`https://kds.sereczyn.pl/api/rachunki/${match.params.stolik}`,{method:"POST"})
     const data = await res.json()
     return data
 }
 
 //Segregowanie przycisków
 var dsrPrzyciski = wszystkie.reduce(function(dsrPrzyciski, org) {
  (dsrPrzyciski[org.DSR_ID] = dsrPrzyciski[org.DSR_ID] || []).push(org);
  return dsrPrzyciski;
}, {})


dsrPrzyciski = Object.entries(dsrPrzyciski);
dsrPrzyciski = sortowanieDsrPrzyciski(dsrPrzyciski)


//Segregowanie Nateraz/surowek/deserow
var obszar1Zam = obszar1.reduce(function(ob, org) {
  (ob[org.ART_Nazwa] = ob[org.ART_Nazwa] || []).push(org);
  return ob;
}, {})

obszar1Zam = Object.entries(obszar1Zam)

obszar1Zam.map(grupa =>{
  let ilosc =0;
  let strDslId = []
  grupa[1].map(pozycja =>{
    ilosc += Number(pozycja.DSL_Ilosc)
    strDslId.push(',',pozycja.DSL_ID)
  
  })
  grupa.push({nazwa:grupa[0], ilosc: ilosc, kat: grupa[1][0].ART_KatWydruku, query: strDslId.join('').substring(1), operator: grupa[1][0].kelnerOpr, czasPoz : grupa[1][0].czas_pozycja, artId: grupa[1][0].DSL_ARTID})
})



var obszar2Zam = obszar2.reduce(function(ob, org) {
  (ob[org.ART_Nazwa] = ob[org.ART_Nazwa] || []).push(org);
  return ob;
}, {})

obszar2Zam = Object.entries(obszar2Zam)

obszar2Zam.map(grupa =>{
  let ilosc =0;
  let strDslId = []
  grupa[1].map(pozycja =>{
    ilosc += Number(pozycja.DSL_Ilosc)
    strDslId.push(',',pozycja.DSL_ID)
  
  })
  grupa.push({nazwa:grupa[0], ilosc: ilosc, kat: grupa[1][0].ART_KatWydruku, query: strDslId.join('').substring(1), operator: grupa[1][0].kelnerOpr, czasPoz : grupa[1][0].czas_pozycja, artId: grupa[1][0].DSL_ARTID })
})


var obszar4Zam = obszar4.reduce(function(ob, org) {
  (ob[org.ART_Nazwa] = ob[org.ART_Nazwa] || []).push(org);
  return ob;
}, {})

obszar4Zam = Object.entries(obszar4Zam)

obszar4Zam.map(grupa =>{
  let ilosc =0;
  let strDslId = []
  grupa[1].map(pozycja =>{
    ilosc += Number(pozycja.DSL_Ilosc)
    strDslId.push(',',pozycja.DSL_ID)
  
  })
  grupa.push({nazwa:grupa[0], ilosc: ilosc, kat: grupa[1][0].ART_KatWydruku, query: strDslId.join('').substring(1), operator: grupa[1][0].kelnerOpr, czasPoz : grupa[1][0].czas_pozycja, artId: grupa[1][0].DSL_ARTID})
})

function wyliczTace() {
 setTimeout(() => {
   getTasks()
  policzTace(wszystkie, 'zupy')
  policzTace(wszystkie, 'glowne')
  getTasks()
 }, 500); 
}

// console.log(obszar1Zam, "OBSZAR1");
// console.log(obszar2Zam, "OBSZAR2");
// console.log(obszar4Zam, "OBSZAR4");

//Odklikaj wszystkie pozycje w kafelku


function odklikajPozycje(query, status){
 
 if(zaloguj() === true){

  const fetchZamowienia = (q,s)=>{
    
    fetch(`https://kds.sereczyn.pl/api/odklikajPozycje?query=${q}&status=${s}&ekran=Kelnerska&operator=${(JSON.parse(sessionStorage.getItem('OPR_KELNER'))).id}&host=${JSON.parse(localStorage.getItem('HOST')).STA_ID}`,{method:"POST"})
}
  fetchZamowienia(query, status)

 }
 getTasks()
 
}

//Logowanie z ekranu 
const [pin, setpin] = useState([])
const [pintace, setpintace] = useState([])
let intervalEkraninput
let interwalModalEkran
let interwalModalModal

    useEffect(() => {
        intervalEkraninput =setInterval(() => {
        document.getElementById('inputEkran').focus()
       }, 400); 

 
      
       document.addEventListener("keydown", keyDownTextField, true);

        function keyDownTextField(e) {
        var keyCode = e.keyCode;
        if(keyCode==13) {
           let submit = document.getElementById('subbmitEkran')
           let submitTace = document.getElementById('subbmitEkranTac')
         
            if(submit != null || submitTace != null){
          

              submitTace.click()
              submit.click()
    
   
            
            
            setTimeout(() =>document.getElementById('inputEkran').value = '' ,60) 
            setTimeout(() =>document.getElementById('inputDoTac').value = '' ,40) 
            }else{
               
            }
        } }

       return () => {
         clearInterval(intervalEkraninput)
         clearInterval(interwalModalEkran)
         clearInterval(interwalModalModal)

         document.removeEventListener('keydown',keyDownTextField, true )
    
        }
    }, [])
    
    
   async function odklikajTaceFunction(jakataca){
    
    if(zaloguj() === true){
    
    if( !await odklikajTace(JSON.parse(sessionStorage.getItem('OPR_KELNER')).id, JSON.parse(localStorage.getItem('HOST')).STA_ID, idgrupy,jakataca)){
      alert('Jedna taca na 3 minuty lub nie ma tac do zaniesienia')
    }
    getTasks()
  }
  }
  //Modal kategorie state
  const [kategorieModal, setkategorieModal] = useState(-1)
  useEffect(() => {
    clearInterval(intervalEkraninput)
   console.log(kategorieModal);
   
    if(kategorieModal == -1){
   
      clearInterval(interwalModalModal)
      setTimeout(() => {
          document.getElementById('inputEkran').focus()
          interwalModalEkran = setInterval(() =>   document.getElementById('inputEkran').focus() ,400)
     },100)
  
    }else{
     
      clearInterval(interwalModalEkran)
      setTimeout(() => {
        document.getElementById('inputDoTac').focus()
        interwalModalModal =  setInterval(() =>   document.getElementById('inputDoTac').focus() ,400)
       },100)
    }

    return () => {
      clearInterval(interwalModalEkran)
      clearInterval(interwalModalModal)
     }
  }, [kategorieModal])
  
    return (<>
{/* MODAL */}
<div class="modal hide" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false" data-keyboard="false" data-backdrop="static"  >
 <div style={{width:'100%', height:'100%', background:'white', position:'absolute'}}>
  <div class="modal-dialog" > 
    <div class="modal-content" >
   
   { JSON.parse(sessionStorage.getItem('OPR_KELNER')) != null ? <> <span style={{color:'black'}}>{JSON.parse(sessionStorage.getItem('OPR_KELNER')).id}</span>  <span style={{color:'black'}}>{JSON.parse(sessionStorage.getItem('OPR_KELNER')).imie}</span> <span style={{color:'black'}}>{JSON.parse(sessionStorage.getItem('OPR_KELNER')).nazwisko}</span> {JSON.parse(localStorage.getItem('HOST')).STA_ID} </> : null}
      <div class="modal-header" >
        <h5 class="modal-title" style={{color:'black'}}  id="exampleModalLabel">Kategorie: {taceglowne.length != 0 ? <span style={{color:'black'}}  onClick={() =>setkategorieModal(2)}>Główne </span> : null }{tacezupy.length != 0 ? <span style={{color:'black'}}  onClick={() =>setkategorieModal(1)}>Zupy </span> : null }{tacedodatkowe.length != 0 ? <span style={{color:'black'}}  onClick={() =>setkategorieModal(4)}>Dodatkowe </span> : null } <span style={{color:'black'}}  onClick={() =>setkategorieModal(0)}>Wszystkie</span></h5>
      </div>
     
      <input style={{color:'black'}} onChange={(e) => setpintace(e.target.value)} type="password" id='inputDoTac'></input>
          <button id="subbmitEkranTac" onClick={async () =>{
         
          setTimeout(() => {
            console.log(kategorieModal);
            if(kategorieModal != -1 ){
              logowanieZEkranu('kelner', pintace)
            
              setTimeout(() => {
              odklikajTaceFunction(kategorieModal)
             }, 500)}
              else if( kategorieModal == 0 || kategorieModal == -1) {
                
              }
            else {
              alert('Wybierz kategorie, wszystkie tacą są tylko do wglądu')
            }
         
           }, 50);
        }}>subbmit</button>
      <div class=""  style={{position:'relative', right:'25rem', width:'200%', pointerEvents:'none'}}  >

      {taceglowne.map(x => {
         if(kategorieModal == 2  || kategorieModal == 0) return <PrzyciskWydawka  nazwa={`${x.TAC_ID} ${x.TAC_OPRID} 🧆`} onClick={() => odklikajTaceFunction(x.TAC_ID)}   kolor={x.TAC_OPRID != null ? `box--gray` : `box--purple` }/> 
         })}

        {tacezupy.map(x => {
             if(kategorieModal == 1  || kategorieModal == 0) return <PrzyciskWydawka  nazwa={`${x.TAC_ID} ${x.TAC_OPRID} 🍲`}   onClick={() => odklikajTaceFunction(x.TAC_ID)}  kolor={x.TAC_OPRID != null ? `box--gray` : `box--blue`}/> 
                })}
        {tacedodatkowe.map(x => {
          if(kategorieModal == 4 || kategorieModal == 0 ) return <PrzyciskWydawka  nazwa={`${x.TAC_ID} ${x.TAC_OPRID} ➕`}   onClick={() => odklikajTaceFunction(x.TAC_ID)}  kolor={x.TAC_OPRID != null ? `box--gray` : `box--red`}/> 
          
         })}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" onClick={() => setkategorieModal(-1)} >Close</button>
       
    <button class="btn -btn-lg btn-primary" id="wyliczTaceBtn" onClick={() =>wyliczTace()}> wyliczTace </button>
    <button class="btn -btn-lg btn-primary" onClick={async () =>{await fetchApi(`/dodajtace?idgrupy=${idgrupy}&jakataca=4 `); getTasks()}}> Dodaj Tacę </button>
      </div>
      </div>
    </div>
  </div>
</div>
{/* MODAL */}
<div className="computer-version">
    {/* {NAVBAR} */}
    <div className="fixed-top">
      <div id="computer-nav" className={`navbar px-4 ${ czas > 40 ? 'box--red' : ''}  ${ czas >=0 && czas <= 15 ? 'box--gray' : '' }${czas > 15 && czas <=25 ? 'box--green' : '' }${czas > 25 && czas <=40 ? 'box--yellow' : '' }`} style={{fontSize:"1.5rem !important"}}>
        <div className="navbar__lokalizacja-i-status ">
          <span className="navbar__lokalizacja-i-status__ikona icon-sm">🍕</span>
          <span className="navbar__lokalizacja-i-status__stolik">{match.params.stolik}</span>
          <span className="navbar__lokalizacja-i-status__ilosc-zamowien">{`     (${dsrPrzyciski.length})`}</span>
          <span className="navbar__lokalizacja-i-status__stolik">{wszystkie.find(x => x.DSR_Telefon != '') != undefined ? wszystkie.find(x => x.DSR_Telefon != '').DSR_Telefon : null}</span>
          <span className="navbar__lokalizacja-i-status__stolik" >{wszystkie.find(x => x.sektor != null) != undefined ? wszystkie.find(x => x.sektor != null).sektor : null}</span>
         
          <span className="navbar__lokalizacja-i-status__stolik" style={{fontSize:'2rem'}}>Do ryb:{policzSztucce(wszystkie, 1)}</span>
          <span className="navbar__lokalizacja-i-status__stolik" style={{fontSize:'2rem'}}>Do mięs:{policzSztucce(wszystkie, 4)}</span>
          <span className="navbar__lokalizacja-i-status__stolik" style={{fontSize:'2rem'}}>widelec:{policzSztucce(wszystkie, 5)}</span>
          <span className="navbar__lokalizacja-i-status__stolik" style={{fontSize:'2rem'}}>zupa:{policzSztucce(wszystkie, 3)}</span>
          
          <span className="navbar__lokalizacja-i-status__stolik" style={{fontSize:'2rem'}}>talerz:{policzTalerze(wszystkie, 2)}</span>
          <span className="navbar__lokalizacja-i-status__stolik" style={{fontSize:'2rem'}}>miska:{policzTalerze(wszystkie.filter(x => (x.DSL_DSLIDPowiazana != null && x.ART_ZastawaZestaw == true )|| x.DSL_DSLIDPowiazana == null && x.ART_KatWydruku != 3  ), 6)}</span>
          <button class={`btn btn-primary`} onClick={() => {
            if(zaloguj() === true){
              fetchApi(`/wydajsztucce?query=${wszystkieDsle(wszystkie)}&oprid=${JSON.parse(sessionStorage.getItem('OPR_KELNER')).id}`)
            }else{
              zaloguj()
            }
          
          } }>Wydaj Sztucce</button>
         
         {/* //Dodawanie grupy */}
          <button id="buttonGrupy" class={`btn btn-primary display-hidden`} onClick={() => {
              !wszystkie.some(x => x.DSR_IDGrupy != null) ?  fetchApi(`/dodajgrupe?query=${wszystkieDsry(wszystkie)}`) :  fetchApi(`/updategrupy?dsry=${wszystkieDsry(wszystkie)}&idGrupy=${wszystkie.find(x => x.DSR_IDGrupy != null).DSR_IDGrupy}`)
          } }></button>

           <button id="buttonGrupy" class={`btn btn-primary display-hidden`} onClick={() => {
              !wszystkie.some(x => x.DSR_IDGrupy != null) ?  fetchApi(`/dodajgrupe?query=${wszystkieDsry(wszystkie)}`) :  fetchApi(`/updategrupy?dsry=${wszystkieDsry(wszystkie)}&idGrupy=${wszystkie.find(x => x.DSR_IDGrupy != null).DSR_IDGrupy}`)
          } }></button>
           
           <button  class={`btn btn-warning przytrzymajPrzycisk`}></button>

          {/* <span className="navbar__lokalizacja-i-status__status">VIP</span> */}
          {/* <span className="navbar__lokalizacja-i-status__lokalizacja">Rybakówka</span> */}
        </div>
      
        <div className="navbar__czas-zamowienia">
          <span className="navbar__czas-zamowienia__timer">{czasWydruku}</span>
        </div>
      </div>
    </div>
            {/* {NAVBAR} */}
        {/* Lewa strona */}
        <div className="middle-content row px-3 margin-top-middle-container">
        <div className="middle-content__left  col-xl-2 col-lg-2 col-md-2">
         {/* Opis DSR */}
         {dsrOpisy.map(x => { 
                if(x != ''){
                  return   <OpisBox opis={x} />
                }
           })}
         
        
         <div className="middle-content__left__sidebar mt-3 row" >

       
<div className="middle-content__left__sidebar__container">
         {obszar2Zam.map(x =>{
              return <NaTerazKafelek artId={x[2].artId} czasPoz={x[2].czasPoz} operator={x[2].operator} ilosc={x[2].ilosc} kolor={x[1][0]} query={x[2].query} nazwa={x[0]} onClick={odklikajPozycje}/>
            })}
       </div>
       </div>
      </div>
  
        {/* Środek Góra */}
        
      <ContextMenu/>
      <div className="middle-content-middle col-xl-8 col-lg-8 col-md-8">
      <div className="middle-content-middle__gora py-3">
          <div className="middle-content-middle__gora__na-teraz">
            {obszar1Zam.map(x =>{
              return <NaTerazKafelek artId={x[2].artId} operator={x[2].operator} czasPoz={x[2].czasPoz} ilosc={x[2].ilosc} kolor={x[1][0]} query={x[2].query} nazwa={x[0]} onClick={odklikajPozycje}/>
            })}
            
            </div>
            </div>
            <div className="middle-content-middle__środek py-1">
            <div className="middle-content-middle__srodek__potrawy">

            {obszar3.map((x,idx )=>{
              if(x.DSL_DSLIDPowiazana == null){
                return  <PotrawyKafelek artid={x.DSL_ARTID} operator={x.kelnerOpr} informacja={x.DSL_InformacjeDoKuchni}  nazwa={x.ART_Nazwa} onClick={odklikajPozycje} czasPozycja={x.czas_pozycja} ilosc={x.DSL_Ilosc} DSL_ID={x.DSL_ID} obszar={obszar3} zamowienie={x} />
              }
            
            })}
      
           
            </div>
              </div>
              <div className="middle-content-middle__dol py-3">

          <div className="middle-content-middle__dol__dodatki">

          {obszar4Zam.map(x =>{
              return <NaTerazKafelek artId={x[2].artId} operator={x[2].operator} czasPoz={x[2].czasPoz} ilosc={x[2].ilosc} kolor={x[1][0]} query={x[2].query} nazwa={x[0]} onClick={odklikajPozycje}/>
            })} 
          
            
            </div>
            </div>
      </div>

      <div className="middle-content-right col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 mt-4">
        <div className="sidebar-right">
        <div className="sidebar-right__przyciski">
        <div className="sidebar-right__przyciski__drukuj">
          <i className="icon-sm">🖨</i>
          <div className="sidebar-right__przyciski__drukuj--text">Wydruk</div>
        </div>
        <Link to={`/test/${match.params.stolik}?czas=${czasWydruku}`} className="sidebar-right__przyciski__pokaz-napoje">
          <i className="icon-sm">☕</i>
          <div className="sidebar-right__przyciski__pokaz-napoje--text">Pokaż napoje</div>
        </Link>
      </div>
    
          <div className="sidebar-right__kafelki-informacyjne">


    
            

          </div>
       
          <div className="sidebar-right__przyciski-wydawka">
        
         {/* {policzTace(wszystkie, 'zupy').map(x => {
            return <PrzyciskWydawka nazwa={`zupa`} kolor={`box--yellow`}/>
         })}
         {policzTace(wszystkie, 'glowne').map(x => {
            return <PrzyciskWydawka  nazwa={`glowne` }  kolor={`box--purple`}/>
         })} */}

{taceglowne.map(x => {
            return <PrzyciskWydawka onClick={() => setkategorieModal(2)}  nazwa={`${x.TAC_ID} ${x.TAC_OPRID}`} kolor={x.TAC_OPRID != null ? `box--gray` : `box--purple`}/>
         })}
{tacezupy.map(x => {
            return <PrzyciskWydawka  onClick={() => setkategorieModal(1)}  nazwa={`${x.TAC_ID} ${x.TAC_OPRID}`}   kolor={x.TAC_OPRID != null ? `box--gray` : `box--blue`}/>
         })}
{tacedodatkowe.map(x => {
            return <PrzyciskWydawka  onClick={() => setkategorieModal(4)} nazwa={`${x.TAC_ID} ${x.TAC_OPRID}`}   kolor={x.TAC_OPRID != null ? `box--gray` : `box--red`}/>
         })}


        
      
            
        </div>
        
        <div style={{positon:'relative',left:'20rem'}}>
          <input style={{color:'black'}} onChange={(e) => setpin(e.target.value)} type="password" id='inputEkran'></input>
          <button id="subbmitEkran" onClick={() => {
            if(kategorieModal == -1){
              logowanieZEkranu('kelner', pin)}
            }
           }>subbmit</button>
        </div>
  
        <div className="sidebar-right__rachunki" style={{marginBottom:"-4.5rem"}}>
          {dsrPrzyciski.map(x =>{
           return <Link to={`/zam/${match.params.stolik}?czas=${czasWydruku}`}> <JedenRachunek rachunekNumerek={x[1][0].DSR_NrRachunku} onClick={()=> 
            setTimeout(()=> history.push(`/zam/${match.params.stolik}/${x[0]}?czas=${czasWydruku}`),10)
          }/></Link>
          })}
          

         <Link to={`/zam/${match.params.stolik}?czas=${czasWydruku}`}> <PrzyciskWszystkieRachunki/></Link>
            
          </div>
        </div>
        <div className="sidebar-right__zmiana-zamowienia">
        <div >
        {ostatnioOdklikane.map(x => {
                     return  <a className="btn btn-primary btn-lg btn--yellow" style={{fontSize:"10px"}} onClick={

                       () =>
                       { history.push(`/`)
                        setTimeout(() => history.push(`/zam/${x.DSR_Opis}?czas=${x.czas}`),10)}

                     }>{x.DSR_Opis}</a>
                   })}
          
        </div>
         <PrzyciskZmianaZamowieniaWiecej/>
        </div>

      </div>
        {/* BELKA */}
  <div className="fixed-bottom">
          <link rel="stylesheet" href="./sass/main.css"></link>
        <div className="belka">
                   <h4 style={{display:'inline'}}>Zalogowany: {JSON.parse(sessionStorage.getItem('OPR_KELNER')) != undefined ? `${JSON.parse(sessionStorage.getItem('OPR_KELNER')).imie} ${JSON.parse(sessionStorage.getItem('OPR_KELNER')).nazwisko}` : null}</h4>
  
          <div className="belka__srodek">
          {/* <Link to="/napoje"> <span>Napoje</span> </Link> */}
          </div>
  
          <div className="belka__prawa" style={{top:'-.5rem'}}>
          {/* <Link to="/raport"><span>Raport</span></Link>
          <Link to="/imprezy"><span>Imprezy</span></Link> */}
                   <Link to="/kelner" onClick={() =>   sessionStorage.removeItem('OPR_KELNER') }><div className="btn btn btn-outline-light btn-lg" style={{padding:'1rem 1.5rem 1rem' }}>Gotowe</div></Link>

          </div>
        </div>
      </div>
      {/* BELKA */}
      </div>
      {/* <Helmet>
      <script src="./scripts/e-zam.js" type="text/javascript" />
      <script defer src="./libki/keyboard/keyboard.js" type="text/javascript" ></script>
      <script defer src="scripts/long-press.js"></script>
            </Helmet> */}

            </div>

            <div className="phone-version">
             <NavbarP/>
            
             <div id="phone-content">
      <div id="zamowienia-tel" className="zamowienia">
      <div id="naTeraz">
      <h2 style={{textAlign:"center"}}>NA TERAZ</h2>
      <div className="row">
      <KafelekNaTerazP/>
      <KafelekNaTerazP/>
      <KafelekNaTerazP/>
      <KafelekNaTerazP/>
   
</div>
      </div>
      <div id="potrawy">
      <h2 style={{textAlign:"center"}}>POTRAWY</h2>
          <div className="row">
       
         <PotrawyKafelek phone={true} />
         <PotrawyKafelek phone={true} />
         <PotrawyKafelek phone={true} />
         <PotrawyKafelek phone={true} />
             
             
              </div>
          
          </div>

          <hr/>
          <div id="surowki">
            <h2 style={{textAlign:"center"}}>SURÓWKI</h2>
          <div className="row">
          <KafelekNaTerazP/>
      <KafelekNaTerazP/>
      <KafelekNaTerazP/>
</div>

          </div>
          <hr/>
          <div id="dodatki">
            <h2 style={{textAlign:"center"}}>DODATKI</h2>
          <div className="row">
          <KafelekNaTerazP/>
      <KafelekNaTerazP/>
      <KafelekNaTerazP/>
</div>

          </div>
          <div id="sidebar-tel" className="sidebar " >


   <PrzyciskiDrukujNapoje/>

<div className="sidebar-right__kafelki-informacyjne">


</div>

<OpisBox phone={true}/>

<div className="sidebar-right__przyciski-wydawka">
  <PrzyciskWydawka/>
  <PrzyciskWydawka/>
  <PrzyciskWydawka/>
  <PrzyciskWydawka/>
</div>

<div className="sidebar-right__rachunki">
<div style={{maxHeight: "7.9rem", overflow:"scroll",marginBottom:"-1.5rem"}}>
 <JedenRachunek/>
 <JedenRachunek/>
 <JedenRachunek/>
 </div>


  <div className="sidebar-right__rachunki__wszystkie-rachunki box--gray">
    
 <PrzyciskWszystkieRachunki/>
  </div>
</div>

<div className="sidebar-right__zmiana-zamowienia">
  <div className="przyciski" style={{maxHeight: "12rem", overflow:"scroll", marginBottom:"-1.5rem"}}>
<PrzyciskZamianaZamowienia/>
<PrzyciskZamianaZamowienia/>
<PrzyciskZamianaZamowienia/>
<PrzyciskZamianaZamowienia/>
<PrzyciskZamianaZamowienia/>
<PrzyciskZamianaZamowienia/>
<PrzyciskZamianaZamowienia/>
</div>
<PrzyciskZmianaZamowieniaWiecej/>
</div>


</div>

        </div>
        
        <hr/>
      
  
        </div>



             <FooterP/>

            </div>

            
        </>

        
        )
}

export default Zamowienie