import React from 'react'
import wyszukajRachunki from '../funkcje/wyszukajRachunki'
import {useState, useEffect} from 'react'
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import { Link, useHistory } from 'react-router-dom';


function Lista(props){
   let css={width:'100%', height:'5rem', borderRadious:'10px'}
   let history=useHistory()

   return <div onClick={() => history.push(`/szukaj/${props.dsr}`)} style={css} class={`btn-danger rounded`}>
       <h3>{`${props.id}      ${props.dataOtwarcia}      ${props.dataZamkniecia}      ${props.imie}  ${props.dsr}`}</h3> 
        </div>
}



export default function Szukaj() {
   //Style
    let styleInput = {display:'block',
                        width:'100%',
                        marginBottom:'.5rem'
                        ,height:'5rem',
                        color:'white',
                        background:'black',
                        borderStyle: 'none',
                        borderRadious: '10px'
                        }
    let container = { 
        display:'grid', 
        gridTemplateColumns:'1fr',
        gridGap: ".5rem"
        }
        

        const [inputValue, setinputValue] = useState([])
        const [lista, setlista] = useState([1])
        const [zmiana, setzmiana] = useState(0)
    
        useEffect(() => {
          wyszukajEnter()

        }, [inputValue])

        async function wyszukajEnter() {
            let input = await wyszukajRachunki(inputValue) 
            setlista(input)
        }

      let onChange = (input) => {
            document.getElementById(`wyszukajInput`).value = input
            setinputValue(input)
          }
        
       let  onKeyPress = (button) => {
            console.log("Button pressed", button);
            zmiana == 0 ? setzmiana(1) : setzmiana(0)
          }
        function schowajKlawiature() {
            document.getElementById(`klawiaturaContainer`).classList.toggle(`display-none`)
        }

        let history=useHistory()
    return (
        <div>

        <div style={container} >
  
        <table class="table table-dark table-striped table-hover">
  <thead>
    <tr>

      <th class="fs-4" scope="col">Numer Rachunku</th>
      <th class="fs-4" scope="col">Opis</th>
      <th class="fs-4" scope="col">Data Otwarcia</th>
      <th class="fs-4" scope="col">Czy zamknięty</th>
      <th class="fs-4" scope="col">Stolik</th>
      <th class="fs-4" scope="col">Sektor</th>
    </tr>
  </thead>
  <tbody>
   {lista.map(x => {
     return (
      <tr  onClick={() => history.push(`/szukaj/${x.DSR_ID}`)}>
      <td class="fs-5">{x.DSR_NrRachunku}</td>
      <td class="fs-5">{x.DSR_Opis}</td>
      <td class="fs-5">{`${x.DSR_DataOtwarcia}`.replace(`T`, ' ').replace(`Z`, ' ').slice(0, -8)}</td>
      <td class="fs-5">{x.DSR_StanWydruku != null ? `zamknięty` : 'otwarty'} ({x.DSR_StanWydruku})</td>
      <td class="fs-5">{x.DSR_DrugiStolik}</td>
      <td class="fs-5">{x.sektor}</td>
    </tr>
     )
   })}
   

  </tbody>
</table>
        
        </div>
       <div style={{marginBottom:'50vh'}}></div>
        <button class="btn btn-light fs-2 w-100 fixed-bottom" >
        <button type="button" onClick={() => schowajKlawiature()} class="btn btn-dark fs-3" >Klawiatura</button>
          <Link to='/kelner'><button type="button" class="btn btn-primary float-right fs-3">Powrót</button></Link>
      
      </button>

   <div class={`fixed-bottom`} style={{marginBottom:'5rem'}} id={`klawiaturaContainer`}>
   
   <input style={styleInput} onChange={(e) => setinputValue(e.target.value)} class=" fs-3 rounded bg-white text-dark" id={`wyszukajInput`}></input>
  <Keyboard
        onChange={onChange}
        onKeyPress={onKeyPress}
      />
      </div>

   

        </div>

        

        
    )
}


