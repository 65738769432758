import React from 'react'
import {useState, useEffect} from 'react'
import { useHistory } from 'react-router-dom';

export default function EkranLogowania() {
    let testdata = [{login: 'jacek@gmail.com', haslo: 'jestemjan'}]
    const [login, setlogin] = useState('')
    const [haslo, sethaslo] = useState('')
    const history = useHistory()

    // useEffect(() => {
    //     if(sessionStorage.getItem('Zalogowany') == 'true'){
    //         history.push('/')
    //     }
    // }, [])

    async function sprawdzCzyZgodneDane(){
        let res = await fetch(`https://kds.sereczyn.pl/api/ekranlogowania?login=${login}`,{method:"POST"})
        const data = await res.json()

        if(data.length != 0){
            if(data.haslo == haslo){
                localStorage.setItem('Zalogowany', 'true')
                history.push('/')
            }else{
                alert('złe hasło')
            }
        }else{
            alert('Nie ma konta z takim adresem email')
        }
    }
    
    return (
<>
        <div class="form-group">
          <label for="exampleInputEmail1" >Email address</label>
          <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" onChange={(e) => {setlogin(e.target.value)}} style={{color:'black !important'}}/>
          <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
        </div>
        <div class="form-group">
          <label for="exampleInputPassword1">Password</label>
          <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Password" onChange={(e) => {sethaslo(e.target.value)}} style={{color:'black !important'}}/>
        </div>
        <button class="btn btn-primary" onClick={() => sprawdzCzyZgodneDane()}>Submit</button>
</>
      
    )
}
