import React from 'react'

export default function KafelekNaWynosP() {
    return (
        
        <div className="zarezerwowana-godzina col-6 mb-3">
        <div className="blok-maly-zamowienie-na-zarezerwowana-godzine">
            <div className="blok-head">
                <div className="data"><span className="ikona-zadania">⏰ </span>15-03</div>
                <div className="opis-zadania"><span className="ikonka-dostawa">🚚</span>Wachowski</div>
                <div className="godzina-zamowienia">13:00</div>
            </div>
        </div>
    </div>
    )
}
