import React from 'react'

 const ContextMenu = () => {
    return (
        
        <div id="contextMenu" className="modal">
  
          <div className="modal-content">
            <div className="modal-footer">
              <div className="btn btn-dark btn-lg">Nowe</div>
              <div className="btn btn-dark btn-lg">Zwrot</div>
              <div className="btn btn-dark btn-lg">Podgrzej</div>
            </div>
          </div>
        </div>
    )
}

export default ContextMenu