import React, { useState, useEffect } from 'react'
import QrReader from 'react-qr-reader'
import fetchApi from '../api/fetchApi'
import dodajStolikDoZamowienia from '../funkcje/dodajStolikDoZamowienia'
import { useHistory } from 'react-router-dom';

export default function QrScanner() {
  const [state, setstate] = useState('Zeskanuj Klocek')
  const [pierwszyQr, setpierwszyQr] = useState([]) 
  const [drugiQr, setdrugiQr] = useState([]) 
  const [coDodaje, setcoDodaje] = useState(2)
  const [listaZamowien, setlistaZamowien] = useState([])
  let history = useHistory()

  const handleScan = async(data) => {
    
    if (data) {
      let numerek =  await fetchApi(`/kodqr?kodqr=${data.slice(-6)}`)

      if(coDodaje == 1){
        if(pierwszyQr.length == 0){
          if(numerek < 200){
            setpierwszyQr([numerek])
 
          }
        
    
        }else{
          if(numerek < 200){
          if(!pierwszyQr.includes(numerek) && !drugiQr.includes(numerek)  ){
            setpierwszyQr([...pierwszyQr,numerek])
          }
        }
        }
      }else if(coDodaje == 2){
        if(numerek > 200){
          setdrugiQr([numerek])
        }
 
      }

      setstate(numerek)
    }
  }

  async function listaZamowienQr() {
    setlistaZamowien(await fetchApi(`/listazamowienqr?idzmiany=222333213`))
  }

  useEffect(() => {
    listaZamowienQr()

    let intervalListaZamowien = setInterval(() => {
     listaZamowienQr()
   },5000)
  
   return () => {
     clearInterval(intervalListaZamowien)
   }
  }, [])

  useEffect(() => {
    if(drugiQr.length == 0 ){
      setcoDodaje(2)
    }else{
      setcoDodaje(1)
    }
  }, [drugiQr])
  

  const handleError = err => {
    console.error(err)
  }
 
  async function zatwierdz() {
   if(pierwszyQr.length == 0 ){
     alert('Nie zeskanowałeś żadnego klocka')
   }else{
    for(let i=0; i < pierwszyQr.length; i++){
      console.log(i, 'razy');
      await dodajStolikDoZamowienia(pierwszyQr[i], drugiQr) 
    }
   }
    

  }

  function usunKlocek(klocek) {
    let newArr = pierwszyQr.filter(c => c != klocek)
    setpierwszyQr(newArr) 
  }
  return (
   

    <div id="qrscanner container" >
 
             <div class=" belkaQr belkaQrTop  fixed-top belkaGoraQr row">
             {coDodaje == 2 ?  <h1 class="info-stolik">Zeskanuj Stolik</h1> : null }

<div class="col-sm-4 col-4" >
{drugiQr.map(x => {
       return <button class="btn-stolik " onClick={() => {
         setdrugiQr([])
        setpierwszyQr([])}}>{x}</button>
     })}
     </div>

<div class="col-sm-8 col-8" style={{ display:'flex',justifyContent:'space-around'}}>
{pierwszyQr.length == 0 && coDodaje == 1 ?  <h1 class={ `info-klocek`} style={{position:'absolute',top:'10%'}}>Zeskanuj Klocek</h1> : null }

      {pierwszyQr.map((x,idx) => {
         return <button class="btn-klocek" onClick={() => usunKlocek(x)}>{x}</button>
       })}
      </div>
       </div>
        <QrReader
          delay={300}
          onError={handleError}
          onScan={handleScan}
          resolution={400}
          className={'qrWindow'}
          style={{ width: '100vw', height:'100vh', border:'0px' }}
        />
        <div class="kwadracik"></div>
        <p>{state}</p>

       
        <h3 style={{color:'black'}}>{coDodaje}</h3>
        <div class="belkaQr belkaQrBottom fixed-bottom  belkaDolQr">
        <div class="qrStolikKlocek" >
 
  <button class=" btn-anuluj "  onClick={() =>  {
        setpierwszyQr([])
        setdrugiQr([])
        history.push('/listarachunkowqr')
        }}>
    Anuluj
  </button>
 
  <button class="btn-zatwierdz" onClick={() => {
                zatwierdz()
                if(pierwszyQr.length != 0 ){
                  setpierwszyQr([])
                  setdrugiQr([])
                }
           
              }}>
    Zatwierdź
  </button>
  
</div>

        </div>
      


<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-fullscreen w-100">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-dark" id="staticBackdropLabel">Zamowienia</h5>
      </div>
      <div class="modal-body">
      <ul class="list-group">
        {listaZamowien.map(x => {
          return           <li class="list-group-item fs-3 fw-normal">{x.DSR_Opis} <span class="float-right text-dark fs-3 fw-normal">{x.sektor} {x.DSR_DrugiStolik}</span></li>
        })}

         
      </ul>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-lg" data-bs-dismiss="modal">Skanuj</button>
      </div>
    </div>
  </div>
</div>

       
      </div>
  )
}
 

