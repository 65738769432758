import React from 'react'

export default function KafelekNaTerazP() {
    return (
        
     
        <div className="col-sm-6 col-6">
       
          <div className="maly__box box--yellow rek">KAWA, A MOŻE NIE</div>
        </div>
  
    )
}
