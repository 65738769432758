//All
async function ostatnioOdklikane(ekran){     
const res = await fetch(`https://kds.sereczyn.pl/api/odklikaneostatnio${ekran}?host=${JSON.parse(localStorage.getItem('HOST')).STA_ID}`,{method:"POST"})
const data = await res.json()
return data }

async function getStanowiska  (){
const res = await fetch("https://kds.sereczyn.pl/api/getstanowiska",{method:"POST"})
const data = await res.json()
return data
}

async function wszystkieZamowienia(){     
    const res = await fetch(`https://kds.sereczyn.pl/api/wszystkiezam`,{method:"POST"})
    const data = await res.json()
    return data 
}

//Wiata
async function sortWiataNaTeraz(){     
    const res = await fetch(`https://kds.sereczyn.pl/api/wiatanaterazsort`,{method:"POST"})
    const data = await res.json()
    return data 
}


  export default {
      ostatnioOdklikane,
       getStanowiska,
       sortWiataNaTeraz, wszystkieZamowienia

  }