import React from 'react'
import KafelekPotrawyKucharza from '../components/kuchnia/KafelekPotrawyKucharza'
import KafelekRachunki from '../components/kucharzZamowienia/KafelekRachunki'
import Navbar from '../components/kuchnia/Navbar'
import OpisBox from '../components/zamowienie/OpisBox'
import PieceBox from '../components/kuchnia/PieceBox'
import {useEffect ,useState} from 'react'
import KafelekSidebar from '../components/wszystkie-zamowienia/KafelekSidebar'
import PrzyciskiDSR from '../components/kuchnia/PrzyciskiDSR'
import PrzyciskiDrukujNapoje from '../components/zamowienie/PrzyciskiDrukujNapoje'
import KafelekInformacyjny from '../components/zamowienie/KafelekInformacyjny'
import PrzyciskWszystkieRachunki from '../components/zamowienie/PrzyciskWszystkieRachunki'
import { Link } from 'react-router-dom'
import JedenRachunek from '../components/zamowienie/JedenRachunek'
import { useHistory } from 'react-router-dom';
import {useContext} from 'react'
import {DsrPrzyciskiKuchniaContext} from  '../DsrPrzyciskiKuchniaContext'
import {KoszykKucharzContext} from '../KoszykKucharzContext'
import {QueryKoszykContext} from '../QueryKoszykContext'
import { useLocation } from 'react-router'
import BelkaLewa from '../components/all/BelkaLewa'
import {ZieloneRachunkiContext} from  '../ZieloneRachunkiContext'
import { Time } from 'mssql'
import Timer from '../components/kucharzZamowienia/Timer'
import sortowanieDsrPrzyciski from '../funkcje/sortowanieDsrPrzyciski'
import dodajTimerZamowieniom from '../funkcje/dodajTimerZamowieniom'


export default function Kuchnia({match}) {


  const {zieloneRachunki,setZieloneRachunki} = useContext(ZieloneRachunkiContext)

  const location = useLocation()
  let czasWydruku = new URLSearchParams(location.search).get("czas")
  const [wartoscTimera, setwartoscTimera] = useState(0)

  let czas = czasWydruku

    var hms = czas    // your input string
    var a = hms.split(':'); // split it at the colons

    // Hours are worth 60 minutes.
    var minutes = (+a[0]) * 60 + (+a[1]);

   czas = minutes

  let history = useHistory();
 
  const [zamowieniaDlaKucharza, setzamowieniaDlaKucharza] = useState([])
  const [update, setUpdate] = useState(0)
  const {dsrBtn} = useContext(DsrPrzyciskiKuchniaContext)
  const [query, setquery] = useState('')
  const [queryDoPolek, setQueryDoPolek] = useState('')
  // const {koszykKucharz, setKoszykKucharz} = useContext(KoszykKucharzContext)
  // const {queryKoszyk, setQueryKoszyk} = useContext(QueryKoszykContext)

  const [odklikane, setOdklikane] = useState([])
  const [czasZam, setczasZam] = useState([])
  const [czasZamMin, setczasZamMin] = useState([])
  //OpisDSR State
  const[dsrOpisy, setDsrOpisy] = useState([])


  function queryZDSLID(zamowienia) {
  
  
    let query = ``
    zamowienia.map(zamowienie => {
      query = `${query}, ${zamowienie.DSL_ID}`
    })

    return query.substring(1).replace(/\s/g, "");
  }

  let array = []
  useEffect(()=>{

    async function odklikane  (){
      const res = await fetch(`https://kds.sereczyn.pl/api/odklikanekucharz`,{method:"POST"})
      const data = await res.json()
      return data
    }

    const getTasks = async () =>{
        const zamowieniaFromServer = await fetchZamowienia()
        const odk = await odklikane()
      
        setOdklikane(odk)
        let pozycjeDlaKucharza = []

      zamowieniaFromServer.map(x => {
        if(x.ART_KatWydruku == 1 || x.ART_KatWydruku == 4 || x.ART_KatWydruku == 7){
          pozycjeDlaKucharza = [...pozycjeDlaKucharza, x]
        }
      })
      console.log(pozycjeDlaKucharza);

        pozycjeDlaKucharza.map(zamowienie => {
          if(zamowienie.KDL_Polka == null && zamowienie.KDL_DataKucharz !== null ) {
            array.push(zamowienie)
          }
        })

        setQueryDoPolek(queryZDSLID(array));

        setczasZam(zamowieniaFromServer.find(x => x.czas != null).czas)
              setczasZamMin(zamowieniaFromServer.find(x => x.czas != null).czas_w_minutach)

        let pozycjeSort = pozycjeDlaKucharza.sort((a,b) => (a.ART_CzasProdukcjiGodzin < b.ART_CzasProdukcjiGodzin) ? 1 : ((b.ART_CzasProdukcjiGodzin < a.ART_CzasProdukcjiGodzin) ? -1 : 0))

        // setzamowieniaDlaKucharza(pozycjeSort)
        // setquery(queryZDSLID(pozycjeDlaKucharza))
        
       
      array = []
      pozycjeDlaKucharza = []
  
    }

     getTasks()
     let zapytajBaze = setInterval(()=>getTasks(),500)

     return () =>{
         clearInterval(zapytajBaze)
     }
 },[])

 useEffect(()=>{
  const getTasks = async () =>{
      const zamowieniaFromServer = await fetchZamowienia()
      let pozycjeDlaKucharza = []

      zamowieniaFromServer.map(x => {
        if(x.ART_KatWydruku == 1 || x.ART_KatWydruku == 4 || x.ART_KatWydruku == 7){
          pozycjeDlaKucharza = [...pozycjeDlaKucharza, x]
        }
      })
     
      let pozycjeSort = pozycjeDlaKucharza.sort((a,b) => (a.ART_CzasProdukcjiGodzin < b.ART_CzasProdukcjiGodzin) ? 1 : ((b.ART_CzasProdukcjiGodzin < a.ART_CzasProdukcjiGodzin) ? -1 : 0))

      
 //OpisDSR 
 let opisyZDsrow = []
 let rachunki = zamowieniaFromServer
 rachunki.map(x => {
   if(!opisyZDsrow.includes(x.DSR_OpisDostawy)){
     opisyZDsrow.push(x.DSR_OpisDostawy)
   }
 })

 setDsrOpisy(opisyZDsrow)


      setzamowieniaDlaKucharza(pozycjeSort)
  }
   getTasks()
},[update])
 

//Funkcjia do dodawania dsrow do koszyka
// const dodajDoKoszyka = () => {
//   let arr = queryDoPolek.split(',')
//   arr =  arr.filter(x => !koszykKucharz.includes(x))
//   let allquery = query.split(',')
//   allquery = allquery.filter(x => !queryKoszyk.includes(x)) 
 
//   if(!arr == '' || !allquery == ''){
//     setKoszykKucharz([...koszykKucharz, ...arr])
//     setQueryKoszyk([...queryKoszyk, ...allquery])
//   } 
 

//   console.log(koszykKucharz);
// }


// const wstawKoszykDoPieca = () => {
//   let doPolekDSLKoszyk = koszykKucharz.join(',')
//   let AllQueryKoszyk = queryKoszyk.join(',')

//   console.log(doPolekDSLKoszyk, 'do polek', AllQueryKoszyk, 'all query');
//   setTimeout(() => {setKoszykKucharz([])
//   setQueryKoszyk([])
//   },700)

//     history.push(`/piece/${match.params.id}?query=${AllQueryKoszyk}&dopolek=${doPolekDSLKoszyk}&koszyk=true`)


// }

 // Fetch Data (Różne Zamóienia)
 const fetchZamowienia = async()=>{
     await fetch("https://kds.sereczyn.pl/api/rachunki",{method:"POST"})
     const res = await fetch(`https://kds.sereczyn.pl/api/rachunkiKucharz/${match.params.dsr}`,{method:"POST"})
     const data = await res.json()
     return data
 }
  
 //Policzenie DSL_Ilos, segregacja zamowien
 let posegregowaneZamowieniaKucharza = {}

 zamowieniaDlaKucharza.map(zamowienie => {
  if(zamowienie.ART_KatWydruku == 1 || zamowienie.ART_KatWydruku == 4 || zamowienie.ART_KatWydruku == 7){
    if(zamowienie.KDL_DataKucharz != null){
      if(`${zamowienie.ART_Nazwa} ` in posegregowaneZamowieniaKucharza){
        let nazwa =  `${zamowienie.ART_Nazwa} `
        posegregowaneZamowieniaKucharza[nazwa].DSL_Ilosc =  Number(posegregowaneZamowieniaKucharza[nazwa].DSL_Ilosc) + Number(zamowienie.DSL_Ilosc)
        posegregowaneZamowieniaKucharza[nazwa].DSL_ID =  `${posegregowaneZamowieniaKucharza[nazwa].DSL_ID} , ${zamowienie.DSL_ID}` 
     }else{
      let nazwa =  `${zamowienie.ART_Nazwa} `
      Object.assign(posegregowaneZamowieniaKucharza, {[nazwa]:{ART_Nazwa: nazwa, DSL_Ilosc:Number(zamowienie.DSL_Ilosc), ART_KatWydruku: zamowienie.ART_KatWydruku, DSL_ID: zamowienie.DSL_ID, KDL_DataKucharz: zamowienie.KDL_DataKucharz, operator: zamowienie.kucharzOpr } });
    }
     
     
    
    }else{
      if(zamowienie.ART_Nazwa in posegregowaneZamowieniaKucharza){
        let nazwa =  zamowienie.ART_Nazwa
        posegregowaneZamowieniaKucharza[nazwa].DSL_Ilosc =  Number(posegregowaneZamowieniaKucharza[nazwa].DSL_Ilosc) + Number(zamowienie.DSL_Ilosc)
        posegregowaneZamowieniaKucharza[nazwa].DSL_ID =  `${posegregowaneZamowieniaKucharza[nazwa].DSL_ID} , ${zamowienie.DSL_ID}` 
     }else{
         let nazwa =  zamowienie.ART_Nazwa
         Object.assign(posegregowaneZamowieniaKucharza, {[nazwa]:{ART_Nazwa: nazwa, DSL_Ilosc:Number(zamowienie.DSL_Ilosc), ART_KatWydruku: zamowienie.ART_KatWydruku, DSL_ID: zamowienie.DSL_ID, KDL_DataKucharz: zamowienie.KDL_DataKucharz, operator: zamowienie.kucharzOpr } });
    }
    }
    
   }
  }
)
 posegregowaneZamowieniaKucharza = Object.entries(posegregowaneZamowieniaKucharza);


 
 //Odklikaj wszystkie pozycje w kafelku
function odklikajPozycje(query, status){
  setUpdate(update + 1)
  if(update > 9999){
    setUpdate(0)
  }
  const fetchZam = (q,s)=>{
    if(JSON.parse(localStorage.getItem('HOST')).STA_ID != null){
      fetch(`https://kds.sereczyn.pl/api/odklikajPozycje?query=${q}&status=${s}&ekran=Kucharz&operator=${(JSON.parse(sessionStorage.getItem('OPR_KUCHARZ'))).id}&host=${JSON.parse(localStorage.getItem('HOST')).STA_ID}`,{method:"POST"})

    }
}
  fetchZam(query, status)

}
 //Segregowanie przycisków
 var dsrPrzyciski = dsrBtn.reduce(function(dsrPrzyciski, org) {
  (dsrPrzyciski[org.DSR_ID] = dsrPrzyciski[org.DSR_ID] || []).push(org);
  return dsrPrzyciski;
}, {})

dsrPrzyciski = Object.entries(dsrPrzyciski);
dsrPrzyciski =sortowanieDsrPrzyciski(dsrPrzyciski)
//Timer
let czyTimer = []
let arrTimerow = []

zamowieniaDlaKucharza.map(x => {
  if(czyTimer.includes(x.Timer)){
    
  }else{
    czyTimer.push(x.Timer)
    arrTimerow.push({Timer:x.Timer, oldT: x.KDL_Timer})

  }
})
;

  return (
        <div class="computer-version" style={{marginTop:'2rem'}}>
            {/* {NAVBAR} */}
            <div className="fixed-top">
      <div id="computer-nav" className={`navbar px-4 ${ czasZamMin > 40 ? 'box--red' : ''}  ${ czasZamMin >=0 && czasZamMin <= 15 ? 'box--gray' : '' }${czasZamMin > 15 && czasZamMin <=25 ? 'box--green' : '' }${czasZamMin > 25 && czasZamMin <=40 ? 'box--yellow' : '' }`} style={{fontSize:"1.5rem !important"}}>
        <div className="navbar__lokalizacja-i-status ">
          <span className="navbar__lokalizacja-i-status__ikona icon-sm">🍕</span>
          <span className="navbar__lokalizacja-i-status__stolik">{match.params.id}</span>
          <span className="navbar__lokalizacja-i-status__ilosc-zamowien">{`     (${dsrPrzyciski.length})`}</span>
          <span className="navbar__lokalizacja-i-status__stolik">{zamowieniaDlaKucharza.find(x => x.DSR_Telefon != '') != undefined ? zamowieniaDlaKucharza.find(x => x.DSR_Telefon != '').DSR_Telefon : null}</span>

          {/* <span className="navbar__lokalizacja-i-status__status">VIP</span> */}
          {/* <span className="navbar__lokalizacja-i-status__lokalizacja">Rybakówka</span> */}
        </div>
      
        <div className="navbar__czas-zamowienia">
          <span className="navbar__czas-zamowienia__timer">{czasZam}</span>
        </div>
      </div>
    </div>
            {/* {NAVBAR} */}

            <div class="row container">
            <div class="col-xl-6 col-lg-6 com-md-6 col-sm-6">
            
            <div id="potrawy-kucharz" class="mt-5">
          <div class="vl"></div>
     
          <div class="row mt-5">
            <div class="col-xl-6 col-lg-6 com-md-6 col-sm-6 ">
            {posegregowaneZamowieniaKucharza.map(x =>{
                return  <KafelekPotrawyKucharza  informacja={x[1].DSL_InformacjeDoKuchni} operator={x[1].operator} ilosc={x[1].DSL_Ilosc} nazwa={x[1].ART_Nazwa} onClick={odklikajPozycje} kolor={x[1].KDL_DataKucharz != null ? 'box--gray' : 'box--red'} query={x[1].DSL_ID}/>
              })}
            </div>
           
            <div class="col-xl-6 col-lg-6 com-md-6 col-sm-6">
              {zamowieniaDlaKucharza.map(x =>{
    
                return  <KafelekPotrawyKucharza  operator={x.kucharzOpr} urzadzenie={x.ART_DopuszczalnyCzasSkladowania} polka={x.KDL_Polka} ilosc={x.DSL_Ilosc} nazwa={x.ART_Nazwa} onClick={odklikajPozycje} kolor={x.KDL_DataKucharz != null ? 'box--gray' : 'box--red'} query={x.DSL_ID} />
              })}
        
     
            </div>
          </div>
        </div>

        <div class="fixed-bottom " style={{marginBottom:'5.5rem'}}>
        <div id="rachunki">
        {odklikane.map(x => {
             
             return   <div class={`rachunki-box ${x.Timer > -1 ? 'box--red' : 'box--green'}`}  onClick={() => {
              history.push(`/kuchzam`)
              setTimeout(()=> history.push(`/kuchzam/${x.DSR_Opis}?czas=${x.czas}`),10)
              
              }}><span style={{fontSize:"2.5rem"}}>{x.DSR_Opis}<br/>{x.Timer > -1 ? '0:' + Math.abs(x.Timer) : '-0:' + Math.abs(x.Timer)}</span> </div>
         })}
        </div>
        </div>

            </div>
            <div class="col-xl-3 col-lg-3 com-md-3 col-sm-3 mt-5">
                
            {dsrOpisy.map(x => { 
            if(x != ''){
              return   <OpisBox opis={x} />
            }
          
           })}

        <PieceBox stolik={match.params.id}/>
        {/* <PrzyciskiDSR/> */}
        
   
        
        {/* <h4 style={{marginLeft:'50%', transform:'translateX(-50%)'}}>W PIECU</h4>
        {zamowieniaDlaKucharza.map(x => {
          if(x.KDL_Polka != null){
            return <button className={`btn btn-lg btn-success`} style={{width:'50%'}}>{x.ART_Nazwa}</button>
          }
        
       
        })} */}
     {/* <button className={`btn btn-lg btn-primary`} style={{width:'100%'}} onClick={() => dodajDoKoszyka()}>Dodaj do koszyka</button>
     {koszykKucharz.map(x => {
         
         
         if(x === ""){}else{
            return<button className={`btn btn-lg btn-warning`} onClick={(c) => {
              let nowyKoszyk = koszykKucharz.filter(c => c != x)
              setKoszykKucharz(nowyKoszyk)
            }}> {x} </button>
          }

        })}
       
        <button className={`btn btn-lg btn-primary`} style={{width:'100%'}} onClick={() => wstawKoszykDoPieca()}>Wstaw koszyk do pieca</button> */}

     {/* Timer */}

     <div id='timerKuch' style={{display:'flex', justifyContent:'space-between', marginTop:'1rem'}}>
{/*         
        {arrTimerow.map((x,idx )=> {
           
           if(x.Timer == null){

           }else{
            return  <Timer timer={x.Timer} oldTimer={x.oldT} zamowienia={zamowieniaDlaKucharza}/>
           
           }
          
        })} */}

{/* <div id='timerKuch' style={{display:'flex', justifyContent:'space-between', marginTop:'1rem'}}>
        
       
        <button class={'btn btn-warning btn- lg'} onClick={() => setwartoscTimera(Math.abs(wartoscTimera - 1))}> - </button>
        <button class={'btn btn-warning btn- lg'} onClick={() => dodajTimerZamowieniom (wartoscTimera, zamowieniaDlaKucharza)}> {wartoscTimera} </button>
        <button class={'btn btn-warning btn- lg'} onClick={() => setwartoscTimera(Math.abs(wartoscTimera + 1))}> + </button>
        
  
     </div> */}

     
        </div>
            </div>

        

    

            <div className="middle-content-right col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 mt-4">
        <div className="sidebar-right">
  
    
          <div className="sidebar-right__kafelki-informacyjne">


          </div>
       
          <div className="sidebar-right__przyciski-wydawka">
       {/* <PrzyciskWydawka/> */}
        </div>


  
        <div className="sidebar-right__rachunki" style={{marginBottom:"-4.5rem"}}>
        {dsrPrzyciski.map(x =>{
           return <Link to={`/kuchzam/${match.params.id}?czas=${czasWydruku}`}> <JedenRachunek rachunekNumerek={x[1][0].DSR_NrRachunku} onClick={()=> 
            setTimeout(()=> history.push(`/kuchzam/${match.params.id}/${x[0]}?czas=${czasWydruku}`),10)
          }/></Link>
          })}
          

         <Link to={`/kuchzam/${match.params.id}?czas=${czasWydruku}`}> <PrzyciskWszystkieRachunki/></Link>
            
          </div>
        </div>
        <div className="sidebar-right__zmiana-zamowienia">
        <div style={{maxHeight: "7.9rem", overflow:"scroll", display:"flex", justifyContent:"space-between", maxWidth:"18.5vh", marginBottom:"-1.5rem"}}>
          {/* <PrzyciskZamianaZamowienia/>
          <PrzyciskZamianaZamowienia/>
          <PrzyciskZamianaZamowienia/>
          <PrzyciskZamianaZamowienia/>
          <PrzyciskZamianaZamowienia/>
          <PrzyciskZamianaZamowienia/> */}
        </div>
         {/* <PrzyciskZmianaZamowieniaWiecej/> */}
        </div>

      </div>
            </div>

            {/* BELKA */}
        <div className="fixed-bottom">
          <link rel="stylesheet" href="./sass/main.css"></link>
        <div className="belka">
  
        <BelkaLewa/>
  
          <div className="belka__srodek">
          {/* <Link to="/napoje"> <span>Napoje</span> </Link> */}
          </div>
  
          <div className="belka__prawa">
          {/* <Link to="/raport"><span>Raport</span></Link>
          <Link to="/imprezy"><span>Imprezy</span></Link> */}
          <Link to="/kuchzam"><div className="btn btn btn-outline-light btn-lg" style={{padding:'1rem 1.5rem 1rem' }}>Gotowe</div></Link>
          </div>
        </div>
      </div>
      {/* BELKA */}
        </div>
    )
}
