import React from 'react'

export default function KafelekReklamacjiP() {
    return (
        <div className="blok-side-bar col-6 mb-3 ">
                        <div className="reklamacja">
                            <div className="blok-maly-reklamacja">
                                <div className="blok-head red">
                                    <div className="lewa">
                                        <div className="informacja-reklamacja">Reklamacja</div>
                                        <div className="stolik">273</div>
                                    </div>
                                    <div className="prawa">
                                        <div className="czas-zamowienia">0:10</div>
                                        <div className="timer-kucharza">0:15</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    )
}
