import React from 'react'

export default function KafelekPotrawyKucharza(props) {
    return (
        <div class={`maly__box ${props.kolor}`} onClick={(e) => {
           
            if(e.target.classList.contains('box--gray')){
                props.onClick(props.query, 'checked')
                e.target.classList.remove('box--gray')
                e.target.classList.add('box--red')
            }else{
                props.onClick(props.query, 'unchecked')
                e.target.classList.add('box--gray')
            }

        }}>{`${props.ilosc}x ${props.nazwa} ${props.polka} ${props.timer}`} <br/> <span class="fs-5">  {`${props.operator}`}</span></div>
    )
}

// {`${props.urzadzenie}`}