import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import NumPad from '../components/logowanie/NumPad'
import { useLocation, useHistory } from 'react-router'
import {useRef} from 'react'

export default function Login() {


    const history = useHistory()
    const location = useLocation()
    let ekran = new URLSearchParams(location.search).get("ekran")
    let stolik = new URLSearchParams(location.search).get("stolik")
    let czas = new URLSearchParams(location.search).get("czas")
    const infoRef = useRef(null)
    const passRef = useRef(null)


    


    const goToPreviousPath = () => {
        history.push('/kelner')
    }

    const sprawdzPin = async() => {
       console.log(ekran);
       let pin = document.getElementById('numpad-input').value
        const res = await fetch(`https://kds.sereczyn.pl/api/logowanie?pin=${pin}`,{method:"POST"})
        const data = await res.json()
        console.log(data);

        if(data[0].length > 0){
            if(ekran == 'kucharz'){
                console.log(data[0][0]);
                        sessionStorage.setItem('OPR_KUCHARZ', JSON. stringify({id: data[0][0].OPR_ID,
                        imie: data[0][0].OPR_Imie,
                        nazwisko: data[0][0].OPR_Nazwisko
                        }));

              
                        setTimeout(() => {
                            // sessionStorage.removeItem('OPR_KUCHARZ')
                        }, 40000)
                history.push('/kuchzam')
               
            }else if (ekran == 'kelner'){
                console.log(data[0][0]);
                sessionStorage.setItem('OPR_KELNER', JSON. stringify({id: data[0][0].OPR_ID,
                imie: data[0][0].OPR_Imie,
                nazwisko: data[0][0].OPR_Nazwisko
                }));

             
             
        history.push(`/zam/${stolik}`)
            }else if(ekran == 'wydawka'){
                console.log(data[0][0]);
                sessionStorage.setItem('OPR_WYDAWKA', JSON. stringify({id: data[0][0].OPR_ID,
                imie: data[0][0].OPR_Imie,
                nazwisko: data[0][0].OPR_Nazwisko
                }));

        

        history.push(`/wydawka`)
            }else if (ekran == 'wiata'){
                console.log(data[0][0]);
                sessionStorage.setItem('OPR_KELNER', JSON. stringify({id: data[0][0].OPR_ID,
                imie: data[0][0].OPR_Imie,
                nazwisko: data[0][0].OPR_Nazwisko
                }));

             
             
        history.push(`/zamwiata/${stolik}`)
            }
        }
        
        else{
            
            infoRef.current.innerText = "Nieprawidłowy Pin"
            passRef.current.value = ""
         

        }

     
        
    }

    useEffect(() => {
        document.getElementById('numpad-input').focus()

        let focus =  setInterval(() => {
             document.getElementById('numpad-input').focus()
         },300)
         
         let nieprawidlowyPinTimeout = setInterval(() => {
            infoRef.current.innerText = ""
        },5000)

        document.addEventListener("keydown", keyDownTextField, true);

        function keyDownTextField(e) {
        var keyCode = e.keyCode;
        if(keyCode==13) {
           let submit = document.querySelector('.submit')
            if(submit != null){
             submit.click()
            }else{
                alert('Nie używaj bransoletki bez ekranu logowania')
            }
        } 
        }


        document.querySelectorAll('.button').forEach(e => {
            e.addEventListener("click", (c) => {
               if(c.target.innerText == "→"){

               }else if(c.target.innerText == "←"){
                document.getElementById('numpad-input').value = document.getElementById('numpad-input').value.slice(0,-1)
              
                }else{
                document.getElementById('numpad-input').value = document.getElementById('numpad-input').value + c.target.innerText
               }
               
            } ) 
        })
         return () => {
            document.removeEventListener('keydown',keyDownTextField, true )
             clearInterval(focus)
             clearInterval(nieprawidlowyPinTimeout)
            

         }
     }, [])

   

    return (
        <>

        
    <link rel="stylesheet" href="logowanie.css"></link>
   




    <button type="button" class="btn btn-outline-light btn-lg" style={{opacity:'.6 important', position:'fixed'}} onClick={goToPreviousPath}>Powrót</button>
         <div class="container-fluid d-flex no-block justify-content-center align-items-center">
         <div class="ramka-bransoletka" style={{position:'relative',top:'6rem',width:'25%', height:'55%', display:'flex',alignContent:'center', justifyContent:'center', alignItems:'center'}}>
         <h3 style={{textAlign:'center'}} id='info-bransoletka'>PRZYŁÓŻ BRANSOLETKĘ <br></br> DO CZYTNIKA </h3>
         
         </div>
       
 
        <div class="d-flex flex-column justify-content-center align-items-center" >
    
            <img src="https://sereczyn.pl/wp-content/uploads/2018/11/logo_brown.png?id=835" alt="" className="filter-white" style={{width: "200px"}}/>
            <div class="auth-box d-flex justify-content-center align-items-center" style={{background:"transparent"}}>

            <>
        
           <link rel="stylesheet" href="./sass/pages/NumPad.css" />
     
        <div class="buttons">
    
        <input type="password" ref={passRef} id="numpad-input" class="input-numpad" ></input>
 
        <div class="button">1</div>
        <div class="button">2</div>
        <div class="button">3</div>
        <div class="button">4</div>
        <div class="button">5</div>
        <div class="button">6</div>
        <div class="button">7</div>
        <div class="button">8</div>
        <div class="button">9</div>
        <div class="button">←</div>
        <div class="button">0</div>
        <div class="button submit " onClick={() => sprawdzPin()}>→</div>
        <div ref={infoRef} style={{marginTop:'5rem', fontSize:'2rem', opacity:'.8', position:'absolute', bottom:'6rem'}}></div>
      </div>
     
      </>
            
     
            </div>
        </div>



</div>
        </>
    )
}
