import React from 'react'
import {useState, useEffect} from 'react'
import { Link, useHistory } from 'react-router-dom'
import fetchApi from '../api/fetchApi'
import { useLocation } from 'react-router'


//Funkcje

async function dodajPolke(DSR_Opis, polka, grupa, dsl) {

    if(dsl){
        // alert(`/zmienpolke?dsl=${dsl}&polka=${polka}`);
        await fetchApi(`/zmienpolke?dsl=${dsl}&polka=${polka}`)
    }else{
        await fetchApi(`/dodajPolke?DSR_Opis=${DSR_Opis}&polka=${polka}&grupa=${grupa}`)
    }

}

async function dodajTimer(DSR_Opis) {
    await fetchApi(`/dodajtimernaplusie?DSR_Opis=${DSR_Opis}`)
}

// Magazyn
function MagazynPolkaComponent({id,pozycje,zamowienieOpis, fetch}) {
    const location = useLocation()
    let koszyk = new URLSearchParams(location.search).get("koszyk")
    let dsl = new URLSearchParams(location.search).get("dsl")

    
 return  <div class="polka__kafelek box--gray"  onClick={() => {
    if(dsl){
        console.log(zamowienieOpis, id, 1,dsl);
         dodajPolke(zamowienieOpis, id, 1,dsl) 
       }
       fetch()
    }

    }>
       {pozycje.map(x => {
           if(x.KDL_Polka == id) return<Link to={`/piece/${zamowienieOpis}/${id}`}><div >{x.DSR_Opis} </div></Link> 
       })}
   </div>
}

function MagazynComponent({id, pozycje, zamowienieOpis, fetch, nazwa}) {
    return  <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 polki">

    <div class="polka">
    <h4>{nazwa}</h4>
     <MagazynPolkaComponent id={`${id}1`} pozycje={pozycje}  fetch={fetch} zamowienieOpis={zamowienieOpis}/>
     <MagazynPolkaComponent id={`${id}2`} pozycje={pozycje}  fetch={fetch}  zamowienieOpis={zamowienieOpis}/>
     <MagazynPolkaComponent id={`${id}3`} pozycje={pozycje}  fetch={fetch} zamowienieOpis={zamowienieOpis}/>
     <MagazynPolkaComponent id={`${id}4`} pozycje={pozycje}  fetch={fetch} zamowienieOpis={zamowienieOpis}/>
     <MagazynPolkaComponent id={`${id}5`} pozycje={pozycje}  fetch={fetch} zamowienieOpis={zamowienieOpis}/>
     <MagazynPolkaComponent id={`${id}6`} pozycje={pozycje}  fetch={fetch} zamowienieOpis={zamowienieOpis}/>
     <MagazynPolkaComponent id={`${id}7`} pozycje={pozycje}  fetch={fetch} zamowienieOpis={zamowienieOpis}/>
     <MagazynPolkaComponent id={`${id}8`} pozycje={pozycje}  fetch={fetch} zamowienieOpis={zamowienieOpis}/>
     <MagazynPolkaComponent id={`${id}9`} pozycje={pozycje}  fetch={fetch} zamowienieOpis={zamowienieOpis}/>
    </div>
</div>
}
// Magazyn


// PIECE
function PiecePozycjaComponent({nazwaZamowienia, timer}) {
    return <div class={`srodek-kafelek ${timer < 0 ? `box--yellow` : `box--red` } srodek1`}> <span>{nazwaZamowienia}</span>
    <p class="fw-bold">{timer}</p>
</div>
}

function PiecComponent({id, piecId, pozycje, zamowienieOpis, fetch}) {
    return  <div class="piec piec-1">
    <div class="nazwa">Piec {piecId}</div>
        
        <PolkaWPiecuComponent fetch={fetch} id={`${id}1`} zamowienieOpis={zamowienieOpis} pozycje={pozycje}/>
        <PolkaWPiecuComponent fetch={fetch} id={`${id}2`} zamowienieOpis={zamowienieOpis} pozycje={pozycje}/>
        <PolkaWPiecuComponent fetch={fetch} id={`${id}3`} zamowienieOpis={zamowienieOpis} pozycje={pozycje}/>
        <PolkaWPiecuComponent fetch={fetch} id={`${id}4`} zamowienieOpis={zamowienieOpis} pozycje={pozycje}/>
        <PolkaWPiecuComponent fetch={fetch} id={`${id}5`} zamowienieOpis={zamowienieOpis} pozycje={pozycje}/>
   
</div>
}

function PolkaWPiecuComponent({id,pozycje, zamowienieOpis,fetch}) {
    const location = useLocation()
    let dsl = new URLSearchParams(location.search).get("dsl")
    let koszyk = new URLSearchParams(location.search).get("koszyk")
        
   
   return    <div class="polka-piec ">
    <div class="row">

        <div onClick={() => {
             if(koszyk == 'true'){
                // alert('dodano Koszyk')
                 fetchApi(`/dodajKoszyk?polka=${id}`)
                 fetch()
             }else{
    
                dsl ? dodajPolke(zamowienieOpis, id, 1,dsl) :  dodajPolke(zamowienieOpis, id, 1); dodajTimer(zamowienieOpis)}
                fetch()
             }
  
             
             } class="col-xl-2 col-lg-2 col-md-2 plus">+</div>
        <div class="srodek-ulozenie1 col-xl-8 col-lg-8 col-md-8 polkaRow">
           
            {pozycje.map(x => {
               if(x.KDL_Polka == id) return <Link to={`/piece/${zamowienieOpis}/${id}`}><PiecePozycjaComponent nazwaZamowienia ={x.DSR_Opis} timer={x.KDL_Timer}/></Link>
            })}
    
        </div>

        <Link to={`/piece/${zamowienieOpis}/${id}`} class="col-xl-2 col-lg-2 col-md-2 timer-wlacz">⏱</Link>
    </div>
</div>
}
// PIECE



// PATELNIE
function PatelniaPozycjaComponent({nazwaZamowienia, timer, idx}) {
    return  <div class={`kafelek kafelek-1 kafelek-${idx} ${timer < 0 ? `box--yellow` : `box--red` } `}><span>{nazwaZamowienia}</span>
    <p  class="fw-bold">{timer}</p>
    </div>
}

function PatelniaPolkaComponent({id,pozycje, zamowienieOpis,fetch}) {
    const location = useLocation()
    let dsl = new URLSearchParams(location.search).get("dsl")
    let koszyk = new URLSearchParams(location.search).get("koszyk")


    let idx = 0
    return  <div class="p1 ">
        <div class={`ulozenie4 `}>
      
        {pozycje.map((x) => {
               
               if(x.KDL_Polka == id) {
                idx <=  4  ? idx++ : idx = 0   
                return <Link to={`/piece/${zamowienieOpis}/${id}`} ><PatelniaPozycjaComponent nazwaZamowienia={x.DSR_Opis} idx={idx} timer={x.KDL_Timer}/></Link>}
            })}
       
        <div class="przyciski-patelnie">
            <Link to={`/piece/${zamowienieOpis}/${id}`}  class="minus">⏱</Link>
            <div class="plus" onClick={() => 
                {
                    if(koszyk ==  'true'){
                        // alert('dodano Koszyk')
                        fetchApi(`/dodajKoszyk?polka=${id}`)
                        fetch()
                    }else{      
                      
                dsl ? dodajPolke(zamowienieOpis, id, 2,dsl) :  dodajPolke(zamowienieOpis, id, 2)
                dodajTimer(zamowienieOpis)
                fetch()
                    }
                }
                } >+</div>
        </div>
    </div>
</div>
}

function PatelniaComponent({id, patelniaId, pozycje, zamowienieOpis,fetch}) {
   
   
   return  <div class="patelnia patelnia-1">
    <p>Patelnia {patelniaId}</p>
   
   <PatelniaPolkaComponent fetch={fetch} id={`${id}1`} zamowienieOpis={zamowienieOpis} pozycje={pozycje} />
   <PatelniaPolkaComponent fetch={fetch} id={`${id}2`} zamowienieOpis={zamowienieOpis} pozycje={pozycje}/>
   <PatelniaPolkaComponent fetch={fetch} id={`${id}3`} zamowienieOpis={zamowienieOpis} pozycje={pozycje}/>
   <PatelniaPolkaComponent fetch={fetch} id={`${id}4`} zamowienieOpis={zamowienieOpis} pozycje={pozycje}/>
 
</div>
}

// PATELNIE

function NavbarComponent({zamowienieOpis, piece, patelnie}) {
    const location = useLocation()
    let dsl = new URLSearchParams(location.search).get("dsl")
    let koszyk = new URLSearchParams(location.search).get("koszyk")

   return <div class="fixed-top">
    <div id="computer-nav" class="navbar px-4 box--green">
        <div class="navbar__lokalizacja-i-status ">
        {zamowienieOpis}
        </div>
        {dsl && <h3>Zmieniasz Półkę</h3>  }  
      {!dsl && <h4>Do piecy: {piece}</h4>  }  
      {!dsl && <h4>Do patelni: {patelnie}</h4> }  
    </div>
</div>
}

export default function PieceNowe({match}) {
    
    let intervalPiece
    const [zamowienia, setzamowienia] = useState([])
    let history = useHistory()

    async function nowyFetch() {
        setzamowienia(await fetchApi('/zamowieniaPolki'))
    }

    //Remove el function
    const removeElements = (elms) => elms.forEach(el => el.remove());

    //ILE ZAMÓWIEŃ
    const [ileZamowienDoPiecy, setileZamowienDoPiecy] = useState([])
    let navinterval 
    
    useEffect(async() => {
    
     setTimeout(async() => {
     setileZamowienDoPiecy(await fetchApi(`/zamowieniadopolek?dsr='${match.params.id}'`))
    }, 50)
 
     navinterval = (setInterval(async() => {
         setileZamowienDoPiecy(await fetchApi(`/zamowieniadopolek?dsr='${match.params.id}'`))
     }, 1500))
 
    }, [])

    //ILE ZAMÓWIEŃ//

    useEffect(async () => {
        setzamowienia(await fetchApi('/zamowieniaPolki'))

        intervalPiece =  (setInterval(async() => {
            setzamowienia(await fetchApi('/zamowieniaPolki'))
        }, 2000)
)
    }, [])
   
    useEffect(() => {
       
        return () => {
            clearInterval(intervalPiece)
            clearInterval(navinterval)
        }
    }, [])

    return (
    <> 
    
        <div class="computer-version">
         
            <NavbarComponent piece={ileZamowienDoPiecy[0] != undefined && ileZamowienDoPiecy[0].piece} patelnie={ileZamowienDoPiecy[0] != undefined && ileZamowienDoPiecy[0].patelnie} zamowienieOpis={match.params.id}/>
       
            <div id="same-piece" class="row container-pp">

                <div id="piece-wspolne" class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-9 ">
                    <div class="row">
                        
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 piece">
                           <PiecComponent id={11} fetch={nowyFetch} zamowienieOpis={match.params.id} piecId={1} pozycje={zamowienia}/>
                           <PiecComponent id={13}  fetch={nowyFetch} zamowienieOpis={match.params.id} piecId={3} pozycje={zamowienia}/>
                        </div>

                             <MagazynComponent id={33} fetch={nowyFetch} nazwa="Magazyn Piecy" zamowienieOpis={match.params.id} pozycje={zamowienia}/>
                       
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 piece">
                           <PiecComponent id={12}  fetch={nowyFetch} zamowienieOpis={match.params.id} piecId={2} pozycje={zamowienia}/>
                           <PiecComponent id={14}  fetch={nowyFetch} zamowienieOpis={match.params.id} piecId={4} pozycje={zamowienia}/>
                        </div>

                        <MagazynComponent id={44} fetch={nowyFetch} nazwa="Magazyn Patelni" zamowienieOpis={match.params.id} pozycje={zamowienia}/>
                    </div>

                 
                </div>
            
            
                
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 patelnie">
                  
                   <PatelniaComponent id={21} fetch={nowyFetch}  zamowienieOpis={match.params.id} patelniaId={1} pozycje={zamowienia}/>
                   <PatelniaComponent id={22}  fetch={nowyFetch} zamowienieOpis={match.params.id} patelniaId={2} pozycje={zamowienia}/>
                   
                  
                </div>
        
         
    
                </div>

              
            </div>
            <button class="btn btn-light fs-2 w-100 fixed-bottom" >
            <button onClick={() =>{

if(ileZamowienDoPiecy[0].piece != 0 || ileZamowienDoPiecy[0].patelnie != 0 ){
    var answer = window.confirm(`Nie dodałeś wszystkich zamówień, masz niedodane zamówienia
    DO PIECY: ${ileZamowienDoPiecy[0].piece}
    DO PATELNI: ${ileZamowienDoPiecy[0].patelnie} 
    Czy napewno chcesz wyjść?`)
    if (answer) {
        history.push(`/kuchzam`)
    }
    else {
       
    }

}else{
    history.push(`/kuchzam`)
}

        } 
            
            } class="btn btn-primary btn-lg float-right">GOTOWE</button>
      </button>
       
     
     </>
    )
    
}
