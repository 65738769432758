import React from 'react'

export default function PrzyciskiDrukujNapoje() {
    return (
       
        <div className="sidebar-right__przyciski">
        <div className="sidebar-right__przyciski__drukuj">
          <i className="icon-sm">🖨</i>
          <div className="sidebar-right__przyciski__drukuj--text">Wydruk</div>
        </div>
        <div className="sidebar-right__przyciski__pokaz-napoje">
          <i className="icon-sm">☕</i>
          <div className="sidebar-right__przyciski__pokaz-napoje--text">Pokaż napoje</div>
        </div>
      </div>
    )
}
