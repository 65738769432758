import React from 'react'

export default function KafelekSprzatnacP() {
    return (

                    <div className="col-6 mb-3">
                    <div className="sprzatnac-stolik">
                        <div className="blok-maly-zamowienie-brudnystolik">
                            <div className="blok-head">
                                <div className="stolik"><span className="ikona-zadania">🗑 </span>273</div>
                                <div className="opis-zadania">Sprzątnąć stolik</div>
                                <div className="ilosc-zadan">(4)</div>
                            </div>
                        </div>
                    </div>
                </div>
    )
}
