export default function scrollButton() {
   document.getElementById('scrollButtonDown').addEventListener('click', () => {
      window.scrollBy(0,700)
   })

   document.getElementById('scrollButtonUp').addEventListener('click', () => {
     if(window.pageYOffset == 0){
     }else{
      window.scrollBy(0,-700)
     }
    
   })
}