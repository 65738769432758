import policzTalerze from "./policzTalerze";
import wszystkieDsle from "./wszystkieDsle";
import fetchApi from "../api/fetchApi";

async function ileOdklikanychTacy(grupa, jakaTaca){
   let tace = await fetchApi(`/tacepogrupie?idgrupy=${grupa}&jakataca=${jakaTaca}`)
    console.log(tace.length, 'GRUPA ' + grupa, 'tacajaka' + jakaTaca);
    return tace.length
}

async function policzTace(arr, jakaTaca) {
    let kategorie = []
    let lenghtArray = []
    let idGrupy
    let liczbaZapisanychTac
    let liczbaTac

    if (arr.find(x => x.DSR_IDGrupy > 0) != undefined)  {
        idGrupy = arr.find(x => x.DSR_IDGrupy > 0).DSR_IDGrupy 
    }
   
    //Filtrowanie z których tac ciągniemy
    // arr = arr.filter(x => x.KDL_DataKelnerska != null)
    arr = arr.filter(x => x.DSL_DSLIDPowiazana == null)

    switch (jakaTaca) {
        case 'zupy':
        kategorie = [3] //Zmieniam kategorie do testów, zmien na 3
        liczbaZapisanychTac = await ileOdklikanychTacy(idGrupy, 1)
        arr = arr.filter(x => kategorie.includes(x.ART_KatWydruku))
           
         
             liczbaTac =  Number(policzTalerze(arr, 6) / 8)  - Number(liczbaZapisanychTac)
            
             console.log(liczbaTac);
           
            if(liczbaTac == 0 ){}{
                for(let i=0; i< liczbaTac; i++){
                    fetchApi(`/dodajtace?idgrupy=${idGrupy}&jakataca=1`)
                }
            }
          
            break;

        case 'glowne':
            kategorie = [1,4,5,6,9]
            liczbaZapisanychTac = await ileOdklikanychTacy(idGrupy, 2)

            arr = arr.filter(x => kategorie.includes(x.ART_KatWydruku))
        

            if(Math.ceil(policzTalerze(arr, 2)) == 0 ){
   
                liczbaTac = Math.ceil(policzTalerze(arr, 6) / 9) - liczbaZapisanychTac
            }else{
                liczbaTac = Math.ceil(policzTalerze(arr, 2) / 3) - liczbaZapisanychTac
            }
          
            

            liczbaTac = Number(liczbaTac) - Number(liczbaZapisanychTac)

            if(liczbaTac == 0 ){}
            else{
                for(let i=0; i< liczbaTac ; i++){
                    fetchApi(`/dodajtace?idgrupy=${idGrupy}&jakataca=2`)
                }
            }
           


            break;

        case 'wiata':
            kategorie = [8,11]
            liczbaZapisanychTac = await ileOdklikanychTacy(idGrupy, 3) 
            arr = arr.filter(x => kategorie.includes(x.ART_KatWydruku))
               
             
                 liczbaTac =  Number(policzTalerze(arr, 7) / 6)  - Number(liczbaZapisanychTac)
                
                 console.log(liczbaTac);
               
                if(liczbaTac == 0 ){}{
                    for(let i=0; i< liczbaTac; i++){
                        fetchApi(`/dodajtace?idgrupy=${idGrupy}&jakataca=3`)
                    }
                }
              
                break;
        default:
            return console.log('Nie podałeś poprawnej Jakiej Tacy')
            break;
    }

    

  }

  export default policzTace