import { useContext } from 'react';
import {useHistory } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { ProduktyContext } from '../../ProduktyContext';


function KafelekZamBg({przejete,numerek,sektor,wydawkaNazwa,wydawka,onClick, stolik, nazwaLokalizacji, iloscZamowien,czasZamowienia,timerKucharza,opis,sztucce,zamowienia, kolor, typZamowienia, obszar,DSR_ID,dostawa,wynos, polka}){
    let kolorHead;
    let {st, setStolik} = useContext(ProduktyContext)
    let katZamowienia = {
        naTeraz: [3],
        wydawka: [2,9],
        wiata:[8,11]
        
    }

    let czas = czasZamowienia

    var hms = czas    // your input string
    var a = hms.split(':'); // split it at the colons

    // Hours are worth 60 minutes.
    var minutes = (+a[0]) * 60 + (+a[1]);

   czas = minutes
  

    let history = useHistory()
    const goToZam = () => {
    if(wydawka == 0){
       
            history.push(`/zam/${opis.replace(/\s/g, "") }?czas=${czasZamowienia}&sektor=${sektor}&numerek=${numerek}`)
      
    }else if(wydawka == 1){
       
            history.push(`/zamwydawka/${opis.replace(/\s/g, "")}?czas=${czasZamowienia}&sektor=${sektor}&numerek=${numerek}`)
      
    } else if(wydawka == 2){
       
        history.push(`/zamwiata/${opis.replace(/\s/g, "")}?czas=${czasZamowienia}&sektor=${sektor}&numerek=${numerek}`)
  
}

}


    //Sortowanie Potraw 
    let potrawy = {}

    if(wydawka == 2){
       
        zamowienia = zamowienia.filter(x => x.KDL_DataKelnerska == null)
  
}
  
    
    if(zamowienia != null ||zamowienia != undefined ||zamowienia != ''){
        zamowienia.map((zamowienie) => {
            if(zamowienie.ART_Nazwa in potrawy){
               let nazwa =  zamowienie.ART_Nazwa
               potrawy[nazwa].DSL_Ilosc =  Number(potrawy[nazwa].DSL_Ilosc) + Number(zamowienie.DSL_Ilosc)
            }else{
                let nazwa =  zamowienie.ART_Nazwa
                Object.assign(potrawy, {[nazwa]:{ART_Nazwa: nazwa, DSL_Ilosc:Number(zamowienie.DSL_Ilosc), ART_KatWydruku: zamowienie.ART_KatWydruku } });
            }
        })
    }

    potrawy = Object.entries(potrawy);
   
    return(
       
        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6 container__blokZamowieniBg" key={uuidv4()}>
        <span>
        <div className={`blok-dlugi-z-tekstem  `} onClick={()=> {
  console.log(minutes);
            goToZam()
            }}>
            <div className="blok-zamowienie ">
            
               <div className={
                   
                   JSON.parse(sessionStorage.getItem('OPR_WYDAWKA')) != null && JSON.parse(sessionStorage.getItem('OPR_WYDAWKA')).id != przejete && przejete != null ? ` blok-head ${typZamowienia} bg-dark text-white-50` : 
                   `blok-head ${typZamowienia}  ${ czas > 40 ? 'box--red' : ''}  ${ czas >=0 && czas <= 15 ? 'box--gray' : '' }${czas > 15 && czas <=25 ? 'box--green' : '' }${czas > 25 && czas <=40 ? 'box--yellow' : '' }`
                   
                   
                   } style={obszar ===3 ? {borderRadius:"5px"} : {}}  >
    
                    {/* Ikonki w zależnośći od TOR (TYP ZAMOWIENIA) */}
                    {dostawa ? <i className="icon-bg" style={{fontSize:"3.5rem", margin:"1rem"}}><img class="filter-white" src="./icons/dostawa.svg"/></i> :null}
                    {wynos ? <i className="icon-bg" style={{fontSize:"3.5rem", margin:"1rem"}}><img class="filter-white" src="./icons/wynos.svg"/></i> :null}
                    {!dostawa && !wynos && stolik != null?  <div className="stolik">{stolik}</div> : <div className="stolik" style={{fontSize:"3rem", marginTop:"4.7rem", marginLeft:"1.5rem"}}>{opis} </div>}

                   
                    {stolik===null ?  <div className="nazwa-lokalizacji" style={{fontSize:"", margin:"0.5rem 0 0"}}>{nazwaLokalizacji}</div> : null} 
               
               {/* ZMIEN TO U GÓRY TO DO TESTÓW */}
               {!dostawa && !wynos && stolik != null? <div className="ilosc-zamowien"style={{fontSize:"", margin:"0rem 0 0 1.5rem"}} >{iloscZamowien}</div> : <div className="ilosc-zamowien"style={{fontSize:"2rem", margin:"0rem 0 4rem 1.5rem",left:"0rem"}} >{iloscZamowien}</div>}  
            
                   {typZamowienia === 'internet' && <div className="ikona-internetu filter-white"><img src="img/world-wide-web (1).svg"/></div>} 
                    
                    
                   {!dostawa && !wynos && stolik != null?  <div className="czas-zamowienia ">{czasZamowienia}</div>:  <div className="czas-zamowienia " style={{fontSize:"2.5rem", top:".5rem"}}>{numerek} {czasZamowienia}</div>}
             
                    {timerKucharza != null ? <div className="timer-kucharza" style={{marginTop:'3rem'}}>{timerKucharza < 0 ? 'T: -0:' + Math.abs(timerKucharza) : 'T: 0:' + Math.abs(timerKucharza)}</div> : null }
                    <div className="czas-zamowienia " style={{marginTop:'3rem'}}>{wydawkaNazwa}</div>
                    {!dostawa && !wynos && stolik != null? <hr/> : null} 
                    <div className="opis">
                        
                    {!dostawa && !wynos && stolik != null?  <div className="opis-kontent">{opis}</div> : null}
                    <center class="mb-5"><h5>{sektor}</h5></center>
                    </div>
                    <div className="timer-kucharza" style={{position:'absolute', top:'5rem', height:'1rem'}}><span style={{zIndex:'100', marginBottom:'1rem', zIndex:'1000'}}>{polka}</span></div>
                </div>
              
               {obszar === 3  ? <></> : <div className={`blok-body ${JSON.parse(sessionStorage.getItem('OPR_WYDAWKA')) != null && JSON.parse(sessionStorage.getItem('OPR_WYDAWKA')).id == przejete ? `bg-info` : `bg-dark` }`}>
                <center><h5>{przejete && przejete}</h5></center>
               
          <div className="ikonki">
              {/* CO Z TYM ROBIĆ */}
              {sztucce.map((x,idx)=>{
                  
                  if(x.Sztucce !== 0 ){
                    return (
                        <div className="ikona-i-ilosc">
                        <p className="ilosc">{x.Sztucce}</p>
                        <p className="ikonka filter-white" style={{marginBottom:"0px", marginTop:"3px"}}><img src="https://img.icons8.com/small/96/000000/money-box.png"/></p>
                    </div>
                    )
                }
                  }
                  )}
             
              
          </div>

          <div className="zamowienie-text">

              {potrawy.map(x=>{
                  if(obszar === 1 || obszar === 2 || obszar === 3 || obszar===4){
                    if(obszar === 1){
                        if(katZamowienia.naTeraz.includes(x[1].ART_KatWydruku)){
                            return <p class="fs-3"> {x[1].DSL_Ilosc}x {x[1].ART_Nazwa} </p>
                        }
                    }else if(obszar === 2){
                        if(katZamowienia.wydawka.includes(x[1].ART_KatWydruku)){
                            return <p class="fs-3"> {x[1].DSL_Ilosc}x {x[1].ART_Nazwa} </p>
                        }
                    }else if(obszar === 4){
                        if(katZamowienia.wiata.includes(x[1].ART_KatWydruku) && x[1].KDL_DataKelnerska == null){
                            return <p class="fs-3"> {x[1].DSL_Ilosc}x {x[1].ART_Nazwa} </p>
                        }
                    }
                  }else {
                    return <p class="fs-3"> {x[1].DSL_Ilosc}x {x[1].ART_Nazwa} </p>
                  }
                 
              })}

          </div>
      </div>} 
            </div>
        </div>
        </span>
    </div>
    )
}

KafelekZamBg.defaultProps = {
    onClick : () =>{console.log('default click');},
    stolik: "-1",
    nazwaLokalizacji: 'Nie podano',
    iloscZamowien: 0,
    czasZamowienia: '',
    timerKucharza: '',
    opis: "Default Description",
    sztucce: [''],
    zamowienia: [''],
    // kolor: 'box--green',
    typZamowienia: '',
    obszar: 1,
    wydawkaNazwa: ''
}

export default KafelekZamBg 